import React, { Component, Fragment } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Immutable from 'immutable';
import cx from 'classnames';
import { compose } from 'recompose';
import { getVersion } from 'settings';
import { signOutAction } from 'redux/reducers/app';

import { isElectron, removeLocalItem } from 'shared/util/utils';

import HeaderDetail from 'containers/App/Header/HeaderDetail';
import LinkWrapper from 'components/LinkWrapper/LinkWrapper';
import { isCSFC, isAiMate } from 'shared/helper/permission';
import IconMenu from 'components/IconMenu/IconMenu';

import headerlogoText from 'design/images/header/header-log.svg';
import headerlogoLight from 'design/images/header/header-logo-white.svg';
import headerIconApp from 'design/images/header/header-icon-app.svg';
import headerIconBrowser from 'design/images/header/header-icon-browser.svg';
import csfcLogo from 'design/images/header/csfc_text_logo.png';
import { ReactComponent as LogoAImate } from 'design/images/header/aimate_logo.svg';
import { ReactComponent as IconAImateAccount } from 'design/images/icons/icon-aimate-account.svg';
import { ReactComponent as IconArrowDown } from 'design/images/icons/icon-arrow-down.svg';

import './Header.scss';

const mapStateToProps = (state) => {
	const user = state.app.get('user');
	return {
		user,
		isDemo: user ? user.get('role') === 'demo' : false,
		isCSFCExam: isCSFC(),
	};
};

const mapDispatchToProps = {
	signOutAction,
};

class Header extends Component {
	static propTypes = {
		user: PropTypes.instanceOf(Immutable.Iterable),
		signOutAction: PropTypes.func,
	};

	static defaultProps = {
		user: null,
	};

	isHeaderWhite = () => {
		const pathname = this.props.location.pathname;
		const user = this.props.user;

		if (pathname.startsWith('/registration')) return true;
		// if (pathname === "/login") return true
		if (pathname.startsWith('/verify')) return true;
		if (!user) return true;
		return false;
	};

	signOut = () => {
		// console.log("signOut")
		const { signOutAction, history } = this.props;

		removeLocalItem('currentCourse');
		removeLocalItem('currentLesson');

		if ((isAiMate('route') && isAiMate('workspace')) || isAiMate('aiMateOnly')) {
			history.push(`/AImate`);
		} else {
			history.push(`/login`);
		}
		signOutAction();
	};

	onClickAccountItem = () => {
		const { history } = this.props;

		//FIXME: Feature
		// history.push(`/AImate/account`);
		history.push(`/AImate/plan`);
	};

	onClickPreferencesItem = () => {
		// TODO:SOL
		return;
	};

	renderVersion = () => {
		return (
			<Fragment>
				<span>
					<img alt='device' className='env-icon' src={isElectron() ? headerIconApp : headerIconBrowser} />
				</span>
				<Typography className='version-text' id='version'>
					{getVersion()}
					{isAiMate('route') && <span style={{ marginLeft: '4px' }}>(Beta)</span>}
				</Typography>
			</Fragment>
		);
	};

	renderLogo = () => {
		const { isCSFCExam, isDemo, user, t } = this.props;

		if (isAiMate('route')) {
			return (
				<LinkWrapper isLink linkTo='/AImate/editor'>
					<LogoAImate id='Header-logo' className='aiMate-header-logo' />
					{/* <img
						id="Header-logo"
						className="aiMate-header-logo"
						src={aiMateLogo}
						alt="AiMate"
					/> */}
				</LinkWrapper>
			);
		}

		if (isCSFCExam) {
			return (
				<Fragment>
					<LinkWrapper>
						<img id='Header-logo' className='csfc-header-logo' src={csfcLogo} alt='CSFC' />
					</LinkWrapper>
					<span className='csfc-label'>{t('login.csfc.bannertext')}</span>
				</Fragment>
			);
		}

		return (
			<LinkWrapper isLink={!isDemo} linkTo='/'>
				<img
					alt='codingbar'
					id='Header-logo'
					className='Header-logo'
					src={this.isHeaderWhite() ? headerlogoText : headerlogoLight}
				/>
			</LinkWrapper>
		);
	};

	renderHeaderItem = () => {
		const { user, togglePlayground, t, toggleZoom } = this.props;

		if (!user) return null;
		if (isAiMate('route')) {
			const userDisplayTitle = user.get('displayName') ? user.get('displayName') : user.get('email');
			return (
				<IconMenu
					className='AImate-header-menu-wrapper'
					menuClassName='AImate-header-menu-list'
					CustomButtonComponent={(p) => (
						<button className='AImate-header-menu' {...p}>
							<IconAImateAccount className='icon-user' />
							<span>{userDisplayTitle}</span>
							<IconArrowDown />
						</button>
					)}
					menuPosition='right'
				>
					{
						<IconMenu.Item onClick={this.onClickAccountItem} className='AImate-header-menu-item'>
							{t('header.account')}
						</IconMenu.Item>
					}
					{/* { <IconMenu.Item onClick={this.onClickPreferencesItem} className="AImate-header-menu-item"> */}
					{/* 	{t('header.preferences')} */}
					{/* </IconMenu.Item>} */}
					<IconMenu.Item>
						<li className='icon-menu-item AImate-header-menu-item'>
							{/* <div className="divider" /> */}
							<button className='icon-menu-item-btn' onClick={this.signOut}>
								{t('header.logOut')}
							</button>
						</li>
					</IconMenu.Item>
				</IconMenu>
			);
		}
		return (
			<span className='Header-link'>
				<HeaderDetail togglePlayground={togglePlayground} toggleZoom={toggleZoom} />
			</span>
		);
	};

	render() {
		const { user, headerDisabled, t, isCSFCExam } = this.props;

		const headerWrapperClassName = cx('Header', {
			'Header-white': this.isHeaderWhite(),
			'Header-light': !this.isHeaderWhite() && !user,
			'Header-dark': !this.isHeaderWhite() && user,
			'Header-AImate': !this.isHeaderWhite() && isAiMate('route') && user,
			'Header-csfc': isCSFCExam,
		});

		if (headerDisabled) return null;

		// render menu when user is authorized
		return (
			<header className={headerWrapperClassName}>
				{this.renderLogo()}
				{!isCSFCExam && this.renderVersion()}
				{this.renderHeaderItem()}
			</header>
		);
	}
}

export default compose(withRouter, translate('translations'), connect(mapStateToProps, mapDispatchToProps))(Header);
