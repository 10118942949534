import React from 'react';
import Select from 'react-select';
import { ReactComponent as Indicator } from 'design/images/icons/icon-index_down.svg';
import './Select.scss';
//根據帶入的className呈現不同的主題
//coding-bar-select(default PrimaryTheme)
//bulleted-list-item (custom下拉式選單 bulleted list)
//monacoTheme (深藍色)
//AImateDarkTheme （深灰色）
export default function (props) {
  const { className='', ...otherProps} = props;
  const DropdownIndicator = () => {
    return <Indicator className="coding-bar-select-icon" />
  }
  return (
    <Select
      className={`coding-bar-select ${className}` }
      classNamePrefix="coding-bar-select"
      isSearchable={false}
      isClearable={false}
      backspaceRemovesValue={false}
      components={{ DropdownIndicator }}
      {...otherProps}
    />
  );
}
