import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './IconLabel.scss';

const IconLabel = props => {
  const { icon, label, bgColor, ...rest } = props;
  const hasIcon = icon !== undefined;
  const css = classnames(
    'icon-label',
    { 'icon-label-padding': hasIcon },
    { 'icon-label-padding-no-icon': !hasIcon }
  );

  return (
    <div className={css}
      style={{ background: bgColor }}>
      {hasIcon ?
        <div className='center-icon'>
          <img src={icon} />
        </div>
        : null}
      <p>{label}</p>
    </div>
  );
}

IconLabel.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  bgColor: PropTypes.string
};

IconLabel.defaultProps = {
  label: 'Label',
  bgColor: '#5CD391'
};

export default IconLabel;
