import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import './RadioButton.scss'

const RadioButton = withStyles({
	root: {
		color: "#B8C3D9",
		padding: 0,
		"&$disabled": {
			color: "#B8C3D9",
		}
	},
	checked: {
		color: "#2F80ED"
	},
	disabled: {
		color: "#B8C3D9"
	}
})(props =>{ 
	const { className = '', ...otherProps } = props;

return <Radio color="default" className={`coding-bar-radio-button ${className}`} {...otherProps} />});

export default RadioButton;