export const INIT = 'REG_INIT'

export const GET_REGISTRATION_SETTING = 'REG_GET_REGISTRATION_SETTING'

export const SET_IS_OLD_STUDENT = 'REG_SET_IS_OLD_STUDENT'

export const SAVE_STEP_ONE = 'REG_SAVE_STEP_ONE'
export const SAVE_STEP_ONE_GROUP = 'REG_SAVE_STEP_ONE_GROUP'
export const SAVE_STEP_TWO = 'REG_SAVE_STEP_TWO'
export const SAVE_STEP_THREE = 'REG_SAVE_STEP_THREE'
export const SAVE_STEP_THREE_GROUP = 'REG_SAVE_STEP_THREE_GROUP'
export const SET_CHEAPEST_PAY_AMOUNT = 'REG_SET_CHEAPEST_PAY_AMOUNT'
export const SAVE_CURRENT_STUDENT_INFO = 'REG_SAVE_CURRENT_STUDENT_INFO'
export const GO_PREV_STUDENT = 'REG_GO_PREV_STUDENT'
export const GO_NEXT_STUDENT = 'REG_GO_NEXT_STUDENT'
export const SAVE_STEP_FOUR = 'REG_SAVE_STEP_FOUR'

export const SET_PAY_LINK = 'REG_SET_PAY_LINK'

export const GO_PREV_STEP = 'REG_GO_PREV_STEP'
export const GO_NEXT_STEP = 'REG_GO_NEXT_STEP'
export const GO_STEP = 'REG_GO_STEP'

export const CHECK_USER_COUNT = 'REG_CHECK_USER_COUNT'
export const CANCEL_CHECK_USER_COUNT = 'REG_CANCEL_CHECK_USER_COUNT'
export const WAIT_FOR_REGISTRATION = 'REG_WAIT_FOR_REGISTRATION'

export const DISCOUNT = {
  NONE: 'none',
  MULTI: 'multi',
  OLD: 'old',
  COUPON: 'coupon',
  GROUP: 'group',
}
