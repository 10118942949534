export const NOTIFICATION_INIT = 'notification/INIT';
export const NOTIFICATION_INIT_SUC = 'notification/INIT_SUC';
export const NOTIFICATION_INIT_ERR = 'notification/INIT_ERR';

export const SAGA_GET_NOTIFICATION = 'notification/SAGA_GET_NOTIFICATION';

export const NOTIFICATION_LISTEN_UNREAD_INIT = 'notification/LISTEN_UNREAD_INIT';
export const NOTIFICATION_LISTEN_UNREAD_INIT_SUC = 'notification/LISTEN_UNREAD_INIT_SUC';
export const NOTIFICATION_LISTEN_UNREAD_INIT_ERR = 'notification/LISTEN_UNREAD_INIT_ERR';

export const SAGA_LISTEN_UNREAD_NOTIFICATION = 'notification/SAGA_LISTEN_UNREAD_NOTIFICATION';