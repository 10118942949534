import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import renderHTML from 'react-render-html';
import * as cons from 'redux/constants/app';

import { Modal, Button, Checkbox } from 'components';

import './AnnouncementModal.scss';
@translate('translations')
@connect((state) => {
	const app = state.app;
	return {
		user: app.get('user'),
		announcements: app.get('announcements') ? app.get('announcements').toJSON() : [],
		ancmList: app.get('ancmList') ? app.get('ancmList').toJSON() : [],
	};
})
export default class AnnouncementModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: true,
		};
	}

	replaceValueWhiteSpace = (text) => {
		return text
			.replace(/^\s+/gm, '') // remove html indentation
			.replace(/(\r\n|\n|\r)/gm, '') // remove html line breaks
			.replaceAll('</br>', '');
	};

	closeModal = () => {
		const { announcement, announcements, dispatch } = this.props;

		const id = announcement.get('id');
		const remainingAnnouncements = announcements.filter((announcement) => announcement.id !== id);
		//	同時有複數個公告 popUp 時, 要針對各自 id 關閉並更新 redux 的 announcements 列表
		dispatch({ type: cons.GET_ANNOUNCEMENT, announcements: [...remainingAnnouncements] });

		this.setState({
			isOpen: false,
		});
	};

	onCheckIsRead = () => {
		this.setState((prevState) => ({
			isChecked: !prevState.isChecked,
		}));
	};

	// Button Actions
	logOutUser = () => {
		codingbarApi.getAuthService().signOut();
	};

	markAncmAsRead = () => {
		const { announcement, ancmList, dispatch } = this.props;
		const id = announcement.get('id');

		codingbarApi
			.getBulletinService()
			.markAsRead(id)
			.then(() => {
				//  有勾選不再顯示的時候，要更新 redux 的 ancmList 的 markAsReadUidList
				dispatch({
					type: cons.GET_ANNOUNCEMENT_LIST,
					ancmList: ancmList.reduce((arr, el) => {
						if (el.id !== id) return [...arr, el];
						const newMarkAsReadUidList = el.markAsReadUidList
							? !el.markAsReadUidList.includes(this.props.user.get('uid'))
								? [...el.markAsReadUidList, this.props.user.get('uid')]
								: el.markAsReadUidList
							: [this.props.user.get('uid')];
						return [...arr, { ...el, markAsReadUidList: newMarkAsReadUidList }];
					}, []),
				});

				this.closeModal();
			});
	};

	render() {
		const { isOpen, isChecked } = this.state;
		const { t, announcement } = this.props;

		return (
			<Modal
				className='ancm-view-modal'
				isOpen={isOpen}
				onRequestClose={this.closeModal}
				isShowClose={false}
				shouldCloseOnOverlayClick={announcement.get('type') === 'agreement' ? false : true}
			>
				<div className='ancm-view-body'>
					<div className='ancm-body-title'>{announcement.get('title')}</div>

					<div className='ancm-content-msg'>
						{/* <p>{announcement.get('content')}</p> */}
						{announcement &&
							announcement.get('content') &&
							renderHTML(this.replaceValueWhiteSpace(announcement.get('content')))}
					</div>
				</div>
				<div className='ancm-view-bottom'>
					{announcement.get('type') === 'announcement' && (
						<div className='type-announcement'>
							<div className='checkbox-cont'>
								<Checkbox checked={isChecked} onChange={this.onCheckIsRead} />
								<p onClick={this.onCheckIsRead}>{t('announcement.dontShowAgain')}</p>
							</div>
							<Button primary onClick={isChecked ? this.markAncmAsRead : this.closeModal}>
								{announcement.get('btnText') === '' ? t('announcement.confirmClose') : announcement.get('btnText')}
							</Button>
						</div>
					)}
					{announcement.get('type') === 'agreement' && (
						<div className='type-agreement'>
							<Button className='ancm-disagree-btn' onClick={this.logOutUser}>
								{t('announcement.disagree')}
							</Button>
							<Button primary onClick={this.markAncmAsRead}>
								{announcement.get('btnText') === '' ? t('announcement.confirmClose') : announcement.get('btnText')}
							</Button>
						</div>
					)}
					{announcement.get('type') === 'alert' && (
						<div className='type-alert'>
							<Button primary onClick={this.markAncmAsRead}>
								{announcement.get('btnText') === '' ? t('announcement.confirmClose') : announcement.get('btnText')}
							</Button>
						</div>
					)}
				</div>
			</Modal>
		);
	}
}
