import i18n from 'lang/i18n';

const divEnd = `</div>`

const header = `
<div style="max-width:680px;margin:0 auto;padding-top:1px;background-color: white;"></div>
`

const topLogo = `<div>
	<img src="http://codingbar.ai/img/email/shared/logo_text.png" alt="logo_text" style="height:62px;width:182px;margin-top:40px;margin-bottom:24px;margin-right:auto;margin-left:auto;display:block;" width="62" height="182"/>
</div>
`

const bannerImg = `
<div>
	<div style="height:4px;width:100%;background-color:#FED602;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" ></div>
	<img src={{imgUrl}} alt="main-img" style="height:auto;width:100%;display:block;border-radius:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" />
	<div style="height:4px;width:100%;background-color:#FED602;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" ></div>
</div>
`

const caption = `
<div style="width:70%;color:#29354C;font-size:16px;font-weight:400;margin-top:24px;margin-bottom:0px;margin-right:auto;margin-left:auto;" >
	<div style="margin-bottom:25px;" >
		<p style="font-size:32px;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >{{caption}}</p>
`

const subTitle = `
		<p style="font-size:20px;font-weight:500;color:#2F80ED;margin-top:5px;margin-bottom:0;margin-right:0;margin-left:0;" >{{title}}</p>
`


const innerTextNoDiv = `
		<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >{{innerTextNoDiv}}</p>
		<br>
`

const signUpDate = `
	<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >{{signUpType}}日期：{{signUpDate}}</p>
	<br>
`

const mailAddress = `
	<div>{{coursesList}}</div>
	<br>
`

const groupInfo = `
	<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >報名方案：{{plan}}</p>
`

const groupId = `
	{{groupCode}}`

const price = `
	<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >繳費金額：$\{{payAmount}} 元</p>
	<br>
`

const regisInfo = `
	<br>
	<div>{{studentList}}</div>
	<br>
`

const innerText = `
<div style="width:70%;color:#29354C;font-size:16px;border-top-width:0px;border-top-style:solid;border-top-color:#6F8190;padding-top:0px;padding-bottom:0px;padding-right:0;padding-left:0;margin-top:24px;margin-bottom:0;margin-right:auto;margin-left:auto;">
	<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >{{innerText}}</p>
</div>
`

const priceBorder = `
<div style="width:70%;color:#29354C;font-size:16px;border-top-width:1px;border-top-style:solid;border-top-color:#6F8190;padding-top:0px;padding-bottom:0px;padding-right:0;padding-left:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;">
	<p style="font-weight:500;margin-top:24px;margin-bottom:0;margin-right:auto;margin-left:auto;text-align:center;" >費用 <span style="font-weight:600; font-family: Montserrat;color:#2F80ED;">NT\${{payAmount}}</span> 元</p>
</div>
`
const borderline = `<div style="width:70%;color:#29354C;font-size:16px;border-bottom-width:1px;border-bottom-style:solid;border-bottom-color:#6F8190;padding-top:0px;padding-bottom:0px;padding-right:0;padding-left:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;">
</div>`

const borderlineWithMarginTop = `<div style="width:70%;color:#29354C;font-size:16px;border-bottom-width:1px;border-bottom-style:solid;border-bottom-color:#6F8190;padding-top:0px;padding-bottom:0px;padding-right:0;padding-left:0;margin-top:24px;margin-bottom:0;margin-right:auto;margin-left:auto;">
</div>`

const paylinkButton = `
<div style="width:70%;color:#29354C;font-size:16px;border-bottom-width:1px;border-bottom-style:solid;border-bottom-color:#6F8190;padding-right:0;padding-left:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" >
	<a href={{payLink}}
		target="_blank" 
		style="width:280px;max-width: 100%;height:40px;box-sizing:border-box;box-shadow:0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);border-radius:4px;background-color:#2F80ED;cursor:pointer;color:white;font-weight:500;line-height:40px;position:relative;text-decoration:none;margin-top:24px;margin-bottom:24px;margin-right:auto;margin-left:auto;display:block;" >
		<div style="width:fit-content;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;display:flex;" >{{linkButton}}</div>
	</a>
</div>
`

const infolinkButton = `
<div style="width:70%;color:#29354C;font-size:16px;border-bottom-width:1px;border-bottom-style:solid;border-bottom-color:#6F8190;padding-right:0;padding-left:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" >
	<a href={{infoLink}}
		target="_blank" 
		style="width:280px;max-width: 100%;height:40px;box-sizing:border-box;box-shadow:0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);border-radius:4px;background-color:#2F80ED;cursor:pointer;color:white;font-weight:500;line-height:40px;position:relative;text-decoration:none;margin-top:24px;margin-bottom:24px;margin-right:auto;margin-left:auto;display:block;" >
		<div style="width:fit-content;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;display:flex;" >{{linkButton}}</div>
	</a>
</div>
`

const lineContact = `
<div style="width:70%;color:#29354C;font-size:16px;border-top-width:0px;border-top-style:solid;border-top-color:#6F8190;padding-top:24px;padding-bottom:80px;padding-right:0;padding-left:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" >
	<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >感謝您的參與和支持，如有任何問題，請於上班時間電洽 (02) 7717-9386 ，或透過官方 Line@ 與我們聯絡！</p>
	<a href="https://line.me/R/ti/p/%40ply0218v" 
		target="_blank" style="width:280px;max-width: 100%;height:40px;box-sizing:border-box;box-shadow:0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);border-radius:4px;cursor:pointer;color:white;font-weight:500;line-height:40px;position:relative;text-decoration:none;margin-top:26px;margin-bottom:24px;margin-right:auto;margin-left:auto;display:block;background-color:#00b900;" >
		<div style="width:fit-content;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;display:flex;" >
			<img src="http://codingbar.ai/img/email/shared/icon_line.png" alt="line_icon" style="width:32px;height:32px;margin-top:4px;margin-bottom:4px;margin-right:4px;margin-left:4px;" />
			立即加入Line@
		</div>
	</a>
	<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;text-align:center;color:#6F8190;" >此為系統信件，請勿直接回覆</p>
</div>
`

const footer = `
<div style="padding-top:40px;padding-bottom:40px;padding-right:0;padding-left:0;border-top-width:1px;border-top-style:solid;border-top-color:#D4E1EE;border-bottom-width:12px;border-bottom-style:solid;border-bottom-color:#FED602;width:100%;display:block;" >
		<img src="http://codingbar.ai/img/email/shared/logo.png" alt="logo" style="margin: 0 auto 20px auto; display: block;height:26px;width:120px" width="26" height="120"/>
		<div style="text-align:center;" >
			<a href="tel:+886277179386" style="text-decoration:none;font-family:'Montserrat', sans-serif;font-size:12px;line-height:15px;margin-top:4px;margin-bottom:4px;margin-right:4px;margin-left:4px;display:block;color:#29354C;" >(02) 7717-9386</a>
			<a href="mailto: service@codingbar.ai"  style="text-decoration:none;font-family:'Montserrat', sans-serif;font-size:12px;line-height:15px;margin-top:4px;margin-bottom:4px;margin-right:4px;margin-left:4px;display:block;color:#29354C;" >service@codingbar.ai</a>
		</div>
		
		<div style="width:fit-content;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" >
			<a href="https://www.facebook.com/codingbar" target="_blank" style="text-decoration:none;" >
				<img src="http://codingbar.ai/img/email/shared/icon_fb.png" alt="fb_icon" style="margin-top:10px;margin-bottom:10px;margin-right:10px;margin-left:10px;height:24px;width:24px" width="24" height="24"/>
			</a>
			<a href="https://line.me/R/ti/p/%40ply0218v" target="_blank" style="text-decoration:none;" >
				<img src="http://codingbar.ai/img/email/shared/icon_line.png" alt="line_icon" style="margin-top:10px;margin-bottom:10px;margin-right:10px;margin-left:10px;height:24px;width:24px" width="24" height="24" />
			</a>
			<a href="https://www.instagram.com/codingbar.ai/" target="_blank" style="text-decoration:none;" >
				<img src="http://codingbar.ai/img/email/shared/icon_ig.png" alt="ig_icon" style="margin-top:10px;margin-bottom:10px;margin-right:10px;margin-left:10px;height:24px;width:24px" width="24" height="24"/>
			</a>
			<a href="https://www.youtube.com/channel/UC97dHlwNiVCV_soIbX8OtsQ" target="_blank" style="text-decoration:none;" >
				<img src="http://codingbar.ai/img/email/shared/icon_youtube.png" alt="youtube_icon" style="margin-top:10px;margin-bottom:10px;margin-right:10px;margin-left:10px;height:24px;width:24px" width="24" height="24"/>
			</a>
		</div>
	</div>
</div>
`

export const ParentSignPay = {
	subject: {
		x1: "【CodingBar 課程報名確認通知】- {{title}}",
		x2: "【CodingBar 課程繳費成功通知】- {{title}}",
		x3: "【CodingBar 信箱認證信】",
		// x4: "【CodingBar 變更密碼信】",
		// x5: "【CodingBar 催款通知信】- {{title}}",
		x6: "【CodingBar 候補信】- {{title}}",
	},
	templates: {
		x1: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x1_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x1.title')),
			subTitle, divEnd, signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.signUp')), 
			mailAddress, groupInfo, groupId, regisInfo, 
			innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x1.innerTextBottom1') + ' <span style="color:#2F80ED; font-weight: 700;">' + i18n.t('parentEmailTemplate.x1.innerTextBottom2') + '</span> ' + i18n.t('parentEmailTemplate.x1.innerTextBottom3') + '<br><br>' + i18n.t('parentEmailTemplate.x1.innerTextBottom4')),
			divEnd, priceBorder,
			paylinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x1.linkButton')),lineContact, footer ].join(''),

		x1_simple: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x1_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x1.title')),
			subTitle, divEnd, signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.signUp')), 
			mailAddress, groupInfo, groupId, regisInfo, 
			innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x1.innerTextBottom1') + ' <span style="color:#2F80ED; font-weight: 700;">' + i18n.t('parentEmailTemplate.x1.innerTextBottom2') + '</span> ' + i18n.t('parentEmailTemplate.x1.innerTextBottom3') + '<br><br>' + i18n.t('parentEmailTemplate.x1.innerTextBottom4')),
			divEnd, priceBorder,
			paylinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x1.linkButton')),
			innerText.replace(/{{innerText}}/g, i18n.t('parentEmailTemplate.x1_simple.innerTextBottom1')),
			infolinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x1_simple.linkButton')),
			lineContact, footer ].join(''),

		x1_noFee: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x1_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x1.title')),
			subTitle, divEnd, signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.signUp')), 
			mailAddress, groupInfo, groupId, regisInfo, 
			innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x1.innerTextBottom5')),
			divEnd, priceBorder, borderlineWithMarginTop, lineContact, footer ].join(''),

		x1_cba: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x1_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x1.title')),
			subTitle, divEnd, signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.signUp')), 
			mailAddress, groupInfo, groupId, regisInfo, 
			innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x1.innerTextBottom1') + ' <span style="color:#2F80ED; font-weight: 700;">' + i18n.t('parentEmailTemplate.x1.innerTextBottom2') + '</span> ' + i18n.t('parentEmailTemplate.x1.innerTextBottom3') + '<br><br>' + i18n.t('parentEmailTemplate.x1.innerTextBottom4')),
			divEnd, priceBorder,
			paylinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x1.linkButton')),lineContact, footer ].join(''),

		x1_cba_noFee: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x1_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x1.title')),
			subTitle, divEnd, signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.signUp')), 
			mailAddress, groupInfo, groupId, regisInfo, 
			innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x1.innerTextBottom5')),
			divEnd, priceBorder, borderlineWithMarginTop, lineContact, footer ].join(''),


		x2: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x2_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x2.title')),
			divEnd, innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x2.innerTextTop1') + ' <span style="color:#2F80ED; font-weight: 700;">' + '{{title}}' + '</span> ' + i18n.t('parentEmailTemplate.x2.innerTextTop2') + ' <span style="color:#2F80ED; font-weight: 700;">' + i18n.t('parentEmailTemplate.x2.innerTextTop3') + '</span> ' + i18n.t('parentEmailTemplate.x2.innerTextTop4')),
			mailAddress, groupId,
			price, regisInfo, divEnd,lineContact, footer ].join(''),
		
		x2_simple: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x2_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x2.title')),
			divEnd, innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x2.innerTextTop1') + ' <span style="color:#2F80ED; font-weight: 700;">' + '{{title}}' + '</span> ' + i18n.t('parentEmailTemplate.x2.innerTextTop2') + ' <span style="color:#2F80ED; font-weight: 700;">' + i18n.t('parentEmailTemplate.x2.innerTextTop3') + '</span> ' + i18n.t('parentEmailTemplate.x2.innerTextTop4')),
			mailAddress,
			price, regisInfo, divEnd,
			borderline,
			innerText.replace(/{{innerText}}/g, i18n.t('parentEmailTemplate.x1_simple.innerTextBottom1')),
			infolinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x1_simple.linkButton')),
			lineContact, footer ].join(''),

		x3: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x3_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x3.title')),
			divEnd, innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x3.innerTextBottom1') + '<br><br>' + i18n.t('parentEmailTemplate.x3.innerTextBottom2')),
			divEnd, paylinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x3.linkButton')),
			lineContact, footer ].join(''),
		
			// 忘記密碼的 信件範本，直接寫死設定在 firebase 上
		// x4: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x4_banner.png'),
		// 	caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x4.title')),
		// 	divEnd, innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x4.innerTextTop1')),
		// 	divEnd, paylinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x4.linkButton')),
		// 	lineContact, footer ].join(''),

		// x5: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x5_banner.png'),
		// 	caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x5.title')),
		// 	divEnd, innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x5.innerTextTop1') + ' <span style="color:#2F80ED; font-weight: 700;">' + '{{title}}' + '</span> ' + i18n.t('parentEmailTemplate.x5.innerTextTop2') + ' <span style="color:#2F80ED; font-weight: 700;">' + '{{deadLine}}' + '</span> ' + i18n.t('parentEmailTemplate.x5.innerTextTop3')),
		// 	signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.signUp')),
		// 	mailAddress,
		// 	groupInfo, groupId, regisInfo, divEnd, priceBorder,
		// 	paylinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x5.linkButton')), lineContact, footer ].join(''),

		x6: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x6_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x6.title')),
			divEnd, innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x6.innerTextTop1') + ' <span style="color:#2F80ED; font-weight: 700;">' + '{{title}}' + '</span> ' + i18n.t('parentEmailTemplate.x6.innerTextTop2')),
			signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.waiting')),
			mailAddress,
			groupInfo, groupId, regisInfo, divEnd, lineContact, footer ].join(''),
		
		x6_simple: [topLogo, bannerImg.replace(/{{imgUrl}}/g, 'http://codingbar.ai/img/email/general/x6_banner.png'),
			caption.replace(/{{caption}}/g, i18n.t('parentEmailTemplate.x6.title')),
			divEnd, innerTextNoDiv.replace(/{{innerTextNoDiv}}/g, i18n.t('parentEmailTemplate.x6.innerTextTop1') + ' <span style="color:#2F80ED; font-weight: 700;">' + '{{title}}' + '</span> ' + i18n.t('parentEmailTemplate.x6.innerTextTop2')),
			signUpDate.replace(/{{signUpType}}/g, i18n.t('parentEmailTemplate.waiting')),
			mailAddress,
			groupInfo, groupId, regisInfo, divEnd, 
			borderline,
			innerText.replace(/{{innerText}}/g, i18n.t('parentEmailTemplate.x1_simple.innerTextBottom1')),
			infolinkButton.replace(/{{linkButton}}/g, i18n.t('parentEmailTemplate.x1_simple.linkButton')),
			lineContact, footer ].join(''),
	},
	// divEnd,
	// topLogo,
	// bannerImg,
	// caption,
	// [subTitle],
	// divEnd,
	// innerTextNoDiv, 一個段落用, 在 signUpDate 之前
	// signUpDate,
	// mailAddress,
	// groupInfo,
	// groupId,
	// price,
	// regisInfo,
	// innerText, 兩個段落用, 在 priceBorder 或 lineContact 之前
	// priceBorder,
	// borderline,
	// paylinkButton,
	// infolinkButton,
	// lineContact,
	// footer,
}

const resetPasswordEmail = `
<div>
<img src="http://codingbar.ai/img/email/shared/logo_text.png" alt="logo_text" style="height:62px;width:182px;margin-top:40px;margin-bottom:24px;margin-right:auto;margin-left:auto;display:block;" width="62" height="182"/>
</div>

<div>
<div style="height:4px;width:100%;background-color:#FED602;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" ></div>
<img src="http://codingbar.ai/img/email/general/x4_banner.png" alt="main-img" style="height:auto;width:100%;display:block;border-radius:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" />
<div style="height:4px;width:100%;background-color:#FED602;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" ></div>
</div>

<div style="width:70%;color:#29354C;font-size:16px;font-weight:400;margin-top:24px;margin-bottom:0px;margin-right:auto;margin-left:auto;" >
<div style="margin-bottom:25px;" >
 <p style="font-size:32px;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >重設密碼</p>
</div>

<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >請點擊下方連結重新設定您的 CodingBar 帳號密碼，若您沒有申請過重新設定密碼，請忽略此封信。</p>
<br>
</div>

<a href="%LINK%"
target="_blank" 
style="width:280px;max-width: 100%;height:40px;box-sizing:border-box;box-shadow:0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);border-radius:4px;background-color:#2F80ED;cursor:pointer;color:white;font-weight:500;line-height:40px;position:relative;text-decoration:none;margin-top:24px;margin-bottom:24px;margin-right:auto;margin-left:auto;display:block;" >
<div style="width:fit-content;font-size:16px;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;display:flex;" >重設密碼</div>
</a>

<div style="width:70%;color:#29354C;font-size:16px;border-top-width:1px;border-top-style:solid;border-top-color:#6F8190;padding-top:24px;padding-bottom:80px;padding-right:0;padding-left:0;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" >
<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >感謝您的參與和支持，如有任何問題，請於上班時間電洽 (02) 7717-9386 ，或透過官方 Line@ 與我們聯絡！</p>
<a href="https://line.me/R/ti/p/%40ply0218v" 
 target="_blank" style="width:280px;max-width: 100%;height:40px;box-sizing:border-box;box-shadow:0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);border-radius:4px;cursor:pointer;color:white;font-weight:500;line-height:40px;position:relative;text-decoration:none;margin-top:26px;margin-bottom:24px;margin-right:auto;margin-left:auto;display:block;background-color:#00b900;" >
 <div style="width:fit-content;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;display:flex;" >
  <img src="http://codingbar.ai/img/email/shared/icon_line.png" alt="line_icon" style="width:32px;height:32px;margin-top:4px;margin-bottom:4px;margin-right:4px;margin-left:4px;" />
  立即加入Line@
 </div>
</a>
<p style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;text-align:center;color:#6F8190;" >此為系統信件，請勿直接回覆</p>
</div>
`

const resetPasswordEmailOld = `
<p>您好,</p>
<p>馬上點選 <a href='%LINK%'>這裡</a> 幫您的 CodingBar %EMAIL% 帳號，重設密碼</p>
<p>如果您沒有要求重設密碼，請忽略這封郵件。</p>
<p>謝謝</p>
<p>CodingBar 團隊敬上</p>
`