import { v4 as uuid } from 'uuid';
import i18n from 'lang/i18n';
import moment from 'moment';
import axios from 'axios';
import { time2Text } from "./time";
import { codingbarApi } from 'codingbar-api';
import { API_URL } from '../../settings';

export function value(val, defVal) {
	return val ? val : (defVal ? defVal : '');
};

export function shuffleList(array) {
	let currentIndex = array.length, temporaryValue, randomIndex;
	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(getSafeRandomNumber() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}

/**
* shuffle an array.
* @param  {array} array 
*/
export function shuffleArray(array) {
	if (!array) return;
	const arrayCopy = [...array];
	for (let i = arrayCopy.length - 1; i > 0; i--) {
		const j = Math.floor(getSafeRandomNumber() * (i + 1));
		[arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
	}
	return arrayCopy;
}

export function getUUID() {
	return uuid();
}

export function timeSince(time) {

	switch (typeof time) {
		case 'number':
			break;
		case 'string':
			time = +new Date(time);
			break;
		case 'object':
			if (time.constructor === Date) time = time.getTime();
			break;
		default:
			time = +new Date();
	}
	var time_formats = [
		[60, '秒', 1], // 60
		[120, '1分鐘以前', '1 minute from now'], // 60*2
		[3600, '分鐘', 60], // 60*60, 60
		[7200, '1小時以前', '1 hour from now'], // 60*60*2
		[86400, '小時', 3600], // 60*60*24, 60*60
		[172800, '昨天', '明天'], // 60*60*24*2
		[604800, '天', 86400], // 60*60*24*7, 60*60*24
		[1209600, '上週', '下周'], // 60*60*24*7*4*2
		[2419200, '周', 604800], // 60*60*24*7*4, 60*60*24*7
		[4838400, '上個月', '下個月'], // 60*60*24*7*4*2
		[29030400, '月', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
		[58060800, '去年', '明年'], // 60*60*24*7*4*12*2
		[2903040000, '年', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
		[5806080000, '上個世紀', '下個世紀'], // 60*60*24*7*4*12*100*2
		[58060800000, '世紀', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
	];
	var seconds = (+new Date() - time) / 1000,
		token = '以前',
		list_choice = 1;

	if (seconds == 0) {
		return '剛剛'
	}
	if (seconds < 0) {
		seconds = Math.abs(seconds);
		token = '現在';
		list_choice = 2;
	}
	var i = 0,
		format;
	while (format = time_formats[i++])
		if (seconds < format[0]) {
			if (typeof format[2] == 'string')
				return format[list_choice];
			else
				return Math.floor(seconds / format[2]) + '' + format[1] + '' + token;
		}
	return time;
}


export function email2UID(str, replace) {
	if (!str) {
		return str;
	}
	return str.replace(/[&\/\\#,+()$~%.@'":*?<>{}]/g, replace ? replace : '-').toLowerCase();
};

export function startOfWeek(date) {
	var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

	let beginningOfWeek = new Date(date.setDate(diff));
	let beginningOfWeekYYYY = beginningOfWeek.getFullYear()
	let beginningOfWeekMM = beginningOfWeek.getMonth() + 1
	let beginningOfWeekDD = beginningOfWeek.getDate()
	let formattedYYYYMMDD = `${beginningOfWeekYYYY}-${beginningOfWeekMM}-${beginningOfWeekDD}`

	// return Monday 00:00 of the current week in Unix millisecond timestamp
	let output = moment(formattedYYYYMMDD, 'YYYY-MM-DD').format("x")
	return output
}

export function endOfWeek(date) {
	var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 7);

	let endOfWeek = new Date(date.setDate(diff));
	let endOfWeekYYYY = endOfWeek.getFullYear()
	let endOfWeekMM = endOfWeek.getMonth() + 1
	let endOfWeekDD = endOfWeek.getDate()
	let formattedYYYYMMDD = `${endOfWeekYYYY}-${endOfWeekMM}-${endOfWeekDD}-23-59-59`

	// return Sunday 11:59 of the current week in Unix millisecond timestamp
	let output = moment(formattedYYYYMMDD, 'YYYY-MM-DD-HH-mm-ss').format("x")
	return output
}

export function copyText(text) {
	console.log(`copy text ${text}`)
	// document and select() only allows user interactive elements. Does not work on <span>
	// create ghost element, copy, and remove ghost element
	var ghostTextArea = document.createElement("textarea")
	ghostTextArea.value = text
	document.body.appendChild(ghostTextArea)
	ghostTextArea.select()
	document.execCommand("Copy")

	// remove ghost area
	ghostTextArea.remove();
}

export function downloadFile(fileUrl, filename) {
	axios({
		url: fileUrl,
		method: 'GET',
		responseType: 'blob', // important
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
	});
}

export function objectToList(object, sortFunc) {
	if (!object) {
		return [];
	}

	const list = [];
	Object.keys(object).forEach(key => {
		list.push({
			key: key,
			value: object[key]
		});
	});

	if (sortFunc) {
		list.sort((a, b) => {
			return sortFunc(a.value, b.value);
		});
	}
	return list;
};

export function toList(object) {
	if (!object) {
		return [];
	}

	const list = [];
	Object.keys(object).forEach(key => {
		list.push(object[key]);
	});
	return list;
};

export function sendEmail(receivers, subject, content) {
	return codingbarApi.getCoreService().sendEmail(receivers, subject, content);
};

export function sendEmailEx(receivers, subject, content, ccList = []) {
	return codingbarApi.getCoreService().sendEmailEx(receivers, subject, content, ccList)
};

export function dumpData(collection, filter) {

	return fetch(`${API_URL.DATABASE}/data/dump`, {
		body: JSON.stringify({ collection: collection, filter: filter }),
		cache: 'no-cache',
		headers: {
			'content-type': 'application/json'
		},
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, cors, *same-origin
		redirect: 'follow', // manual, *follow, error
		referrer: 'no-referrer', // *client, no-referrer
	}).then(response => response.text())
};

export function queryData(collection, filter, sort) {

	return fetch(`${API_URL.DATABASE}/data/select`, {
		body: JSON.stringify({ collection: collection, filter: filter, sort: sort }),
		cache: 'no-cache',
		headers: {
			'content-type': 'application/json'
		},
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, cors, *same-origin
		redirect: 'follow', // manual, *follow, error
		referrer: 'no-referrer', // *client, no-referrer
	}).then(response => response.json())
};

export function countBytes(beginTime, endTime) {
	const api = "https://api2.codingbar.ai/service/data/aggregate";

	return fetch(`${api}`, {
		body: JSON.stringify({
			collection: "data_stats", pipeline: [
				{
					"$match": {
						updated: {
							$gte: beginTime,
							$lte: endTime
						}
					}
				},
				{ "$group": { "_id": null, "sum": { "$sum": "$total" } } }
			]
		}),
		cache: 'no-cache',
		headers: {
			'content-type': 'application/json'
		},
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, cors, *same-origin
		redirect: 'follow', // manual, *follow, error
		referrer: 'no-referrer', // *client, no-referrer
	}).then(response => response.json())

}


export function isElectron() {
	// return true;
	return (
		typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
	);
}

export function log(...args) {
	const len = args.length;
	switch (len) {
		case 1:
			console.log(`[DEBUG] ${time2Text(Date.now())} ${args[0]}`);
			break;
		case 2:
			console.log(`[DEBUG] ${time2Text(Date.now())} ${args[0]}`, args[1]);
			break;
	}
}

export function error(tag, msg) {
	console.error(`[ERROR] ${time2Text(Date.now())} ${tag}`, msg ? msg : "")
}

export function sortString(list, key) {

	return list.sort((a, b) => {
		if (a[key] < b[key])
			return -1;

		if (a[key] > b[key])
			return 1;

		return 0;
	})
}

export function openInNewWindow(url) {
	const win = window.open("https://www.codingbar.ai", '_blank');
	win.focus();
}

export function formatCurrency(input) {
	return (input + "").replace(/\B(?=(\d{3})+(?!\d))/g, ","); //NOSONAR
}

/**
 * "3,000.00" -> 3000
 * @param {string} currencyString
 * @return {number}
 */
export function convertCurrencyToNumber(currencyString) {
	return parseFloat(currencyString.replace(/[^0-9.-]+/g, ''));
}

/**
 * 生成隨機條碼.
 * @param {string} length - 條碼長度
 * @param {string} unwantedChar - 不想在條碼中出現的字元
 * @return {string} 隨機生成的條碼.
 */
export function generateRandomCode(length = 6, unwantedChar = "I1O0MN") {
	let result = '';
	const reg = new RegExp(`[${unwantedChar}]`, "g")
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.replace(reg, '');

	const charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(getSafeRandomNumber() * charactersLength));
	}
	return result;
}

function confirmCloseListener(e) {
	(e || window.event).returnValue = ""; // Gecko + IE
	return ""; // Gecko + Webkit, Safari, Chrome etc.
};  // 放在window.底下，才能讓其他component來remove

export function addConfirmCloseListener() {
	window.addEventListener("beforeunload", confirmCloseListener);
}

export function removeConfirmCloseListener() {
	window.removeEventListener("beforeunload", confirmCloseListener);
}

export function unixTextToHtml(text) {
	const html = text.replace(/\n/g, "<br />");
	return html;
}
export function htmlToUnixText(html) {
	// todo not implement yet
	return html
}

export function isEmailValid(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email); // return boolean
}

export function getLocalObject(key) {
	const value = localStorage.getItem(key);

	if (!value || value === null) {
		return "";
	}

	return JSON.parse(value);
}

export function removeLocalItem(key) {
	localStorage.removeItem(key);
}

export function padLeft(str, len = 3) {
	str = '' + str;
	if (str.length >= len) {
		return str;
	} else {
		return padLeft("0" + str, len);
	}
}

export function padRight(str, len = 3) {
	str = '' + str;
	if (str.length >= len) {
		return str;
	} else {
		return padRight(str + "0", len);
	}
}

export function getDuration(millisecond) {
	const m = moment.duration(millisecond)
	return padLeft(m.asHours() | 0, 2) + ":" + padLeft(m.minutes(), 2)
}

export function getStandardDeviation(data, average) {
	if (!data) return

	const N = data.length
	let TotalPower = 0
	data.forEach(el => {
		TotalPower = TotalPower + el ** 2
	})

	return Math.sqrt((TotalPower / N) - average ** 2)
}

export function getNormalDistributionBound(questionsAverage, questionsSD) {
	const LowerTwoBound = (questionsAverage - 2 * questionsSD > 0) ? questionsAverage - 2 * questionsSD : 0
	const LowerOneBound = (questionsAverage - questionsSD > 0) ? questionsAverage - questionsSD : 0
	return [LowerTwoBound,
		LowerOneBound,
		questionsAverage + questionsSD,
		questionsAverage + 2 * questionsSD]
}

export function getEvaluation(type, value) {  //評語標準因為分兩種，percent 和 標準差，用 type 區別
	if (type === 'percent') {
		if (value >= 80) {
			return 'EXC'
		} else if (value >= 60) {
			return 'Good'
		} else if (value >= 40) {
			return 'Normal'
		} else if (value >= 20) {
			return 'Fair'
		} else {
			return 'Poor'
		}
	} else if (type === 'SD') {
		if (value === 2) {
			return 'EXC'
		} else if (value === 1) {
			return 'Good'
		} else if (value === -1) {
			return 'Fair'
		} else if (value === -2) {
			return 'Poor'
		} else {
			return 'Normal'
		}
	}
}

export function transferExerciseTypeName(value) {
	switch (value) {
		case 'freecode':
			return i18n.t('component.exerciseManage.tab.freeCode');
		case 'slot-filling':
			return i18n.t('component.exerciseManage.tab.fillin');
		case 'cloze':
			return i18n.t('component.exerciseManage.tab.cloze');
		case 'dragdrop':
			return i18n.t('component.exerciseManage.tab.dnd');
		case 'choice':
			return i18n.t('component.exerciseManage.tab.choice');
		case 'rearrangement':
			return i18n.t('component.exerciseManage.tab.rearrangement');
		case 'video':
			return i18n.t('component.exerciseManage.tab.video');
		case 'step-by-step':
			return i18n.t('component.exerciseManage.tab.step');
		default:
			return i18n.t('component.exerciseManage.tab.freeCode');
	}
}

/**
* Get a random item from the input array.
* @param  {array} array 
*/
export function getRandomItemFromAnArray(array) {
	return array[Math.floor(getSafeRandomNumber() * array.length)];
}


/**
* generate a random Key by currentTimeStamp
* @param  {string} string 
*/
function number2Letter(string) {
	const alpha = 'ABCDEFGHJK';

	let key = '';
	for (let i = 0; i < string.length; i++) {
		key += alpha[parseInt(string[i])]
	}
	
	return key;
}

/**
* generate a random Key by currentTimeStamp
* @param  {string} uuid 
* @param  {timeStamp} number 
*/
export function transferUUIDToKey( uuid, timeStamp ) {
	return (uuid.replaceAll('-', '') + number2Letter(timeStamp.toString())).toUpperCase()
}

/**
* format csv rowValue to clear string
* @param  {string} row 
* @param  {number} idx 
*/
export function getRowValue(row, idx) {
	if (!row) {
		return '';
	}

	if (idx >= row.length) {
		return '';
	}

	return row[idx] ? row[idx] : '';
};

/**
* replace Math.random safely for weak cryptography;
*/
export const getSafeRandomNumber = () => {
	const randomBuffer = new Uint32Array(1)
	window.crypto.getRandomValues(randomBuffer)
	return randomBuffer[0] / (0xFFFFFFFF + 1)
}