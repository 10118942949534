import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './PillButton.scss';

export default class PillButton extends React.PureComponent {
	static propTypes = {
		className: PropTypes.string,
		onClick: PropTypes.func,
		btnText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node]),
		isDisabled: PropTypes.bool,
		secondary: PropTypes.bool,
	};

	render() {
		const { className, onClick, btnText, isDisabled, secondary } = this.props;

		return (
			<div
				className={cx('coding-bar-pill-btn', className, { secondary }, { disabled: isDisabled })}
				onClick={!isDisabled && onClick ? onClick : null}
				aria-hidden="true"
			>
				{btnText}
			</div>
		);
	}
}
