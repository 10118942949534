import { TEST_ENV_ACCESS_LIST } from './collections';
import { isEmpty } from 'lodash-es';

export const getCodingBarBetaAccessUnitList = () => {
	const filter = {
		testType: 'beta',
	};

	return codingbarApi
		.getCoreService()
		.queryData(TEST_ENV_ACCESS_LIST, filter, {}, {}, '')
		.then((res) => {
			return res.status === 'success' ? res.result : [];
		})
		.catch((err) => console.error(err));
};

export const insertCodingBarBetaAccessUnitList = (unit) => {
	if (!unit) return Promise.reject('insertCodingBarBetaAccessUnitList: unit is empty!');

	const value = {
		testType: 'beta',
		unit: unit,
		created: Date.now(),
	};

	return codingbarApi.getCoreService().createData(TEST_ENV_ACCESS_LIST, value);
};

export const removeCodingBarBetaAccessUnitList = (unit) => {
	if (!unit) return Promise.reject('removeCodingBarBetaAccessUnitList: unit is empty!');

	const filter = {
		testType: 'beta',
		unit: unit,
	};

	return codingbarApi.getCoreService().removeData(TEST_ENV_ACCESS_LIST, filter);
};

export const checkingForBetaUnit = async () => {
	const SYS = localStorage.getItem('SYS');

	if (!SYS) {
		const user = codingbarApi.getAuthService().getUser();

		const betaUnitList = await getCodingBarBetaAccessUnitList();
		const unitFilterList = betaUnitList.filter((obj) => obj.unit.toLowerCase() === user.unit.toLowerCase());
		const unitsFilterList = betaUnitList.filter((obj) =>
			user.units.some((item) => item.toLowerCase() === obj.unit.toLowerCase())
		);

		if (!isEmpty(unitFilterList) || !isEmpty(unitsFilterList)) {
			localStorage.setItem('SYS', 'beta');
			return true;
		}
		return false;
	}
	return true;
};
