import * as cons from '../constants/exerciseManage';
import { codingbarApi } from 'codingbar-api';
import { API_URL } from '../../settings';
import { generateRandomCode, getUUID } from '../../shared/util/utils';
import { EXERCISE_TYPE, EXERCISE_EDITOR_LANGUAGES } from '../constants/excercise';
import { COURSE_TYPE_DROPDOWN } from '../constants/course';
import i18n from 'lang/i18n';

export function getInitialState() {
	return {
		isEdit: false,  // new form or edit old form
		// isLoading: false,    // fetching data, posting data
		step: 0,
		totalSteps: 3,

		exerciseID: getUUID(),
		exerciseType: EXERCISE_TYPE.FREECODE,
		codeType: COURSE_TYPE_DROPDOWN[0],
		exerciseTitle: '',
		exerciseTag: '',
		exerciseTagArr: [],

		freeCode: {
			content: '',
			tips: [],
			answers: [],
			vds: [],
		},

		slotFilling: {
			content: '',
			slotFillingContent: '',
			tips: [],
			answers: [],
			vds: [],
		},

		cloze: {
			content: '',
			clozeContent: '',
			tips: [],
			answers: [],
			vds: [],
		},

		dragDrop: {
			content: '',
			dragDropContent: '',
			tips: [],
			answers: [],
			vds: [],
		},

		choice: {
			content: '',
			choiceContent: {},
			tips: [],
			answers: [],
		},

		rearrangement: {
			content: '',
			rearrangementContent: {},
			tips: [],
			answers: [],
		},

		video: {
			videoUrl: ""
		},

		stepByStep: {
			content: '',
			steps: []
		},

	};
}

export default function reducer(state = getInitialState(), action = {}) {
	const { type, payload } = action
	switch (type) {
		case cons.INIT: {
			return getInitialState()
		}

		case cons.SET_EXERCISE: {
			return {
				...state,
				...payload
			}
		}
		case cons.SET_FREE_CODE: {
			let freeCode = {
				...state.freeCode,
				...payload
			}
			return {
				...state,
				freeCode
			}
		}
		case cons.SET_CHOICE: {
			let choice = {
				...state.choice,
				...payload
			}
			return {
				...state,
				choice
			}
		}
		case cons.SET_REARRANGEMENT: {
			let rearrangement = {
				...state.rearrangement,
				...payload
			}
			return {
				...state,
				rearrangement
			}
		}
		case cons.SET_SLOT_FILLING: {
			let slotFilling = {
				...state.slotFilling,
				...payload
			}
			return {
				...state,
				slotFilling
			}
		}
		case cons.SET_CLOZE: {
			let cloze = {
				...state.cloze,
				...payload
			}
			return {
				...state,
				cloze
			}
		}
		case cons.SET_DRAGDROP: {
			let dragDrop = {
				...state.dragDrop,
				...payload
			}
			return {
				...state,
				dragDrop
			}
		}
		case cons.SET_VIDEO: {
			let video = {
				...state.video,
				...payload
			}
			return {
				...state,
				video
			}
		}
		case cons.SET_STEP_BY_STEP: {
			let stepByStep = {
				...state.stepByStep,
				...payload
			}
			return {
				...state,
				stepByStep
			}

		}

		case cons.SET_TOTAL_STEPS: {
			return {
				...state,
				totalSteps: payload.totalSteps
			}
		}

		case cons.GO_PREV_STEP: {
			let step = state.step === 0 ? state.step : state.step - 1
			return {
				...state,
				step
			}
		}

		case cons.GO_NEXT_STEP: {
			let step = state.step === state.totalSteps - 1 ? state.step : state.step + 1
			return {
				...state,
				step
			}
		}

		case cons.GO_STEP: {
			return {
				...state,
				step: payload.step
			}
		}

		case cons.SET_LOADING: {
			let { isLoading } = payload
			return { ...state, isLoading }
		}

		default:
			return state;
	}
}

export function initExerciseManage() {
	return { type: cons.INIT }
}


export function editExistedExerciseManage(exercise) {
	// return {
	//   type: cons.EDIT_EXISTED_REGISTRATION,
	//   payload: data
	// }
}

export function setTotalSteps(totalSteps = 5) {
	return {
		type: cons.SET_TOTAL_STEPS,
		payload: { totalSteps }
	}

}
export function goPrevStep() {
	return {
		type: cons.GO_PREV_STEP
	}
}
export function goNextStep() {
	return {
		type: cons.GO_NEXT_STEP
	}
}
export function goSpecificStep(step) {
	return {
		type: cons.GO_STEP,
		payload: { step }
	}
}



export function setExercise(data) {
	return (dispatch, getState) => {
		const prevData = getState().exerciseManage

		if (data.exerciseType && prevData.exerciseType !== data.exerciseType) {
			data.step = 0
			if (data.exerciseType.value === "video") data.codeType = EXERCISE_TYPE.VIDEO
			else if (prevData.codeType.value === "video") data.codeType = EXERCISE_EDITOR_LANGUAGES.PYTHON
		}

		if (data.codeType && prevData.codeType !== data.codeType) {
			if (data.codeType.value === "video") data.exerciseType = EXERCISE_TYPE.VIDEO
		}

		if (data.exerciseTag) {
			data.exerciseTagArr = data.exerciseTag.split('#')
				.map(tag => tag.trim())
				.filter(tag => tag)
		}

		dispatch({
			type: cons.SET_EXERCISE,
			payload: data
		})
	}
}

export function setFreeCode(data) {
	return {
		type: cons.SET_FREE_CODE,
		payload: data
	}
}
export function setChoice(data) {
	return {
		type: cons.SET_CHOICE,
		payload: data
	}
}
export function setRearrangement(data) {
	return {
		type: cons.SET_REARRANGEMENT,
		payload: data
	}
}
export function setSlotFilling(data) {
	return {
		type: cons.SET_SLOT_FILLING,
		payload: data
	}
}
export function setCloze(data) {
	return {
		type: cons.SET_CLOZE,
		payload: data
	}
}
export function setDragDrop(data) {
	return {
		type: cons.SET_DRAGDROP,
		payload: data
	}
}
export function setVideo(data) {
	return {
		type: cons.SET_VIDEO,
		payload: data
	}
}
export function setStepByStep(data) {
	return {
		type: cons.SET_STEP_BY_STEP,
		payload: data
	}
}



function parseFreecodeFromReduxToDB(reduxExercise) {
	let freeCode = reduxExercise.freeCode
	return {
		content: freeCode.content,
		tips: freeCode.tips,
		answers: freeCode.answers,
		vds: freeCode.vds,

		// reset values when exercise changes exerciseType
		choiceContent: "",
		rearrangementContent: "",
		slotFillingContent: "",
		clozeContent: "",
		dragDropContent: "",
		steps: "",
		videoUrl: "",
	}
}
function parseChoiceFromReduxToDB(reduxExercise) {
	let choice = reduxExercise.choice
	return {
		content: choice.content,
		choiceContent: choice.choiceContent,
		tips: choice.tips,
		answers: choice.answers,

		rearrangementContent: "",
		slotFillingContent: "",
		clozeContent: "",
		dragDropContent: "",
		steps: "",
		videoUrl: "",
	}
}
function parseRearrangementFromReduxToDB(reduxExercise) {
	let rearrangement = reduxExercise.rearrangement
	return {
		content: rearrangement.content,
		rearrangementContent: rearrangement.rearrangementContent,
		tips: rearrangement.tips,
		answers: rearrangement.answers,

		choiceContent: "",
		slotFillingContent: "",
		clozeContent: "",
		dragDropContent: "",
		steps: "",
		videoUrl: "",
	}
}
function parseSlotFillingFromReduxToDB(reduxExercise) {
	let slotFilling = reduxExercise.slotFilling;
	return {
		content: slotFilling.content,
		slotFillingContent: slotFilling.slotFillingContent,
		tips: slotFilling.tips,
		answers: slotFilling.answers,
		vds: slotFilling.vds,

		choiceContent: "",
		rearrangementContent: "",
		clozeContent: "",
		dragDropContent: "",
		steps: "",
		videoUrl: "",
	}
}
function parseClozeFromReduxToDB(reduxExercise) {
	let cloze = reduxExercise.cloze;
	return {
		content: cloze.content,
		clozeContent: cloze.clozeContent,
		tips: cloze.tips,
		answers: cloze.answers,
		vds: cloze.vds,

		choiceContent: "",
		rearrangementContent: "",
		slotFillingContent: "",
		dragDropContent: "",
		steps: "",
		videoUrl: "",
	}
}
function parseDragDropFromReduxToDB(reduxExercise) {
	let dragDrop = reduxExercise.dragDrop;
	return {
		content: dragDrop.content,
		dragDropContent: dragDrop.dragDropContent,
		tips: dragDrop.tips,
		answers: dragDrop.answers,
		vds: dragDrop.vds,

		choiceContent: "",
		rearrangementContent: "",
		slotFillingContent: "",
		clozeContent: "",
		steps: "",
		videoUrl: "",
	}
}
function parseStepByStepFromReduxToDB(reduxExercise) {
	const stepByStep = reduxExercise.stepByStep
	const data = {
		content: stepByStep.content,
		steps: (stepByStep.steps || []).map(step => {
			const { stepId, stepTitle, stepType, } = step
			console.log("parseStepByStepFromReduxToDB", step)
			if (stepType.value === EXERCISE_TYPE.FREECODE.value)
				return { stepId, stepTitle, stepType: stepType.value, ...step.freeCode }
			else if (stepType.value === EXERCISE_TYPE.CHOICE.value)
				return { stepId, stepTitle, stepType: stepType.value, ...step.choice }
			else if (stepType.value === EXERCISE_TYPE.REARRANGEMENT.value)
				return { stepId, stepTitle, stepType: stepType.value, ...step.rearrangement }
			else if (stepType.value === EXERCISE_TYPE.SLOT_FILLING.value)
				return { stepId, stepTitle, stepType: stepType.value, ...step.slotFilling }
			else if (stepType.value === EXERCISE_TYPE.CLOZE.value)
				return { stepId, stepTitle, stepType: stepType.value, ...step.cloze }
			else if (stepType.value === EXERCISE_TYPE.DRAGDROP.value)
				return { stepId, stepTitle, stepType: stepType.value, ...step.dragDrop }
			else if (stepType.value === EXERCISE_TYPE.VIDEO.value)
				return { stepId, stepTitle, stepType: stepType.value, ...step.video }
			else return { stepId, stepTitle, stepType: stepType.value, ...step }
		}),

		choiceContent: "",
		rearrangementContent: "",
		slotFillingContent: "",
		clozeContent: "",
		dragDropContent: "",
		videoUrl: "",
	}
	console.log("parseStepByStepFromReduxToDB final", data)
	return data
}
function parseVideoFromReduxToDB(reduxExercise) {
	let video = reduxExercise.video
	return {
		videoUrl: video.videoUrl,

		content: "",
		tips: "",
		answers: "",
		vds: "",

		choiceContent: "",
		rearrangementContent: "",
		slotFillingContent: "",
		clozeContent: "",
		dragDropContent: "",
		steps: "",
	}
}

export function parseExerciseFromReduxToDB(reduxExercise, options = {}) {
	let exercise = {
		id: reduxExercise.exerciseID,
		title: reduxExercise.exerciseTitle,
		tags: reduxExercise.exerciseTagArr,
		exerciseType: reduxExercise.exerciseType.value,
		codeType: reduxExercise.codeType.value,
		shareable: false,                   // useless now
		inClass: options.isInClass || null,
	}
	console.log("reduxExercise.exerciseType", reduxExercise.exerciseType)
	switch (exercise.exerciseType) {
		case EXERCISE_TYPE.FREECODE.value:
			return { ...exercise, ...parseFreecodeFromReduxToDB(reduxExercise) }
		case EXERCISE_TYPE.CHOICE.value:
			return { ...exercise, ...parseChoiceFromReduxToDB(reduxExercise) }
		case EXERCISE_TYPE.REARRANGEMENT.value:
			return { ...exercise, ...parseRearrangementFromReduxToDB(reduxExercise) }
		case EXERCISE_TYPE.SLOT_FILLING.value:
			return { ...exercise, ...parseSlotFillingFromReduxToDB(reduxExercise) }
		case EXERCISE_TYPE.CLOZE.value:
			return { ...exercise, ...parseClozeFromReduxToDB(reduxExercise) }
		case EXERCISE_TYPE.DRAGDROP.value:
			return { ...exercise, ...parseDragDropFromReduxToDB(reduxExercise) }
		case EXERCISE_TYPE.STEP_BY_STEP.value:
			return { ...exercise, ...parseStepByStepFromReduxToDB(reduxExercise) }
		case EXERCISE_TYPE.VIDEO.value:
			return { ...exercise, ...parseVideoFromReduxToDB(reduxExercise) }
		default:
			alert(i18n.t('component.exerciseManage.alert.somethingWrong'))
			return exercise
	}

}


function parseFreecodeFromDBToRedux(dbExercise) {
	return {
		content: dbExercise.content || "",
		tips: dbExercise.tips || [],
		answers: dbExercise.answers || [],
		vds: dbExercise.vds || []
	}
}
function parseChoiceFromDBToRedux(dbExercise) {
	return {
		content: dbExercise.content || "",
		choiceContent: dbExercise.choiceContent || {},
		tips: dbExercise.tips || [],
		answers: dbExercise.answers || [],
	}
}
function parseSlotFillingFromDBToRedux(dbExercise) {
	return {
		content: dbExercise.content || "",
		slotFillingContent: dbExercise.slotFillingContent || {},
		tips: dbExercise.tips || [],
		answers: dbExercise.answers || [],
		vds: dbExercise.vds || []
	}
}
function parseClozeFromDBToRedux(dbExercise) {
	return {
		content: dbExercise.content || "",
		clozeContent: dbExercise.clozeContent || "",
		tips: dbExercise.tips || [],
		answers: dbExercise.answers || [],
		vds: dbExercise.vds || []
	}
}
function parseDragDropFromDBToRedux(dbExercise) {
	return {
		content: dbExercise.content || "",
		dragDropContent: dbExercise.dragDropContent || "",
		tips: dbExercise.tips || [],
		answers: dbExercise.answers || [],
		vds: dbExercise.vds || []
	}
}
function parseRearrangementFromDBToRedux(dbExercise) {
	return {
		content: dbExercise.content || "",
		rearrangementContent: dbExercise.rearrangementContent || {},
		tips: dbExercise.tips || [],
		answers: dbExercise.answers || [],
	}
}
function parseVideoFromDBToRedux(dbExercise) {
	return {
		content: dbExercise.content || "",
		videoUrl: dbExercise.videoUrl || {},
		tips: dbExercise.tips || [],
		answers: dbExercise.answers || [],
		vds: dbExercise.vds || []
	}
}
function parseStepByStepFromDBToRedux(dbExercise) {
	const data = {
		content: dbExercise.content || "",
		steps: (dbExercise.steps || []).map(step => {
			console.log("parseStepByStepFromDBToRedux", step)
			const { stepId, stepTitle, stepType, ...remain } = step
			if (stepType === EXERCISE_TYPE.FREECODE.value)
				return { stepId, stepTitle, stepType: EXERCISE_TYPE.FREECODE, freeCode: remain }
			else if (stepType === EXERCISE_TYPE.CHOICE.value)
				return { stepId, stepTitle, stepType: EXERCISE_TYPE.CHOICE, choice: remain }
			else if (stepType === EXERCISE_TYPE.REARRANGEMENT.value)
				return { stepId, stepTitle, stepType: EXERCISE_TYPE.REARRANGEMENT, rearrangement: remain }
			else if (stepType === EXERCISE_TYPE.SLOT_FILLING.value)
				return { stepId, stepTitle, stepType: EXERCISE_TYPE.SLOT_FILLING, slotFilling: remain }
			else if (stepType === EXERCISE_TYPE.CLOZE.value)
				return { stepId, stepTitle, stepType: EXERCISE_TYPE.CLOZE, cloze: remain }
			else if (stepType === EXERCISE_TYPE.DRAGDROP.value)
				return { stepId, stepTitle, stepType: EXERCISE_TYPE.DRAGDROP, dragDrop: remain }
			else if (stepType === EXERCISE_TYPE.VIDEO.value)
				return { stepId, stepTitle, stepType: EXERCISE_TYPE.VIDEO, video: remain }
			else return { stepId, stepTitle, stepType: EXERCISE_TYPE.FREECODE }
		})
	}
	console.log("parseStepByStepFromDBToRedux final", data)
	return data
}
export function parseExerciseFromDBToRedux(dbExercise) {
	const dbExerciseCodeTypeValue = dbExercise.codeType != null ? dbExercise.codeType : COURSE_TYPE_DROPDOWN[0].value

	let exercise = {
		isEdit: true,
		exerciseID: dbExercise.id,
		exerciseTitle: dbExercise.title,
		exerciseTag: (dbExercise.tags || []).map(item => '#' + item).join(' '),
		codeType: COURSE_TYPE_DROPDOWN.find(o => o.value == dbExerciseCodeTypeValue) || COURSE_TYPE_DROPDOWN[0],
	}
	if (
		(dbExercise.exerciseType === EXERCISE_TYPE.FREECODE.value)
	) {
		exercise.exerciseType = EXERCISE_TYPE.FREECODE
		return { ...exercise, freeCode: parseFreecodeFromDBToRedux(dbExercise) }
	}
	else if (
		(dbExercise.exerciseType === EXERCISE_TYPE.CHOICE.value)
		|| dbExercise.choiceContent
	) {
		exercise.exerciseType = EXERCISE_TYPE.CHOICE
		return { ...exercise, choice: parseChoiceFromDBToRedux(dbExercise) }
	}
	else if (
		(dbExercise.exerciseType === EXERCISE_TYPE.SLOT_FILLING.value)
		|| dbExercise.slotFillingContent
	) {
		exercise.exerciseType = EXERCISE_TYPE.SLOT_FILLING
		return { ...exercise, slotFilling: parseSlotFillingFromDBToRedux(dbExercise) }
	}
	else if (
		(dbExercise.exerciseType === EXERCISE_TYPE.CLOZE.value)
		|| dbExercise.clozeContent
	) {
		exercise.exerciseType = EXERCISE_TYPE.CLOZE
		return { ...exercise, cloze: parseClozeFromDBToRedux(dbExercise) }
	}
	else if (
		(dbExercise.exerciseType === EXERCISE_TYPE.DRAGDROP.value)
		|| dbExercise.dragDropContent
	) {
		exercise.exerciseType = EXERCISE_TYPE.DRAGDROP
		return { ...exercise, dragDrop: parseDragDropFromDBToRedux(dbExercise) }
	}
	else if (
		(dbExercise.exerciseType === EXERCISE_TYPE.REARRANGEMENT.value)
		|| dbExercise.rearrangementContent
	) {
		exercise.exerciseType = EXERCISE_TYPE.REARRANGEMENT
		return { ...exercise, rearrangement: parseRearrangementFromDBToRedux(dbExercise) }
	}
	else if (
		(dbExercise.exerciseType === EXERCISE_TYPE.VIDEO.value)
		|| dbExercise.videoUrl
	) {
		exercise.exerciseType = EXERCISE_TYPE.VIDEO
		exercise.codeType = EXERCISE_TYPE.VIDEO
		return { ...exercise, video: parseVideoFromDBToRedux(dbExercise) }
	}
	else if (
		(dbExercise.exerciseType === EXERCISE_TYPE.STEP_BY_STEP.value)
	) {
		exercise.exerciseType = EXERCISE_TYPE.STEP_BY_STEP
		return { ...exercise, stepByStep: parseStepByStepFromDBToRedux(dbExercise) }
	}
	else {
		try {
			exercise.exerciseType = EXERCISE_TYPE.FREECODE
			return { ...exercise, freeCode: parseFreecodeFromDBToRedux(dbExercise) }
		} catch (error) {
			console.log("error", error)
			alert('有東西出錯了！若重複發生，請填寫問題與反饋')
		}
	}
}
