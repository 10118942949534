import { codingbarApi } from "codingbar-api";

export function fetchCurrentUnitDomain(unit) {
	return codingbarApi.getUserService().getCodingbarDomainAccessUnitList()
	.then(list => {
		if (list.some(item => item.unit === unit.toLowerCase())) return 'app'; // unit is using app.codingbar.ai
		return 'school'; // unit is using school.codingbar.ai
	})
	.catch(err => console.error(err));
}

export function fetchSchoolUnitDomainList() {
	return codingbarApi.getUserService().getCodingbarDomainAccessUnitList()
	.then(res => {
		return (res) ? res : []
	})
	.catch(err => console.error(err));
}