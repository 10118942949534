import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Modal, Button } from 'components';
import renderHTML from 'react-render-html';
import moment from 'moment';
import cx from 'classnames';
import * as cons from 'redux/constants/app';

import AncmNoticeIcon from 'design/images/icons/icon-notify-cheerup.svg';
import AncmAgreeIcon from 'design/images/icons/icon-notify-homework.svg';
import AncmAlertIcon from 'design/images/icons/icon-notify-challenge.svg';
import NoMessages from 'design/images/TeacherPage/teacher-notification-no-messages.svg';
import './AnnouncementListModal.scss';

@connect((state) => {
	const app = state.app;
	return {
		user: app.get('user'),
		ancmList: app.get('ancmList') ? app.get('ancmList').toJSON() : [],
	};
})
	
@translate('translations')
export default class AnnouncementListModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedIdxs: [],
		};
	}

	getAncmIcon = (type) => {
		switch (type) { 
			case 'announcement':
				return AncmNoticeIcon;
			case 'agreement':
				return AncmAgreeIcon;
			case 'alert':
				return AncmAlertIcon;
			default:
				return AncmNoticeIcon
		}
	}

	onToggleExpandNoticeContent = (ancm, idx) => {
		const { expandedIdxs } = this.state;

		this.onChangeReadUidList(ancm);

		this.setState((prevState) => ({
			expandedIdxs: prevState.expandedIdxs.includes(idx)
				? expandedIdxs.filter((el) => el !== idx)
				: [...prevState.expandedIdxs, idx],
		}));
	};

	onChangeReadUidList = (ancm) => {
		const { ancmList, dispatch } = this.props;
		if (ancm.markAsReadUidList && ancm.markAsReadUidList.includes(this.props.user.get('uid'))) return;

		codingbarApi.getBulletinService().markAsRead(ancm.id)
		.then(() => {
			//  有點開未閱讀的公告時，要更新 redux 的 ancmList 的 markAsReadUidList
			dispatch({
				type: cons.GET_ANNOUNCEMENT_LIST, ancmList: ancmList.reduce((arr, el) => {
					if (el.id !== ancm.id) return [...arr, el]
					return [...arr, { ...el, markAsReadUidList: (el.markAsReadUidList) ? (!el.markAsReadUidList.includes(this.props.user.get('uid'))) ? [...el.markAsReadUidList, this.props.user.get('uid')] : el.markAsReadUidList : [ this.props.user.get('uid') ]}]
				}, [])
			});
		})
		.catch(err => console.error(err))
	}

	replaceValueWhiteSpace = (text) => {
		return (
			text
			.replace(/^\s+/gm, "") // remove html indentation
			.replace(/(\r\n|\n|\r)/gm,"") // remove html line breaks
			.replaceAll("</br>", "")
		)
	}

	renderList = () => {
		const { ancmList, user, t } = this.props;
		const sortByStartTime = ancmList.sort((a, b) => b.startTime - a.startTime);

		if (ancmList.length === 0) {
			return (
				<div className="no-list">
					<p>{t('announcement.announcementList.noAnnounce')}</p>
					<img src={NoMessages} />
				</div>
			);
		}

		return sortByStartTime.map((el, idx) => {
			console.log('zzz', el)
			return (
				<div className="list-body-row" key={el.id}>
					<img src={this.getAncmIcon(el.type)} className="img-cont" />

					<div className="text-cont">
						<div className={cx("text-title-row", { 'unRead': !el.markAsReadUidList.includes(user.get('uid')) })} onClick={() => this.onToggleExpandNoticeContent(el, idx)}>
							<p className="title">{el.title}</p>
							<p className="subtext">{moment(el.startTime).format('YYYY/MM/DD HH:mm')}</p>
						</div>

						<div className={`subtext ${!this.state.expandedIdxs.includes(idx) ? "folded-subtext" : "unfolded-subtext"}`}
							style={{ 'WebkitBoxOrient': !this.state.expandedIdxs.includes(idx) ? 'vertical' : 'initial' }}>
							{/* -webkit-box-orient 這個 inner-style 是為了處理收合的垂直方向顯示, 平常寫進 scss 裡會被 webpack 殺掉QQ 所以放在這邊不要動!!! */}
							{(el.content.includes('<p>') ?  // due to fit old format without html textArea
								renderHTML(this.replaceValueWhiteSpace(el.content))
								:
								el.content
							)}
						</div>
					</div>
				</div>
			);
		});
	};

	render() {
		const { isAnnouncementListModalOpen, toggleAnnouncementListModal, t } = this.props;

		return (
			<Modal className="announcement-list-modal" isOpen={isAnnouncementListModalOpen} onClose={toggleAnnouncementListModal}>
				<div className="announcement-list-body">
					<div className="list-body-title">{t('announcementList.title')}</div>

					<div className="list-body-content">{this.renderList()}</div>
				</div>

				<div className="announcement-list-bottom">
					<Button primary onClick={toggleAnnouncementListModal}>
						{t('announcement.closeAncm')}
					</Button>
				</div>
			</Modal>
		);
	}
}