import * as cons from '../constants/registrationManage';
import { codingbarApi } from 'codingbar-api';
import { API_URL } from '../../settings';
import { generateRandomCode } from '../../shared/util/utils';
import { checkIfFormCanEnabled } from '../../shared/util/registrationOldUtils';

export function getInitialState() {
  return {
    isEdit: false,  // new form or edit old form
    isLoading: false,    // fetching data, posting data
    savedStatus: "",    // post data status
    step: 1,

    enabled: false,

    // step 1
    formId: generateRandomCode(),
    payMethodSelected: "all",
    isGroupRegistration: false,
    title: "",
    subtitle: "",
    description: "",

    // step 2
    placeClassOptions: [
      // {
      //   place: "台北",
      //   classOptions: [
      //     {
      //       value: "台北A班",
      //       subtitle: "每週六下午 01:30 - 04:30",
      //       description: "台灣文創訓練中心，台北市松江路131號2樓之4 (捷運松江南京站7號出口步行約3-5分鐘)"
      //     }
      //   ]
      // },
    ],

    // step 3
    priceOrigin: [
      // { payAmount: 10000, startTime: '2019-08-01', endTime: '2019-09-30' },
    ],
    // enableGroupDiscount: true,
    priceGroupDiscount: [
      // { numOfStudent: 2, payAmount: 10000, startTime: '2019-08-01', endTime: '2019-09-30' },
    ],
    enableOldStudentDiscount: false,
    priceOldStudentDiscount: [
      // { payAmount: 10000, startTime: '2019-08-01', endTime: '2019-09-30' },
    ],
    enableMultiStudentDiscount: false,
    priceMultiStudentDiscount: [
      // { numOfStudent: 2, payAmount: 18000, startTime: '2020-01-01', endTime: '2020-03-30' },
    ],
    enableCouponCode: false,
    priceCouponCodeDiscount: [
      // { code: "5XG97G", payAmount: 12500, startTime: '2019-08-01', endTime: '2019-09-30', maxCount: 10 },
    ],

    // step 4
    enableInfoFrom: true,
    infoFromOptions: ["CodingBar 粉專", "IG 廣告", "FB廣告", "Google 搜尋", "親友推薦", "網頁廣告", "DM"],
    enableGradeSelect: true,
    gradeSelectOptions: ["五年級", "六年級", "七年級", "八年級", "九年級", "高一", "高二", "高三",],
    enableTaxNumber: false,
    enableVegan: false,
    enableRentLaptop: false,
    enableBirthdayAndIdentityCardNumber: true,
    enableOthers: false,
    othersOptions: ["自行駕車，需要停車證"],

    // step 5
    emailPaymentTitle: "【 {{title}} 】繳費確認通知",
    emailPaymentContent: `<div>親愛的家長及學員 您好：</div>
<div>  </div>
<div>感謝您報名參與 {{title}}</div>
{{studentList}}
<div>我們已收到您的課程款項費用 $ {{payAmount}}，</div>
<div>開課前一週將會寄發【開課通知】，</div>
<div>感謝您的參與與支持，如有任何問題或建議，</div>
<div>歡迎您透過LINE與我們反應與交流，謝謝！</div>
<div>LINE 帳號：@CodingBar（或直接點選網址：http://nav.cx/1pmQ6m8 ）</div>
<div>也請務必將 service@codingbar.ai 設為常用聯絡人，避免漏掉後續重要通知！</div>
<div>  </div>
<div>如有任何問題或建議，請於上班時間來電 02-7717-9386 或透過 LINE 聯繫我們，此為系統自動發送信件請勿直接回覆，謝謝。</div>
`,
    emailNotPaidTitle: "【 {{title}} 】報名確認通知",
    emailNotPaidContent: `<div>親愛的家長及學員 您好：</div>
<div>  </div>
<div>恭喜您完成報名 {{title}}</div>
{{studentList}}
<div>  </div>
<div>我們提供多元繳費方式，課程名額將視繳費順序進行保留</div>
<div>請點選下方繳費連結並於三天之內繳費完畢</div>
<div>課程費用 $ {{payAmount}}</div>
<div>繳費連結 {{payLink}}</div>
<div>完成繳費後將會寄送繳費確認信件至您的信箱，屆時請將 service@codingbar.ai 設為常用聯絡人，避免漏掉後續重要通知！</div>
<div>  </div>
<div>感謝您的參與，歡迎加入CodingBar官方LINE帳號：@CodingBar（或直接點選網址：http://nav.cx/1pmQ6m8 ）以獲取課程營隊與程式教育最新資訊！</div>
<div>  </div>
<div>如有任何問題或建議，請於上班時間來電 02-7717-9386 或透過 LINE 聯繫我們，謝謝。</div>
`,
    policyUrl: "https://drive.google.com/file/d/19YjbkEGFRuWINels29mnaFxF85HMixIM/view",

    // step 6
    GlobalGACodeList: [],
    GACodeList: [],
    GlobalFACodeList: [],
    FACodeList: [],

  };
}

export default function reducer(state = getInitialState(), action = {}) {
  const { type, payload } = action
  switch (type) {
    case cons.REGISTRATION_SETTING_INIT: {
      return getInitialState()
    }

    case cons.EDIT_EXISTED_REGISTRATION: {
      let {
        enabled, formId, payMethodSelected, isGroupRegistration,
        title, subtitle, description, placeClassOptions,
        priceOrigin, /*enableGroupDiscount,*/ priceGroupDiscount, enableOldStudentDiscount, priceOldStudentDiscount,
        enableMultiStudentDiscount, priceMultiStudentDiscount, enableCouponCode, priceCouponCodeDiscount,
        enableInfoFrom, infoFromOptions,
        enableGradeSelect, gradeSelectOptions,
        enableTaxNumber, enableVegan, enableRentLaptop, enableBirthdayAndIdentityCardNumber,
        enableOthers, othersOptions,
        emailPaymentTitle, emailPaymentContent, emailNotCompletedTitle, emailNotCompletedContent,
        emailNotPaidTitle, emailNotPaidContent, policyUrl,
        GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
      } = payload
      return {
        ...state,
        isEdit: true,
        enabled, formId, payMethodSelected, isGroupRegistration,
        title, subtitle, description, placeClassOptions,
        priceOrigin, /*enableGroupDiscount,*/ priceGroupDiscount, enableOldStudentDiscount, priceOldStudentDiscount,
        enableMultiStudentDiscount, priceMultiStudentDiscount, enableCouponCode, priceCouponCodeDiscount,
        enableInfoFrom, infoFromOptions,
        enableGradeSelect: (enableGradeSelect!=null ? enableGradeSelect : state.enableGradeSelect), 
        gradeSelectOptions: (gradeSelectOptions || state.gradeSelectOptions),
        enableTaxNumber, enableVegan, enableRentLaptop, enableBirthdayAndIdentityCardNumber,
        enableOthers, othersOptions,
        emailPaymentTitle, emailPaymentContent, emailNotCompletedTitle, emailNotCompletedContent,
        emailNotPaidTitle, emailNotPaidContent, policyUrl,
        GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
      }
    }

    case cons.CHECK_IF_FORM_CAN_ENABLED: {
      let canEnabled = Boolean(checkIfFormCanEnabled(state))
      let enabled = state.enabled && canEnabled
      return {
        ...state,
        enabled
      }
    }

    case cons.SAVE_STEP_ONE: {
      let { enabled, formId, payMethodSelected, isGroupRegistration, title, subtitle, description } = payload
      return {
        ...state,
        enabled,
        formId,
        payMethodSelected,
        isGroupRegistration,
        title,
        subtitle,
        description
      }
    }

    case cons.SAVE_STEP_TWO: {
      let { placeClassOptions } = payload
      return {
        ...state,
        placeClassOptions,
      }
    }

    case cons.SAVE_STEP_THREE: {
      let { priceOrigin, /*enableGroupDiscount,*/ priceGroupDiscount, enableOldStudentDiscount, priceOldStudentDiscount, enableMultiStudentDiscount, priceMultiStudentDiscount, enableCouponCode, priceCouponCodeDiscount, } = payload
      return {
        ...state,
        priceOrigin,
        // enableGroupDiscount,
        priceGroupDiscount,
        enableOldStudentDiscount,
        priceOldStudentDiscount,
        enableMultiStudentDiscount,
        priceMultiStudentDiscount,
        enableCouponCode,
        priceCouponCodeDiscount,
      }
    }

    case cons.SAVE_STEP_FOUR: {
      let { enableInfoFrom, infoFromOptions, enableGradeSelect, gradeSelectOptions, enableTaxNumber, enableVegan, enableRentLaptop, enableBirthdayAndIdentityCardNumber, enableOthers, othersOptions } = payload
      return {
        ...state,
        enableInfoFrom,
        infoFromOptions,
        enableGradeSelect,
        gradeSelectOptions,
        enableTaxNumber,
        enableVegan,
        enableRentLaptop,
        enableBirthdayAndIdentityCardNumber,
        enableOthers,
        othersOptions
      }
    }

    case cons.SAVE_STEP_FIVE: {
      let { emailPaymentTitle, emailPaymentContent, emailNotCompletedTitle, emailNotCompletedContent, emailNotPaidTitle, emailNotPaidContent, policyUrl, } = payload
      return {
        ...state,
        emailPaymentTitle,
        emailPaymentContent,
        emailNotCompletedTitle,
        emailNotCompletedContent,
        emailNotPaidTitle,
        emailNotPaidContent,
        policyUrl,
      }
    }

    case cons.SAVE_STEP_SIX: {
      let { GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList, } = payload
      return {
        ...state,
        GlobalGACodeList,
        GACodeList,
        GlobalFACodeList,
        FACodeList,
      }
    }

    case cons.GO_PREV_STEP: {
      return {
        ...state,
        step: state.step - 1
      }
    }

    case cons.GO_STEP: {
      return {
        ...state,
        step: payload.step
      }
    }

    case cons.GO_NEXT_STEP: {
      return { ...state, step: (state.step + 1) }
    }

    case cons.SET_LOADING: {
      let { isLoading } = payload
      return { ...state, isLoading }
    }

    case cons.SAVED_DONE: {
      let { savedStatus } = payload
      return { ...state, savedStatus, isLoading: false }
    }

    default:
      return state;
  }
}

export function initRegistrationManage() {
  return { type: cons.REGISTRATION_SETTING_INIT }
}
export function editExistedRegistration(data) {
  return {
    type: cons.EDIT_EXISTED_REGISTRATION,
    payload: data
  }
}

export function saveStepOne(stepOneData) {
  return {
    type: cons.SAVE_STEP_ONE,
    payload: stepOneData
  }
}
export function saveStepTwo(stepTwoData) {
  return (dispatch, getState) => {
    dispatch({
      type: cons.SAVE_STEP_TWO,
      payload: stepTwoData
    })
    dispatch({
      type: cons.CHECK_IF_FORM_CAN_ENABLED
    })
  }
}
export function saveStepThree(stepThreeData) {
  return (dispatch, getState) => {
    dispatch({
      type: cons.SAVE_STEP_THREE,
      payload: stepThreeData
    })
    dispatch({
      type: cons.CHECK_IF_FORM_CAN_ENABLED
    })
  }
}
export function saveStepFour(stepFourData) {
  return (dispatch, getState) => {
    dispatch({
      type: cons.SAVE_STEP_FOUR,
      payload: stepFourData
    })
    dispatch({
      type: cons.CHECK_IF_FORM_CAN_ENABLED
    })
  }
}
export function saveStepFive(stepFiveData) {
  return (dispatch, getState) => {
    dispatch({
      type: cons.SAVE_STEP_FIVE,
      payload: stepFiveData
    })
    dispatch({
      type: cons.CHECK_IF_FORM_CAN_ENABLED
    })
  }
}
export function saveStepSix(stepSixData) {
  return (dispatch, getState) => {
    dispatch({
      type: cons.SAVE_STEP_SIX,
      payload: stepSixData
    })
    dispatch({
      type: cons.CHECK_IF_FORM_CAN_ENABLED
    })
  }
}
export function goNextStep() {
  return {
    type: cons.GO_NEXT_STEP
  }
}
export function goSpecificStep(step) {
  return {
    type: cons.GO_STEP,
    payload: { step }
  }
}

export function saveToDatabase() {
  return (dispatch, getState) => {
    const { registrationManage } = getState();
    const {
      isEdit,
      enabled, formId, payMethodSelected, isGroupRegistration,
      title, subtitle, description, placeClassOptions,
      priceOrigin, /*enableGroupDiscount,*/  priceGroupDiscount, enableOldStudentDiscount, priceOldStudentDiscount,
      enableMultiStudentDiscount, priceMultiStudentDiscount, enableCouponCode, priceCouponCodeDiscount,
      enableInfoFrom, infoFromOptions,
      enableGradeSelect, gradeSelectOptions,
      enableTaxNumber, enableVegan, enableRentLaptop, enableBirthdayAndIdentityCardNumber,
      enableOthers, othersOptions,
      emailPaymentTitle, emailPaymentContent, emailNotCompletedTitle, emailNotCompletedContent,
      emailNotPaidTitle, emailNotPaidContent, policyUrl,
      GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
    } = registrationManage
    const href = `${API_URL.HOST}/registration/course/${formId}`

    if (!enabled) {
      if (!confirm("此表單目前為不開放狀態，請確認此為正確設定？")) return
    }

    if (!Array.isArray(GlobalGACodeList) || GlobalGACodeList.length === 0 ||
      !Array.isArray(GACodeList) || GACodeList.length === 0 ||
      !Array.isArray(GlobalFACodeList) || GlobalFACodeList.length === 0 ||
      !Array.isArray(FACodeList) || FACodeList.length === 0
    ) {
      alert("GA/FB code 沒有內容, 請回去更新!");
      return;
    }

    const data = {
      version: "20200715",
      enabled, formId, href, payMethodSelected, isGroupRegistration,
      title, subtitle, description, placeClassOptions,
      priceOrigin, /*enableGroupDiscount,*/  priceGroupDiscount, enableOldStudentDiscount, priceOldStudentDiscount,
      enableMultiStudentDiscount, priceMultiStudentDiscount, enableCouponCode, priceCouponCodeDiscount,
      enableInfoFrom, infoFromOptions,
      enableGradeSelect, gradeSelectOptions,
      enableTaxNumber, enableVegan, enableRentLaptop, enableBirthdayAndIdentityCardNumber,
      enableOthers, othersOptions,
      emailPaymentTitle, emailPaymentContent, emailNotCompletedTitle, emailNotCompletedContent,
      emailNotPaidTitle, emailNotPaidContent, policyUrl,
      GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
    }
    console.log("registrationManage data", data, "isEdit", isEdit)

    dispatch({
      type: cons.SET_LOADING,
      payload: { isLoading: true }
    })

    if (!isEdit) {
      codingbarApi.getRegistrationService().createRegistrationForm(data).then(result => {
        dispatch({
          type: cons.SAVED_DONE,
          payload: { savedStatus: result.status }
        })
      })
    }
    else {
      codingbarApi.getRegistrationService().updateRegistrationForm(data).then(result => {
        console.log("result", result)
        dispatch({
          type: cons.SAVED_DONE,
          payload: { savedStatus: result.status }
        })
      })
    }
  };
}

