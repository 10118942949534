import { codingbarApi } from 'codingbar-api';
import { getUUID } from 'shared/util/utils';
import isEmpty from 'lodash-es/isEmpty';
import { USERS_COLLECTION, COURSES_COLLECTION, STUDENT_STATUS_COLLECTION, AUTO_JOIN_COURSES_COLLECTION, COURSE_VALIDITY_PERIOD_COLLECTION } from './collections';

// adds all exercises & offlineExercises to openList array when lesson starts
export const autoReleaseLessonExercises = (course) => {
	const { lessons, shouldAutoReleaseCourse } = course;
	
	if (!course || !lessons || lessons.length === 0 || !shouldAutoReleaseCourse) {
		return Promise.resolve(course);
	}
	
	let shouldUpdateCourse = false;
	const currentTime = Date.now();
	
	const updatedLessons = course.lessons.map(lesson => {
		if (lesson.isAlreadyOpenExercises || lesson.beginTime > currentTime) return lesson;
		
		const openList = new Set(lesson.openList || []);
		
		const updatedExercises = lesson.exercises.map(e => {
		if (!e.isShowContent) {
				openList.add(e.id);
				return {...e, isShowContent: true};
			}
			return e;
		})
		
		const updatedOfflineExercises = lesson.offlineExercises.map(e => {
		if (!e.isShowContent) {
				openList.add(e.id);
				return {...e, isShowContent: true};
			}
			return e;
		})
		
		const updatedLesson = {
			...lesson,
			isAlreadyOpenExercises: true,
			exercises: updatedExercises,
			offlineExercises: updatedOfflineExercises,
			openList: Array.from(openList)
		}
		
		shouldUpdateCourse = true;
		return updatedLesson;
	})
	
	if (shouldUpdateCourse) {
		const updatedCourse = {...course, lessons: updatedLessons};
		
		return codingbarApi.getCourseService().updateCourse(updatedCourse)
		.then(() => Promise.resolve(updatedCourse))
	} 
	
	return Promise.resolve(course);
}


export const getCoursesStudentByCourseId = (courseId) => {
	if (!courseId) return Promise.reject('getCoursesStudentByCourseId: incorrect course id');

	const filter = { courseId };
	const projection = {
		id: 1,
		students: 1,
	};

	return codingbarApi.getCoreService().queryOne(COURSES_COLLECTION, filter, {}, projection)
		.then(res => {
			return res.status === 'success' ? res.result.students : [];
		})
		.catch(err => console.error(err));
}

export const getCoursesById = (courseId) => {
	if (!courseId) return Promise.reject('getCoursesById: incorrect course id');

	const filter = {
		id: courseId
	};
	
	const projection = { 
		"_id": 0,
		"_ip": 0,
	};

	return codingbarApi.getCoreService().queryOneWithoutUnitMerge(COURSES_COLLECTION, filter, projection)
	.then(res => {
		return res.result;
	})
}

export const createCourse = (courseObj) => {
	return codingbarApi.getCoreService().createData(COURSES_COLLECTION, courseObj);
}

export const getCourseByIdByProjection = (courseId, projection = {}) => {
	if (!courseId) return Promise.reject('getCourseByIdByProjection: incorrect course id');

	const filter = {
		courseId
	}
	
	return codingbarApi.getCoreService().queryOne(COURSES_COLLECTION, filter, projection)
	.then(res => {
		return res.result;
	})
	.catch(err => console.error(err));
}

export const updateCourseWithoutUnitMerge = (courseObj) => {
	if (!courseObj || !courseObj.id || courseObj.id === '') {
		return Promise.reject('updateCourseWithoutUnitMerge: incorrect id!');
	}

	const filter = {
		id: courseObj.id,
	}
	
	return codingbarApi.getCoreService().updateData(COURSES_COLLECTION, filter, courseObj);
}

export const updateSpecificStudentExpiryTimeNotifiedInCourse = (courseId, studentUid) => {
	if (!courseId) return Promise.reject(new Error('updateSpecificStudentExpiryTimeNotifiedInCourse: courseId is empty'));
	if (!studentUid) return Promise.reject(new Error('updateSpecificStudentExpiryTimeNotifiedInCourse: studentUid is empty'));

	const filter = {
		id: courseId,
		'students.uid': studentUid,
	};

	const data = {
		'students.$.expiryTimeNotified': true,
	};

	return codingbarApi.getCoreService().updateData(COURSES_COLLECTION, filter, data);
};

export const getCourseIdByCid = (cid) => {
	const filter = {
		cid,
	};
	const distinctKey = 'courseId';
	return codingbarApi.getCoreService().queryData(COURSES_COLLECTION, filter, {}, {}, distinctKey);
};

export const patchStudentStatus = (courseId, lessonId, exerciseId, dataObj) => {
	const user = codingbarApi.getAuthService().getUser();
	const filter = {
		courseId: courseId,
		lessonId: lessonId,
		exerciseId: exerciseId,
		unit: user.unit,
		uid: user.uid
	};
	return codingbarApi.getCoreService().patchData(STUDENT_STATUS_COLLECTION, filter, dataObj);
}

export const fetchAllCoursesIncludesStudent = (studentList) => { // no permission deny，建議不要動!!!
	if (!studentList || studentList.length === 0) return Promise.reject('fetchAllCoursesIncludesStudent# error! Incorrect studentList!');
	const filter = {
		"students.uid": { $in: studentList } //將 students 這個 array 中比對 uid，只要符合 studentList 內的至少一個學生帳號就會撈出此課程
	};

	const projection = {
		"_id": 0,
		"id": 1,
		"courseType": 1,
		"students": 1,
		"title": 1,
	}

	return codingbarApi.getCoreService().queryData(COURSES_COLLECTION, filter, {}, projection, '')
	.then(res => {
		return res.status === 'success' ? res.result : [];
	})
	.catch(err => console.error(err));
}

export const fetchAllCoursesIncludesTeacher = (teacherList) => {
	const filter = {
		"teachers.uid": { $in: teacherList}
	};

	return codingbarApi.getCoreService().queryData(COURSES_COLLECTION, filter, {}, undefined)
	.then(res => {
		return res.status === 'success' ? res.result : [];
	})
	.catch(err => console.error(err));
}

export const fetchCoursesByStudentEmail = (email, sortBy = {}, projection = {}) => { // with permission deny, only Staff or Dev use
	return codingbarApi.getCourseService().fetchAllCoursesByStudent(email, sortBy, projection)
		.then(res => {
			return res && res.length > 0 ? res : [];
	})
	.catch(err => console.error(err));
}

// ---- autoJoinCourses ----
// without package sources, globalTest, csfcTest
export const getNormalCoursesByUnitPagination = (unit, sort = {}, skip = 0, limit = 0, searchString = '', projection = {}, courseIds) => {
	if (!unit) return Promise.reject(new Error('getNormalCoursesByUnitPagination: unit is empty'));

	const filter = {
		unit,
		$or: [
			{ courseType: { $nin: ['globalTest', 'csfcTest', 'package'] } },
			{ packageId: { $exists: true } }, // 套裝資源不會有 packageId, 不會撈出套裝資源但會撈出套裝課程
		],
	};

	if (courseIds && courseIds.length > 0) {
		filter.id = { $in: courseIds };
	}

	const searchKeys = ['cid', 'title', 'id'];

	return codingbarApi.getCoreService().queryPaginationWithoutUnitMerge(
		COURSES_COLLECTION,
		filter,
		sort,
		skip,
		limit,
		searchKeys,
		searchString,
		projection,
	);
};

export const queryOneAutoJoinCourseIds = (key) => {
	const filter = {
		key,
	};

	return codingbarApi.getCoreService().queryOne(AUTO_JOIN_COURSES_COLLECTION, filter, {})
		.then((res) => {
			return (res.status === 'success') ? res.result.coursesId : [];
		});
};

export const updateAutoJoinCourseIds = (id, courseIds) => {
	if (!id) return Promise.reject(new Error('updateAutoJoinCourseIds: id is empty'));
	const filter = {
		id,
	};

	const data = {
		coursesId: courseIds,
		updated: Date.now(),
	};

	return codingbarApi.getCoreService().updateData(AUTO_JOIN_COURSES_COLLECTION, filter, data);
};

export const updateAutoJoinCourseIdsByKey = (key, courseIds) => {
	if (!key) return Promise.reject(new Error('updateAutoJoinCourseIdsByKey: key is empty'));
	const filter = {
		key,
	};

	const data = {
		coursesId: courseIds,
		updated: Date.now(),
	};

	return codingbarApi.getCoreService().updateData(AUTO_JOIN_COURSES_COLLECTION, filter, data);
};

export const createAutoJoinCourseIds = (key, courseIds) => {
	if (!key) return Promise.reject(new Error('createAutoJoinCourseIds: key is empty'));
	if (isEmpty(courseIds)) return Promise.reject(new Error('createAutoJoinCourseIds: courseIds is empty'));

	const now = Date.now();
	const data = {
		key,
		id: `${getUUID()}-${now}`,
		coursesId: courseIds,
	};

	return codingbarApi.getCoreService().createData(AUTO_JOIN_COURSES_COLLECTION, data);
};

export const removeAutoJoinCourseIds = (id) => {
	if (!id) return Promise.reject(new Error('removeAutoJoinCourseIds: key is empty'));
	const filter = {
		id,
	};

	return codingbarApi.getCoreService().removeData(AUTO_JOIN_COURSES_COLLECTION, filter);
};

export const queryAllAutoJoinCourseIds = () => {
	const projection = {
		_id: false,
		_ip: false,
		updated: false,
		created: false,
	};

	const filter = {
		key: {
			$ne: 'schoolAuthorizeTrial',
		},
	};

	const sort = {
		created: -1,
	};

	return codingbarApi.getCoreService().queryData(AUTO_JOIN_COURSES_COLLECTION, filter, sort, projection, '')
		.then((res) => {
			return (res.status === 'success') ? res.result : [];
		});
};

export const queryAllAutoJoinCoursesKey = () => {
	const filter = {
		key: {
			$ne: 'schoolAuthorizeTrial',
		},
	};

	const sort = {
		created: -1,
	};

	return codingbarApi.getCoreService().queryData(AUTO_JOIN_COURSES_COLLECTION, filter, sort, {}, 'key')
		.then((res) => {
			return (res.status === 'success') ? res.result : [];
		});
};

// ---- CourseValidityPeriod ----
export const queryAllCourseValidityPeriod = () => {
	const projection = {
		_ip: false,
		_id: false,
		updated: false,
		created: false,
	};

	const filter = {};

	const sort = {
		created: -1,
	};

	return codingbarApi.getCoreService().queryData(COURSE_VALIDITY_PERIOD_COLLECTION, filter, sort, projection, '')
		.then((res) => {
			return (res.status === 'success') ? res.result : [];
		});
};

export const createCourseValidityPeriod = (data) => {
	if (!data.key) return Promise.reject(new Error('createCourseValidityPeriod: key is empty'));
	if (!data.days) return Promise.reject(new Error('createCourseValidityPeriod: days is empty'));
	if (!data.milliseconds) return Promise.reject(new Error('createCourseValidityPeriod: milliseconds is empty'));

	const now = Date.now();
	const dataToCreate = {
		...data,
		id: `${getUUID()}-${now}`,
	};

	return codingbarApi.getCoreService().createData(COURSE_VALIDITY_PERIOD_COLLECTION, dataToCreate);
};

export const removeCourseValidityPeriod = (id) => {
	if (!id) return Promise.reject(new Error('removeCourseValidityPeriod: id is empty'));
	const filter = {
		id,
	};

	return codingbarApi.getCoreService().removeData(COURSE_VALIDITY_PERIOD_COLLECTION, filter);
};

export const removeCourseValidityPeriodByKey = (key) => {
	if (!key) return Promise.reject(new Error('removeCourseValidityPeriodByKey: key is empty'));
	const filter = {
		key,
	};

	return codingbarApi.getCoreService().removeData(COURSE_VALIDITY_PERIOD_COLLECTION, filter);
};

export const updateCourseValidityPeriod = (data) => {
	if (!data.id) return Promise.reject(new Error('updateCourseValidityPeriod: id is empty'));
	if (!data.days) return Promise.reject(new Error('updateCourseValidityPeriod: days is empty'));
	if (!data.milliseconds) return Promise.reject(new Error('updateCourseValidityPeriod: milliseconds is empty'));

	const filter = {
		id: data.id,
	};

	const dataToUpdate = {
		days: data.days,
		milliseconds: data.milliseconds,
		updated: Date.now(),
	};

	return codingbarApi.getCoreService().updateData(COURSE_VALIDITY_PERIOD_COLLECTION, filter, dataToUpdate);
};