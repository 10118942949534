import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';

import * as cons from '../constants/excercise';

export function getInitialState() {
  return fromJS({
    excercise: null,
    getExcercise: false,
    getExcerciseSuc: false,
    getExcerciseErr: false,
  });
}

export default function reducer(state = getInitialState(), action = {}) {
  switch (action.type) {
    case cons.GET_EXCERCISE_SUC: {
      state = state.merge({
        getExcercise: true,
        getExcerciseSuc: false,
        getExcerciseErr: false,
      });

      return state;
    }

    case cons.GET_EXCERCISE_SUC: {
      state = state.merge({
        getExcercise: false,
        getExcerciseSuc: true,
        getExcerciseErr: false,
      });

      return state;
    }

    case cons.GET_EXCERCISE_ERR: {
      state = state.merge({
        getExcercise: false,
        getExcerciseSuc: false,
        getExcerciseErr: true,
      });

      return state;
    }

  }

  return state;
};

export const getExcerciseAction = createAction(cons.SAGA_GET_EXCERCISE);
