import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';

import * as cons from '../constants/course';

export function getInitialState() {
  return fromJS({
    course: null,
    courses: [],
    getCourse: false,
    getCourseSuc: false,
    getCourseErr: false,
  });
}

export default function reducer(state = getInitialState(), action = {}) {
  switch (action.type) {
    case cons.GET_COURSE_CLEAR: {
      state = state.merge({
        course: null,
      });

      return state;
    }

    case cons.GET_COURSE: {
      state = state.merge({
        getCourse: true,
        getCourseSuc: false,
        getCourseErr: false,
      });

      return state;
    }

    case cons.GET_COURSE_SUC: {
      state = state.merge({
        course: action.course,
        getCourse: false,
        getCourseSuc: true,
        getCourseErr: false,
      });

      return state;
    }

    case cons.GET_COURSE_ERR: {
      state = state.merge({
        getCourse: false,
        getCourseSuc: false,
        getCourseErr: true,
      });

      return state;
    }

  }

  return state;
};

export const getCourseAction = createAction(cons.SAGA_GET_COURSE);
