import React from 'react';
import classnames from 'classnames';

import './Button.scss';

export default function(props) {
	const {
		className,
		children,
		primary,
		primaryYellow,
		secondary,
		disabled,
		tertiary,
		alert,
		AImatePrimary,
		AImateWarning,
		AImateBasic,
		AImateCancel,
		fullWidth,
		isDisabled,
		...otherProps
	} = props;
	const css = classnames(
		'coding-bar-button',
		className,
		{ primary },
		{ secondary },
		{ disabled },
		{ tertiary },
		{ 'primary-yellow': primaryYellow },
		{ alert },
		{ AImatePrimary },
		{ AImateWarning },
		{ AImateBasic },
		{ AImateCancel },
		{ fullWidth }
	);

	return (
		<button className={css} {...otherProps} disabled={isDisabled}>
			{children}
		</button>
	);
}
