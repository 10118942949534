import React from 'react';
import classnames from 'classnames';

import './Avatar.scss';

export default function (props) {
  const { className, photoUrl, style, ...otherProps } = props;
  const css = classnames('coding-bar-avatar', className);
  const _style = style || {};

  if (photoUrl) {
    _style.backgroundImage = `url(${photoUrl})`;
  }

  return (
    <div className={css} style={_style} {...otherProps} />
  );
}
