import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { Select } from 'components';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './DatePicker.scss';

function DropDownForDatePicker(props) {
	const { value } = props;

	return (
		<Select
			name="coding-bar-date-picker"
			className="coding-bar-date-picker"
			value={value}
			onOpen={() => {
				props.onClick();
			}}
			options={[{ label: value, value }]}
		/>
	);
}

export default class CustomDatePicker extends React.Component {
	static propTypes = {
		value: PropTypes.string,
		onChange: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.state = {
			startDate: props.value || moment(),
		};
	}

	handleChange = (date) => {
		const { onChange } = this.props;
		this.setState({
			startDate: date,
		});

		if (typeof onChange === 'function') {
			onChange(date);
		}
	};

	render() {
		const { startDate } = this.state;

		return <DatePicker customInput={<DropDownForDatePicker />} selected={startDate} onChange={this.handleChange} />;
	}
}
