export const GET_COURSE = 'course/GET_COURSE';
export const GET_COURSE_CLEAR = 'course/GET_COURSE_CLEAR';
export const GET_COURSE_SUC = 'course/GET_COURSE_SUC';
export const GET_COURSE_ERR = 'course/GET_COURSE_ERR';

export const SAGA_GET_COURSE = 'course/SAGA_GET_COURSE';

export const COURSE_TYPES = [
	'all',
	'python',
	'microbit',
	'blocklymicrobit',
	'meowbit',
	'pygame',
	'pyweb',
	'c',
	'cpp',
	'java',
	'lua',
	'video',
];
export const COURSE_TYPE_LABELS = {
	all: 'ALL',
	python: 'Python',
	microbit: 'Microbit',
	blocklymicrobit: 'BlocklyMicrobit',
	meowbit: 'Meowbit',
	pygame: 'Pygame',
	pyweb: 'PyWeb',
	c: 'C',
	cpp: 'C++',
	java: 'Java',
	lua: 'Lua',
	video: 'Video',
};
export const COURSE_LEVEL_LABELS = { begin: '初級班', medium: '中級班', advanced: '進階班' };
export const getCourseType = (index) => {
	return COURSE_TYPES[index];
};

export const getCourseTypeLabel = (type) => {
	return COURSE_TYPE_LABELS[type];
};

export const getCourseLevelLabel = (type) => {
	return COURSE_LEVEL_LABELS[type];
};

export const COURSE_TYPE_DROPDOWN = [
	{ value: 'python', label: 'Python' },
	{ value: 'microbit', label: 'Microbit' },
	{ value: 'blocklymicrobit', label: 'BlocklyMicrobit' },
	{ value: 'meowbit', label: 'Meowbit' },
	{ value: 'pygame', label: 'Pygame' },
	{ value: 'pyweb', label: 'PyWeb' },
	{ value: 'c', label: 'C' },
	{ value: 'cpp', label: 'C++' },
	{ value: 'java', label: 'Java' },
	{ value: 'lua', label: 'Lua' },
	//   { value: 'video', label: 'Video' },
];

export const CLONE_COURSE_TYPE = {
	TEMPLATE: 'template', // 複製課程 (舊版 wording: 以此當範本建立)
	CLONE: 'clone', // 分享課程及教學資源 (舊版 wording: 複製課程給其他老師)
	ASSIGN_CLONE: 'assignClone', // 指派課程 (舊版 wording: 分享並指派課程)
	TRANSLATION: 'translation', // 指派給翻譯者 (舊版 wording: 指派翻譯課程)
	SCHOOLTRIAL: 'schoolTrial', //校園授權複製免費課程
};

export const COURSE_CATEGORY_DROPDOWN = [
	{ value: 'problemSolving', label: '解題型' },
	{ value: 'nonProblemSolving', label: '非解題型' },
	{ value: 'unset', label: '未指定' },
];

export const MAXIMUM_END_TIME = 9999999999999;
