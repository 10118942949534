import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';

import * as cons from '../constants/search';

export function getInitialState() {
  return fromJS({
    searchTerm: '',
    searchedReslut: [],
    iaLoading: false,
    getSearchResult: false,
    getSearchedResultSuc: false,
    getSearchedResultErr: false
  });
}

export default function reducer(state = getInitialState(), action = {}) {
  switch (action.type) {
    case cons.GET_SEARCHED_RESULT: {
      state = state.merge({
        getSearchResult: true,
        getSearchedResultSuc: false,
        getSearchedResultErr: false,
        searchTerm: action.searchTerm,
        iaLoading: true
      });

      return state;
    }

    case cons.GET_SEARCHED_RESULT_SUC: {
      state = state.merge({
        searchTerm: action.result.searchTerm,
        searchedReslut: action.result.list,
        getSearchResult: false,
        getSearchedResultSuc: true,
        getSearchedResultErr: false,
        iaLoading: false
      });

      return state;
    }

    case cons.GET_SEARCHED_RESULT_ERR: {
      state = state.merge({
        getSearchResult: false,
        getSearchedResultSuc: false,
        getSearchedResultErr: true,
        iaLoading: false
      });

      return state;
    }

    case cons.RESET_SEARCHED_RESULT: {
      state = getInitialState()

      return state;
    }
  }

  return state;
}

export const getSearchedResultAction = createAction(
  cons.SAGA_GET_SEARCHED_RESULT
);

export const resetSearchedResultAction = createAction(
  cons.RESET_SEARCHED_RESULT
);
