import React from 'react';
import PropTypes from 'prop-types';
import { codingbarApi } from 'codingbar-api';
import axios from 'axios';
import { hhmmss } from 'shared/util/time';
import { connect } from 'react-redux';
import cx from 'classnames';

import { Modal, Button, TextArea, Input, Checkbox } from 'components';
import './NoteDialog.scss';
import { translate } from 'react-i18next';
import { getNoteTimeText } from '../../../shared/util/time';
import { getLocalObject, getUUID } from '../../../shared/util/utils';
@translate('translations')
@connect((state) => {
	const app = state.app;
	return {
		user: app.get('user'),
	};
}, {})
export default class NoteDialog extends React.PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);

		const { t } = this.props;

		this.state = {
			title: '',
			content: '',
			loaded: 0,
			course: getLocalObject('currentCourse'),
			lesson: getLocalObject('currentLesson'),
			time: getNoteTimeText(),
			placeHolderForContent: t('notes.placeholder.addNotes'),
		};

		this.onContentChange = this.onContentChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onContentChange(e) {
		const content = e.target.value;
		const { course, lesson } = this.state;

		let title = '';
		if (!course && !lesson && content && content.trim().length > 0) {
			const lines = content.split('\n');
			if (lines.length > 0) {
				title = lines[0];
			}
		}

		this.setState({
			title,
			content: content,
		});
	}

	onSubmit(e) {
		const { title, course, lesson, time, content, placeHolderForContent } = this.state;
		const note = {
			uid: this.props.user.get('uid'),
			content: content,
			course: course,
			lesson: lesson,
			timeText: time,
			created: Date.now(),
			updated: Date.now(),
			title: course && lesson ? `${course ? course.title : ''} - ${lesson ? lesson.title : ''}` : title,
			id: getUUID(),
		};
		console.log(note);

		codingbarApi
			.getNoteService()
			.createNote(note)
			.then((result) => {
				console.log(result);
				this.props.closeModal();
			});
	}

	handleClick = (e) => {
		e.stopPropagation();
	};

	render() {
		const { isShow, closeModal, t, rootClassName } = this.props;
		const { title, course, lesson, time, content, placeHolderForContent } = this.state;
		const cn = cx('note-dialog-main', rootClassName);

		return (
			<Modal className={cn} isOpen={isShow} onClose={this.props.closeModal} isShowClose={false}>
				<div className="note-dialog-main-body" onClick={(e) => this.handleClick(e)}>
					<div className="note-dialog-title">{title ? title : t('notes.newNotes')}</div>

					<div className="note-dialog-content-main">
						<div className="note-dialog-content-body">
							<div className="note-dialog-content-comment">
								<div className="note-dialog-content-title">
									{course && lesson ? `${course ? course.title : ''} - ${lesson ? lesson.title : ''} | ` : ''} {time}
								</div>
								<TextArea
									className="note-dialog-add-comment-input"
									value={content}
									placeholder={placeHolderForContent}
									onChange={this.onContentChange}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="note-dialog-bottom" onClick={(e) => this.handleClick(e)}>
					<Button className="note-dialog-add-btn" primary onClick={(e) => this.onSubmit(e)}>
						{t('notes.saveNotes')}
					</Button>
					<Button className="note-dialog-cancel-btn" onClick={closeModal}>
						{t('common.cancel')}
					</Button>
				</div>
			</Modal>
		);
	}
}
