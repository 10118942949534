import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './LinkWrapper.scss';

const LinkWrapper = (props) => {
	const { children, isLink, linkTo = '', className = '', ...others } = props;
	if (isLink) {
		return (
			<Link to={linkTo} className={cx('codingBar-link', className)} {...others}>
				{children}
			</Link>
		);
	}
	return (
		<div className={cx('codingBar-link', className)} {...others}>
			{children}
		</div>
	);
};

LinkWrapper.propTypes = {
	children: PropTypes.any,
	isLink: PropTypes.bool,
	linkTo: PropTypes.string,
	className: PropTypes.string,
};
export default LinkWrapper;
