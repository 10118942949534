import React from 'react';
import PropTypes from 'prop-types';
import * as md from 'react-icons/lib/md';

const Icon = props => {
  const { name, color, size, ...rest } = props;
  const MdIcon = md[name];

  return (
    <MdIcon
      className="Icon"
      color={color}
      size={size}
      {...rest}
    ></MdIcon>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number
};

Icon.defaultProps = {
  color: 'black',
  size: 24
};

export default Icon;
