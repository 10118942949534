export const REGISTRATION_SETTING_INIT = 'REGMANNEW_REGISTRATION_SETTING_INIT';
export const SET_ALL_CLASSES_FROM_DB = 'REGMANNEW_SET_ALL_CLASSES_FROM_DB';
export const SET_ALL_GOODS_FROM_DB = 'REGMANNEW_SET_ALL_GOODS_FROM_DB';
export const SET_ALL_INFOS_FROM_DB = 'REGMANNEW_SET_ALL_INFOS_FROM_DB';
export const EDIT_EXISTED_REGISTRATION = 'REGMANNEW_EDIT_EXISTED_REGISTRATION';
export const SET_LOADING = 'REGMANNEW_SET_LOADING';
export const SAVED_DONE = 'REGMANNEW_SAVED_DONE';

export const SET_REDUCER = 'REGMANNEW_SET_REDUCER';
export const SAVE_STEP_BASIS = 'REGMANNEW_SAVE_STEP_BASIS';
export const SAVE_STEP_PLACECLASS = 'REGMANNEW_SAVE_STEP_PLACECLASS';
export const SAVE_STEP_OTHERS = 'REGMANNEW_SAVE_STEP_OTHERS';
export const SAVE_STEP_RESPONSE = 'REGMANNEW_SAVE_STEP_RESPONSE';
export const SAVE_STEP_ANALYSIS = 'REGMANNEW_SAVE_STEP_ANALYSIS';
export const CHECK_IF_FORM_CAN_ENABLED = 'REGMANNEW_CHECK_IF_FORM_CAN_ENABLED';

export const GO_PREV_STEP = 'REGMANNEW_GO_PREV_STEP';
export const GO_NEXT_STEP = 'REGMANNEW_GO_NEXT_STEP';
export const GO_STEP = 'REGMANNEW_GO_STEP';
// export const SAVE_STEP_FOUR_STUDENT = 'SAVE_STEP_FOUR_STUDENT'
// export const SAVE_STEP_FOUR = 'SAVE_STEP_FOUR'

export const FORM_ATTRIBUTE_SINGLE = 'single';
export const FORM_ATTRIBUTE_SINGLE_PAY_GROUP = 'singlePayGroup'; // 一人支付團報

export const FORM_ATTRIBUTE_CBA = 'cba';
export const FORM_ATTRIBUTE_CBA_GROUP = 'cbaGroup';
export const CBA_OPTION_LIST = [FORM_ATTRIBUTE_CBA, FORM_ATTRIBUTE_CBA_GROUP];

export const CBA_DEFAULT_CONTENT = {
	[FORM_ATTRIBUTE_CBA_GROUP]: {
		subtitle: '兩人團報，享優惠',
		description: `【注意】
    此為團報方案，若需退費不接受單獨退費，需整團辦理`,
	},
	[FORM_ATTRIBUTE_CBA]: {
		subtitle: '單人方案',
		description: `【注意】
    若您需要統編，請確實填寫發票資訊欄位以利後續電子發票流程`,
	},
};
