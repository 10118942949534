import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import iconPlus from 'design/images/icons/icon-plus-white.svg';
import './FabAddWithTip.scss';

const tooltip = input => {
  return (
    <Tooltip id="tooltip">{input}</Tooltip>
  );
}

const handleClick = (e, callback) => {
  if (callback) {
    callback(e);
  }
}

const FabAddWithTip = props => {
  const { tipContent, onClick: callback, ...rest } = props;

  return (
    <OverlayTrigger placement="top" overlay={tooltip(tipContent)}>
      <button
        className="fab btn-circle" onClick={(e) => handleClick(e, callback)}>
        <span aria-hidden="true"><img src={iconPlus} /></span>
      </button>
    </OverlayTrigger>
  );
};

export default FabAddWithTip;
