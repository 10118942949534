import PropTypes from 'prop-types';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import cx from 'classnames';
import './Switch.scss';

function CustomizedSwitch(props) {
	const { className = '', containerClassName = '', label = '', isViewOnly, ...otherProps } = props;

	return (
		<div className={cx('coding-bar-switch-container', containerClassName)}>
			{label ? <span className="coding-bar-switch-label">{label}</span> : null}
			<Switch className={cx('coding-bar-switch', { 'is-view-only': isViewOnly }, className)} {...otherProps} />
		</div>
	);
}

CustomizedSwitch.propTypes = {
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	isViewOnly: PropTypes.bool,
	label: PropTypes.string,
};

export default CustomizedSwitch;
