import { UPDATE_SIDEBAR_STATUS, SIDEBAR_STATUS, TOGGLE_SIDEBAR } from '../constants/exerciseSidebar';

export function getInitialState() {
  return {
    sidebarStatus: SIDEBAR_STATUS.COMPLETE,
    sidebarIsClosed: false,
  }
}

export default function reducer(state = getInitialState(), action = {}) {
  switch (action.type) {
    case UPDATE_SIDEBAR_STATUS: {
      const sidebarStatus = action.payload
      if (Object.values(SIDEBAR_STATUS).indexOf(sidebarStatus) < 0) return state
      else return {
        ...state,
        sidebarStatus: sidebarStatus
      };
    }
    case TOGGLE_SIDEBAR: 
      return {
        ...state,
        sidebarIsClosed: !state.sidebarIsClosed,
      }
    default:
      return state;
  }
};


export function updateSidebarStatus(newStatus) {
  // newStatus: type of cons.SIDEBAR_STATUS
  return {
    type: UPDATE_SIDEBAR_STATUS,
    payload: newStatus
  }
}

export function triggerUpdateSidebar() {
  return updateSidebarStatus(SIDEBAR_STATUS.SHOULD_REFRESH)
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  }
}