import { codingbarApi } from 'codingbar-api';
import isEmpty from 'lodash/isEmpty';
import { getUUID } from "shared/util/utils";
import { CSFC_USERS_COLLECTION, COURSES_COLLECTION, CSFC_TEST_EVENTS_COLLECTION } from './collections';

export const getCSFCUser = (uid) => {
	if (!uid) return Promise.reject("getCSFCUser: wrong uid");

	const filter = {
		uid,
	}

	const projection = {
		'_id': 0,
		id: 1,
		uid: 1,
		email: 1,
		displayName: 1,
		testEvent: 1,
		studentAccount: 1,
	}

	return codingbarApi.getCoreService().queryOne(CSFC_USERS_COLLECTION, filter, projection)
		.then(res => {
		return res.status === 'success' ? res.result : undefined;
	})
	.catch(err => console.error(err));
}

export const getCSFCUserByEventId = (courseId, eventId) => {
	if (!courseId) return Promise.reject(new Error('getCSFCUser: wrong courseId'));
	if (!eventId) return Promise.reject(new Error('getCSFCUser: wrong eventId'));

	const filter = {
		'testEvent.courseId': courseId,
		'testEvent.eventId': eventId,
	};

	const projection = {
	};

	return codingbarApi.getCoreService().queryData(CSFC_USERS_COLLECTION, filter, {}, projection)
		.then((res) => {
			return res.status === 'success' ? res.result : undefined;
		});
};

export const createCSFCSentInfo = (infoObj) => {
	if (!infoObj) return Promise.reject("createCSFCSentInfo: wrong infoObj");

	infoObj.id = `${getUUID()}-${Date.now()}`

	return codingbarApi.getCoreService().createData(CSFC_USERS_COLLECTION, infoObj)
		.then(res => {
		return res.status === 'success' ? res.result : undefined;
	})
	.catch(err => console.error(err));

}

export const updateCSFCSentInfo = (infoObj) => {
	if (!infoObj) return Promise.reject("updateCSFCSentInfo: wrong infoObj");
	const now = Date.now();

	const filter = {
		uid: infoObj.uid,
	}

	const updateObj = {
		testEvent: infoObj.testEvent,
		updated: now,
	};

	return codingbarApi.getCoreService().updateData(CSFC_USERS_COLLECTION, filter, updateObj)
		.then(res => {
		return res.status === 'success' ? res.result : undefined;
	})
	.catch(err => console.error(err));
}

export const fetchCSFCRegistedDataByToken = (token) => {
	if (!token) return Promise.reject("fetchCSFCRegistedDataByToken: wrong token");

	const filter = {
		'testEvent.token': token,
	}

	const projection = {
		'_id': 0,
		id: 1,
		displayName: 1,
		uid: 1,
		testEvent: 1,
	}

	return codingbarApi.getCoreService().queryOne(CSFC_USERS_COLLECTION, filter, projection)
		.then(res => {
		return res.status === 'success' ? res.result : undefined;
	})
	.catch(err => console.error(err));
}

export const getCSFCUserUidCache = () => {
	return localStorage.getItem('csfcUserUid');
};

export const setCSFCUserUidCache = (uid) => {
	return localStorage.setItem('csfcUserUid', uid);
};

export const getCSFCCourses = () => {
	const filter = {
		courseType: 'csfcTest',
	};
	const sort = { beginTime: 1 };

	const projection = {
	};

	return codingbarApi.getCoreService().queryData(COURSES_COLLECTION, filter, sort, projection)
		.then((res) => {
			if (res.status !== 'success') {
				return Promise.reject(new Error('not exist'));
			}
			return res.result;
		});
};

export const getCSFCCourseByIds = (courseIds) => {
	const filter = {
		courseType: 'csfcTest',
		courseId: { $in: courseIds },
	};
	const sort = { beginTime: 1 };

	const projection = {
	};

	return codingbarApi.getCoreService().queryData(COURSES_COLLECTION, filter, sort, projection)
		.then((res) => {
			if (res.status !== 'success') {
				return Promise.reject(new Error('not exist'));
			}
			return res.result;
		});
};

export const getCSFCCourseByIdByProjection = (courseId, projection = {}) => {
	if (!courseId) return Promise.reject(new Error('getCSFCCourseByIdByProjection: courseId is empty'));

	const filter = {
		courseId,
		courseType: 'csfcTest',
	};

	return codingbarApi.getCoreService().queryOne(COURSES_COLLECTION, filter, projection)
		.then((res) => {
			return res.status === 'success' ? res.result : undefined;
		});
};


export const createCsfcTestEvent = (testEvent) => {
	if (isEmpty(testEvent)) return Promise.reject(new Error('createCsfcTestEvent: testEvent isEmpty'));


	return codingbarApi.getCoreService().createData(CSFC_TEST_EVENTS_COLLECTION, testEvent)
		.then((res) => {
			console.log('SSS>>>>res:', res);
			return res.status === 'success' ? res.result : undefined;
		});
};

export const updateCsfcTestEvent = (eventId, testEvent) => {
	if (isEmpty(eventId) || isEmpty(testEvent)) return Promise.reject(new Error('updateCsfcTestEvent: eventId or testEvent isEmpty'));

	const filter = {
		id: eventId,
	};

	return codingbarApi.getCoreService().updateData(CSFC_TEST_EVENTS_COLLECTION, filter, testEvent)
		.then((res) => {
			return res.status === 'success' ? res.result : undefined;
		});
};


export const getCsfcTestEventsByPagination = (filter, sort, skip, limit, searchString = '', projection = {}) => {
	const searchKeys = ['eventSubject', 'eventLevel'];

	return codingbarApi.getCoreService().queryPagination(CSFC_TEST_EVENTS_COLLECTION, filter, sort, skip, limit, searchKeys, searchString, projection);
};

export const getCsfcTestEventsByIds = (courseIds = [], eventIds = [], projection = {}) => {
	if (isEmpty(courseIds)) return Promise.reject(new Error('getCsfcTestEventsByIds: eventId isEmpty'));
	const sort = { beginTime: 1 };
	const filter = {
		courseId: { $in: courseIds },
	};

	if (!isEmpty(eventIds)) {
		filter.id = { $in: eventIds };
	}

	return codingbarApi.getCoreService().queryData(CSFC_TEST_EVENTS_COLLECTION, filter, sort, projection, '').then((res) => {
		if (res.status !== 'success') {
			return Promise.reject(new Error('not exist'));
		}
		return res.result;
	});
};

export const getCsfcTestEventsByCourseIdAndTestIds = (courseId, testId, projection = {}) => {
	if (isEmpty(courseId)) return Promise.reject(new Error('getCsfcTestEventsByCourseIdAndTestIds: courseId isEmpty'));
	if (isEmpty(testId)) return Promise.reject(new Error('getCsfcTestEventsByCourseIdAndTestIds: testId isEmpty'));
	const filter = {
		courseId,
		$or: [
			{ conceptTestId: testId },
			{ implementationTestId: testId },
		],
	};


	return codingbarApi.getCoreService().queryOne(CSFC_TEST_EVENTS_COLLECTION, filter, {}, projection, '').then((res) => {
		if (res.status !== 'success') {
			return Promise.reject(new Error('not exist'));
		}
		return res.result;
	});
};

export const deleteCsfcTestEventById = (id) => {
	const filter = {
		id,
	};

	return codingbarApi.getCoreService().removeData(CSFC_TEST_EVENTS_COLLECTION, filter).then((res) => {
		if (res.status !== 'success') {
			return Promise.reject(new Error('not exist'));
		}
		return res.result;
	});
};

export const getExistCsfcUserByCourseId = (courseId) => {
	if (!courseId) return Promise.reject(new Error('getExistCsfcUserByCourseId: courseId isEmpty'));

	const filter = {
		'testEvent.courseId': courseId,
	};

	const projection = {
		displayName: 1,
		email: 1,
	};

	return codingbarApi.getCoreService().queryOne(CSFC_USERS_COLLECTION, filter, projection)
		.then((res) => {
			return res.status === 'success' ? res.result : undefined;
		});
};

export const getExistCsfcUserByEventId = (eventId) => {
	if (!eventId) return Promise.reject(new Error('getExistCsfcUserByEventId: eventId isEmpty'));

	const filter = {
		'testEvent.eventId': eventId,
	};

	const projection = {
		displayName: 1,
		email: 1,
	};

	return codingbarApi.getCoreService().queryOne(CSFC_USERS_COLLECTION, filter, projection)
		.then((res) => {
			return res.status === 'success' ? res.result : undefined;
		});
};
