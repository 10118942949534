import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';

import arrowDown from 'design/images/icon-navi-arrow-down.svg';
import './ExpandableContainer.scss';


class ExpandableContainer extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    content: PropTypes.element,
    isOpen: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    if (this.props.isOpen) {
      this.state = {
        isOpen: true,
      };
    }

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    if (this.props.setCurrentLesson && this.props.lessonId) {
      this.props.setCurrentLesson(this.props.lessonId)
    }
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { isOpen } = this.state;
    const { title, content } = this.props;
    const rotateClassName = classNames({
      'rotate-0-deg': isOpen,
      'rotate-180-deg': !isOpen,
    });

    return (
      <div className='expandable-container'>
        <div className='title-bar'>
          <span>{title}</span>
          <img src={arrowDown} className={rotateClassName}
            onClick={this.toggleCollapse} />
        </div>
        {content === undefined ? null :
          <Collapse in={isOpen}>
            <div>{content}</div>
          </Collapse>}
      </div>
    );
  }
}

export default ExpandableContainer;
