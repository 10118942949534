import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { Button as MuiBtn, makeStyles } from '@material-ui/core'

const COLOR_LIST = ['line', 'submit', 'alert', 'white', 'disabled', 'blue', 'cancel', 'yellow', 'orange', 'aiMate' ]
const MUI_COLOR_LIST = ["default", "inherit", "primary", "secondary"]

const useStyles = makeStyles(theme => ({
  line: {
    borderRadius: '8px',
    backgroundColor: '#00b900!important',
    color: 'white!important',
    // '&:hover': {
    //   backgroundColor: 'rgb(0, 166, 0)'
    // }
  },
  submit: {
    borderRadius: '8px',
    backgroundColor: '#fed602',
    '&:hover': {
      backgroundColor: "rgb(228, 192, 1)"
    }
  },
  alert: {
    borderRadius: '8px',
    backgroundColor: '#d9534f',
    color: 'white',
    '&:hover': {
      backgroundColor: "#c9302c"
    }

  },
  disabled: {
    borderRadius: '8px',
    backgroundColor: '#6F8190',
    color: '#B8C3D9!important',
    '&:hover': {
      backgroundColor: "rgb(228, 192, 1)"
      // background-color: #cba901;
    }
  },
  blue: {
    borderRadius: '8px',
    border: "1px solid transparent",
    color: "white",
    backgroundColor: '#2F80ED',
    '&:hover': {
      backgroundColor: "#286dc9!important"
    }
  },
  orange: {
    borderRadius: '8px',
    border: "1px solid transparent",
    color: "#191976",
    backgroundColor: '#ffc125',
    '&:hover': {
      backgroundColor: "rgb(255, 193, 37, 0.8)!important"
    }
  },
  cancel: {
    borderRadius: '8px',
    border: '1px solid #29354C',
    '&:hover': {
      backgroundColor: "#F4F7F9!important"
    }
  },
  white: {
    borderRadius: '8px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: "rgb(229, 229, 229)"
    }
    // border: 1px solid #d4d6d9;
    // box-shadow: none !important;
    // &:hover {
    //     background-color: #cccccc;
    // }
  },
  aiMate: {
    borderRadius: '8px',
    background: 'linear-gradient(67deg, #5BC59F -15.4%, #7C68FF 119.79%)',
    backgroundColor: '#B8C3D9',
    
    '&:disabled': {
      background: '#B8C3D9',
      backgroundColor: '#B8C3D9',
    }
  }
}))

export const StyledButton = ({ color, className, ...props }) => {
  const classes = useStyles()
  const cn = cx(className, { [classes[color]]: COLOR_LIST.includes(color) })
  return (
    <MuiBtn
      className={cn}
      color={COLOR_LIST.includes(color) ? 'default' : color}
      {...props}
    />
  )
}

const Button = ({ onClick, to, isExternal, ...props }) => {
  if (to) {
    return (
      <StyledButton
        component={(isExternal ? 'a' : Link)}
        href={isExternal && to}
        to={isExternal ? undefined : to}
        {...props}
      />
    )
  } else {
    return (
      <StyledButton
        onClick={onClick}
        {...props}
      />
    )
  }
}
Button.propTypes = {
  onClick: (props, propName, componentName) => {
    if (props.to && props.onClick) {
      return new Error('Choose at most one of `to` and `onClick`')
    }
  },
  isExternal: (props, propName, componentName) => {
    if (!props.to && props.isExternal) {
      return new Error('`isExternal` only work in Link')
    }
  },
  color: PropTypes.oneOf(MUI_COLOR_LIST.concat(COLOR_LIST))
}

export default Button
