import React from "react";
import cx from 'classnames'
import Draggable from 'react-draggable';
import "./ZoomStudent.scss";
import { translate } from 'react-i18next';
import Icon from "../../components/core/Icon";
import { connect } from "react-redux";
import { compose } from 'recompose';
import LinearLayout from "../../components/common/LinearLayout";
import {Button} from "react-bootstrap";

const mapStateToProps = state => {
  const user = state.app.get('user')
  return {
    user: user.toJS()
  };
}
@translate('translations')
class ZoomStudent extends React.PureComponent {

  constructor(props) {
    super(props);

    this.isFullScreen = false;
    this.state = {
      isHideForFullScreen: [],
      startZoom: false,
      selectedVideoConference: undefined,
    };

    this.iframeLoadCount = 0;
  }

  componentDidMount() {
    this.syncCourses(this.props.videoConferenceInfos);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {videoConferenceInfos} = nextProps;
    this.syncCourses(videoConferenceInfos);
  }

  getCourse(info) {
    return codingbarApi.getCourseService().getCourse(info.courseId).then(course => {
      info["course"] = course;
      return Promise.resolve(info);
    }).catch(err => {
      return Promise.reject(err);
    })
  }


  syncCourses(videoConferenceInfos) {
    if (!videoConferenceInfos) {
      return;
    }

    const promises = [];
    videoConferenceInfos.forEach(info => {
      promises.push(this.getCourse(info));
    })

    Promise.all(promises).then(infos => {
      console.log("syncCourses", infos);
      this.setState({
        videoConferenceInfos: infos,
        selectedVideoConference: infos && infos.length === 1? infos[0]: undefined,
      })
    })
  }

  toggleFullScreen = () => {
    this.isFullScreen = !this.isFullScreen;
    this.setState({
      isHideForFullScreen: true
    });

    const host = this;
    setTimeout(function (){
      host.setState({
        isHideForFullScreen: false
      })}, 50);
  }

  onIframeLoad() {
    this.iframeLoadCount++;
    if (this.iframeLoadCount > 1) {
      if (this.props.setVisible) {
        this.props.setVisible(false);
      }
    }
  }

  closeWindow() {
    if (this.props.setVisible) {
      this.props.setVisible(false);
    }
  }

  onClickVideoConference(info) {
    this.iframeLoadCount = 0;
    this.setState({
      selectedVideoConference: info
    })
  }

  renderVideoConferenceInfos () {
    const {videoConferenceInfos} = this.state;
    const { t } = this.props;
    console.log("renderVideoConferenceInfos#infos", videoConferenceInfos);
    if (!videoConferenceInfos || videoConferenceInfos.length === 0) {
      return <div>{t('common.pleaseWait')}</div>
    }
    return videoConferenceInfos.map((info, idx) => {
      return <LinearLayout orientation={"vertical"} align={"center"}>
        <LinearLayout orientation={"horizontal"} align={"left"} style={{background:"#ffffff", marginTop: "10px", width: "100%"}}>
          <div>
            <div>
              {idx + 1}. {info.course.title}
            </div>
            <div style={{marginTop: "5px", marginLeft: "10px"}}>
              {info.course.id}
            </div>
          </div>

          <div style={{marginLeft: "10px"}}>
            <Button key={`info_${idx}`} style={{height: "30px"}} onClick={this.onClickVideoConference.bind(this, info)}>{('component.exerciseManage.select')}</Button>
          </div>
        </LinearLayout>
      </LinearLayout>
    })
  }

  backVideoConferenceList() {
    this.setState({
      selectedVideoConference: undefined,
    })
  }

  render() {
    const { t } = this.props;

    const displayerClassName = cx('ZoomStudent', { "zoom-student-full-screen-displayer": this.isFullScreen})
    const defaultStyle = this.isFullScreen ? { width: `100%`, height: `850px`, border: 'none' } : { width: `500px`, height: `550px`, border: 'none' };
    const {user} = this.props;
    const {selectedVideoConference, videoConferenceInfos} = this.state;

    const meetingNumber = selectedVideoConference ? selectedVideoConference.meetingNumber.split(" ").join(""):"";
    const meetingPassword = selectedVideoConference ? selectedVideoConference.meetingPassword:"";
    const courseId = selectedVideoConference ? selectedVideoConference.courseId:"";
    const courseTitle = selectedVideoConference ? selectedVideoConference.course.title:"";
    const host = "https://meeting.codingbar.ai";
    const meetingUrl = `${host}?courseId=${courseId}&courseTitle=${courseTitle}&id=${meetingNumber}&password=${meetingPassword}&name=${user.displayName}&email=${user.email}`;
    console.log("meetingUrl", meetingUrl);

    const showCourseList = selectedVideoConference && videoConferenceInfos.length > 1;

    return (
      <Draggable cancel={"input,.monaco-editor,.form-control"}>
        <div className={displayerClassName}>

          <div>
            <LinearLayout orientation={"horizontal"} align={"right"} style={{background:"#4f52f5", width: "100%"}}>

              {showCourseList && <LinearLayout orientation={"horizontal"} align={"left"} style={{background:"#4f52f5", marginLeft: "5px", marginTop: "5px", width: "100px", color:"#ffffff"}}>
                <div onClick={this.backVideoConferenceList.bind(this)}>
                  {`< ${t('zoom.list')}`}
                </div>
              </LinearLayout>}

              <LinearLayout orientation={"horizontal"} align={"center"} style={{background:"#4f52f5", marginTop: "5px", width: "100%", color:"#ffffff"}}>
                <div>
                  {t('zoom.zoomCourse')}
                </div>
              </LinearLayout>

              <div style={{width: "50px"}} onClick={this.toggleFullScreen.bind(this)}>
                <Icon name="fullscreen" color="white" />
              </div>
              <div style={{width: "50px"}} onClick={this.closeWindow.bind(this)}>
                <Icon name="close" color="white" />
              </div>
            </LinearLayout>

            {!selectedVideoConference && <div style={{marginTop: "20px", marginLeft: "20px"}}>
              {videoConferenceInfos && videoConferenceInfos.length > 0 && <div style={{fontSize: "14pt"}}>
                {t('zoom.selectCourse')}
              </div>}
              <div style={{marginTop: "10px"}}>
                {this.renderVideoConferenceInfos()}
              </div>
            </div>}

            {selectedVideoConference && <div>
              <iframe
                id={"ZoomIframe"}
                onLoad={this.onIframeLoad.bind(this)}
                style={defaultStyle}
                src={meetingUrl}
                allow="camera; microphone"
              />
            </div>}

          </div>
        </div>
      </Draggable>
    )
  }
}

export default compose(
  connect(mapStateToProps),
)(ZoomStudent);

