import React, { Fragment } from 'react';
import { initAction } from 'redux/reducers/app';
import './ErrorHandler.scss';
import { Modal } from 'components';
import { Button } from "react-bootstrap"
import { codingbarApi } from 'codingbar-api';
import LinearLayout from "../../components/common/LinearLayout";
import noSessionImg from "../../../design/images/TeacherPage/teacher-course-no-course.svg";

export default class ErrorHandler extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			text: ""
		}
	}

	close = () => {
		if (this.props.close) {
			this.props.close();
		}
	}

	setText = (event) => {
		this.setState({text: event.target.value})
	}

	send = () => {
		const { bug={} } = this.props;
		if (bug) {
			codingbarApi.sendBugReport("ReactError", bug.error, {...bug.info, description: this.state.text});
		}

		if (this.props.close) {
			this.props.close();
		}
	}

	render() {
		return (
			<div style={{ fontSize: "14pt" }} className="error-handler-window">
				<LinearLayout align={"spacearound"}>
					<div style={{ color: "red" }}><h2>Ooops!</h2></div>
					<div>
						<img src={noSessionImg} />
					</div>
				</LinearLayout>
				<div style={{ marginTop: "20px" }}>這個頁面目前發生錯誤，無法正常顯示！<br />{window.location.href} </div>

				<LinearLayout style={{ marginTop: "30px" }} align={"spacearound"}>
					<span>請簡單敘述案發經過，謝謝！</span>
				</LinearLayout>
				<LinearLayout align={"spacearound"}>
					<textarea style={{ width: "100%", height: '100px', resize: 'none' }} placeholder="請簡單敘述案發經過，謝謝！" value={this.state.text} onChange={this.setText}/>
				</LinearLayout>
				<LinearLayout style={{ marginTop: "10px" }} align={"spacearound"}>
					<Button
						bsStyle={"default"}
						style={{ width: "150px", borderColor: "#2F80ED", color: "#2F80ED" }}
						onClick={this.close}
					>關閉</Button>
					<Button style={{ width: "150px" }} bsStyle={"danger"} onClick={this.send}>送出回報</Button>
				</LinearLayout>
			</div>
		);
	}
}
