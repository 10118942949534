import { fork, cancel, take, call, put, select, spawn, all } from 'redux-saga/effects';

import appSagas from './app';
import courseSagas from './course';
// import excerciseSagas from './excercise';
import searchSagas from './search'
import notificationSagas from './notification'

const sagas = [
  ...appSagas,
  ...courseSagas,
  // ...excerciseSagas,
  ...searchSagas,
  ...notificationSagas,
];

const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga) {
  return function* main() {
    const sagaTask = yield fork(saga);
    yield take(CANCEL_SAGAS_HMR);
    yield cancel(sagaTask);
  };
}

/*
  Global error handler for saga
  from: https://github.com/redux-saga/redux-saga/pull/644
*/
function* startAllSagas() {
  let _sagas = sagas;

  if (__DEVELOPMENT__) {
    _sagas = _sagas.map(createAbortableSaga);
  }

  yield all(_sagas.map(saga =>
    spawn(function* () {
      let isSyncError = false;
      while (!isSyncError) {
        isSyncError = true;
        try {
          setTimeout(() => isSyncError = false);

          yield call(saga);

          break;
        } catch (error) {
          console.error(error);

          if (isSyncError) {
            throw new Error(`${saga.name} was terminated because it threw an exception on startup.`);
          }
        }
      }
    }),
  ));
}

/*
目前 redux-saga 官方尚未支援 Hot reload，必須要在專案中做些修改才行
Webpack React/Redux Hot Module Reloading (HMR) example
*/
export default {
  startSagas(sagaMiddleware) {
    sagaMiddleware.run(startAllSagas);
  },
  cancelSagas(store) {
    store.dispatch({ type: CANCEL_SAGAS_HMR });
  },
};
