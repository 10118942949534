import React from "react";
import cx from 'classnames'
import Draggable from 'react-draggable';
import "./ZoomTeacher.scss";
import { translate } from 'react-i18next';
import {Button} from "react-bootstrap";
import { codingbarApi } from "codingbar-api";


@translate('translations')
class ZoomTeacher extends React.PureComponent {

  constructor(props) {
    super(props);

    this.isFullScreen = false;
    this.state = {
      isHideForFullScreen: false,
      courseId: "", //localStorage.getItem("currentCourseId") || "",
      id : "", //localStorage.getItem("zoomId") || "",
      password : "", //localStorage.getItem("zoomPassword") || "",
      isActived: false, //localStorage.getItem("zoomStarted") === "true" ? true : false
      courseTitle: "",
    };
  }

  componentDidMount() {
    this.syncInfo();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.syncInfo();
  };

  getTitle(courseId) {
    codingbarApi.getCourseService().getCourse(courseId).then(course => {
      console.log("ZoomTeacher#   getTitle", course);
      if (course) {
        this.setState({
          courseTitle: course.title
        })
      }
    })
  }

  syncInfo  = () => {
    codingbarApi.getCourseService().getVideoConferenceInfos().then(result => {
      console.log("ZoomTeacher#   getVideoConferenceInfos", result);
      const currentCourseId = localStorage.getItem("currentCourseId") || "";
      console.log("ZoomTeacher#   currentCourseId", currentCourseId);
      const activeInfos = result && result.length > 0 ? result.filter(i => i.isActived):[];

      if (activeInfos.length > 0) {
        const info = result.filter(i => i.isActived)[0];
        localStorage.setItem("videoConferenceIsActived", info ? `${info.isActived}`:"false");
        const courseId = info.courseId || currentCourseId;
        this.setState({
          courseId,
          id: info.meetingNumber,
          password: info.meetingPassword,
          isActived: info.isActived,
          courseTitle: ""
        })
        this.getTitle(courseId);
      } else {
        const infos = result && result.length > 0 ? result.filter(i => i.courseId === currentCourseId):[];

        if (infos && infos.length > 0) {
          const info = infos[0];
          localStorage.setItem("videoConferenceIsActived", info ? `${info.isActived}`:"false");
          const courseId = info.courseId || currentCourseId || "";
          this.setState({
            courseId,
            id: info.meetingNumber,
            password: info.meetingPassword,
            isActived: info.isActived,
            courseTitle: ""
          })
          this.getTitle(courseId);
        } else {
          this.setState({
            courseId: currentCourseId,
            courseTitle: "",
            isActived: false
          })
          this.getTitle(currentCourseId);
        }
        localStorage.setItem("videoConferenceIsActived", "false");
      }
    });
  };

  toggleFullScreen = () => {

    this.isFullScreen = !this.isFullScreen;
    this.setState({
      isHideForFullScreen: true
    });

    const host = this;
    setTimeout(function (){
      host.setState({
        isHideForFullScreen: false
      })}, 50);
  }

  onInputChanged(type, e) {
    switch (type) {
      case "courseId":
        this.setState({
          courseId: e.target.value
        });
        break;
      case "id":
        this.setState({
          id: e.target.value
        })
        break;
      case "password":
        this.setState({
          password: e.target.value
        })
        break;
    }
  }

  onStartClick() {
    const { t } = this.props;

    const {courseId, id, password, isActived} = this.state;

    if ( !courseId || !id || !password) {
      alert(t('zoom.selectCodeAndPW'));
      return;
    }

    if (!isActived) {
      codingbarApi.getCourseService().setupVideoConference(courseId, id, password).then(result => {
        console.log("setupVideoConference", result)
        this.syncInfo();
      });
    } else {
      codingbarApi.getCourseService().endVideoConference(courseId).then(result => {
        console.log("endVideoConference", result)
        this.syncInfo();
      });
    }
  }

  render() {
    const { t } = this.props;

    const {id, password, courseId, isActived} = this.state;

    const displayerClassName = cx('ZoomTeacher', { "full-screen-displayer": this.isFullScreen})

    const styleInput = {
      width: "200px"
    }

    return (
      <div className={displayerClassName}>
        <div style={{padding: "10px"}}>
          <div style={{color: "#ffffff", marginTop: "10px"}}>
            {this.state.courseTitle || t('zoom.retrievingInfo')}
          </div>
          <div style={{color: "#ffffff", marginTop: "10px"}}>
            {t('zoom.courseCode')}
          </div>
          <input disabled={isActived} style={styleInput} value={courseId} onChange={this.onInputChanged.bind(this, "courseId")}/>
          <div style={{color: "#ffffff",  marginTop: "20px"}}>
            {t('zoom.meetingId')}
          </div>
          <input disabled={isActived} style={styleInput} value={id} onChange={this.onInputChanged.bind(this, "id")}/>
          <div style={{color: "#ffffff", marginTop: "20px"}}>
            {t('information.account.password')}
          </div>
          <input disabled={isActived} style={styleInput} value={password} onChange={this.onInputChanged.bind(this, "password")}/>
          <div style={{marginTop: "20px"}}>
            {/*<div>*/}
            {/*  {!this.state.zoomStarted && <div>視訊課程尚未開始</div>}*/}
            {/*  {this.state.zoomStarted && <div>視訊課程已經開始</>}*/}
            {/*</div>*/}
            <div style={{marginTop: "10px"}}>
              {!isActived && <Button onClick={this.onStartClick.bind(this)}>{t('zoom.startCourse')}</Button>}
              {isActived && <Button onClick={this.onStartClick.bind(this)}>{t('zoom.endCourse')}</Button>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ZoomTeacher;
