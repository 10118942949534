import * as cons from '../constants/registrationNew';
import { getUUID, sendEmail } from '../../shared/util/utils';
import { API_URL, STUFF_MAIL_LIST } from '../../settings';
import { calculateRegistrationPrice } from '../../shared/util/registrationUtils';
import { codingbarApi } from 'codingbar-api';

export function getInitialState() {
  return {
    allGoodsFromDB: [],
    allInfosFromDB: [],

    step: 0,
    formId: "",
    payId: "",
    payLink: "",
    isWaitForRegistration: false,
    isCheckUserCount: false,

    settings: {
      enabled: true,
      formAttributeSelected: "normal",
      payMethodSelected: "all",
      groupOptionList: [
        { value: "2", label: "2人", checked: false },
        { value: "3", label: "3人", checked: false },
        { value: "4", label: "4人", checked: false },
        { value: "5", label: "5人", checked: false },
      ],
      title: "",
      subtitle: "",
      description: "",

      mainCourse: {
        generalId: "",
        classes: [
          {
            classId: "",
            singlePriceSettings: [
              //{ "payAmount": 9800, "endTimeStamp": 1598716800000.0 },
              //{ "payAmount": 10800, "endTimeStamp": 1600099200000.0 },
              //{ "payAmount": null, "endTimeStamp": NaN },
              //{ "payAmount": null, "endTimeStamp": NaN }
            ],
            formGeneralId: "",
            className: "",
            place: "",
            placeLabel: "",
            courseStartAndEndDate: "",
            classTime: "",
            classRoomName: "",
            classRoomId: "",
            status: "",
            originalPrice: 99998,
            finalPrice: 99999,
            classRoomLocation: ""
          }
        ]
      },
      subCourses: [],
      goods: {},

      enableInfoFrom: true,
      infoOptions: {},
      enableGradeSelect: true,
      gradeOptions: {},
      enableTaxNumber: false,
      enableVegan: false,
      enableBirthdayAndIdentityCardNumber: true,
      enableOthers: false,
      othersOptions: ["自行駕車，需要停車證"],
      policyUrl: "https://codingbar.ai/service_term/index.html",

      emailPaymentTitle: "",
      emailPaymentContent: "",
      emailSimplePaymentContent: "",
      emailNotPaidTitle: "",
      emailNotPaidContent: "",
      emailSimpleNotPaidContent: "",
      emailCBANotPaidContent:"",
      emailWaitingListTitle: "", 
      emailWaitingListContent: "",
      emailSimpleWaitingListContent: "",

      GlobalGACodeList: [],
      GACodeList: [],
      GlobalFACodeList: [],
      FACodeList: [],
    },

    // step 1
    placeSelected: "",

    isFirstGroupRegistration: false, // 團報報名表才用的到，表示此user是團報第一人
    groupCountSelected: null,        // 團報報名表才用的到，表示此團報方案的人數
    groupCode: "",            // 團報報名表才用的到，團報識別碼

    // step 2
    classSelected: "",
    classSelectedId: "",
    goodsSelectedList: [],

    useOldMember: false,
    oldMemberPhone: "",
    oldMemberData: [],

    useCoupon: false,
    couponCode: "",
    couponLegal: false,
    couponCodeDiscount: 0,
    couponRemark: "",
    couponTarget: "",

    useTaxNumber: false,
    taxTitle: "",
    taxNumber: "",

    // step 3
    subCoursesSelected: [
      // { courseGeneralId: "", classSelected: "", classSelectedId: "", goodsSelectedList: [] },
    ],

    // step 4
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    contactConty: "",
    contactAddress: "",

    useOldStudent: false,
    oldStudentEmail: "",

    useContactAsStudent: false,

    studentName: "",
    studentEmail: "",
    studentPhone: "",
    studentSchool: "",
    studentGrade: "",
    studentBirthday: null,
    studentIDNumber: "",

    needVegan: false,
    needOthers: [],
    remark: "",
    infoFromSelected: [],

    // step 5
    priceData: {},
    payAmount: 0,
    // discountType: cons.DISCOUNT.NONE,


  };
}

export default function reducer(state = getInitialState(), action = {}) {
  const { type, payload } = action
  switch (type) {
    case cons.INIT:
      return getInitialState()

    case cons.SET_ALL_GOODS_FROM_DB: {
      return {
        ...state,
        allGoodsFromDB: payload.allGoodsFromDB
      }
    }
    case cons.SET_ALL_INFOS_FROM_DB: {
      return {
        ...state,
        allInfosFromDB: payload.allInfosFromDB
      }
    }
    case cons.GET_REGISTRATION_SETTING: {
      let {
        formId,
        enabled, formAttributeSelected, payMethodSelected, groupOptionList,
        title, subtitle, description, isOldMemberDiscount,
        mainCourse, subCourses, goods,
        enableTaxNumber, enableInfoFrom, infoOptions, enableGradeSelect, gradeOptions, enableVegan, enableBirthdayAndIdentityCardNumber,
        enableOthers, othersOptions, policyUrl,
        emailPaymentTitle, emailPaymentContent, emailSimplePaymentContent, emailNotPaidTitle, emailNotPaidContent, emailSimpleNotPaidContent, emailCBANotPaidContent, emailWaitingListTitle, emailWaitingListContent, emailSimpleWaitingListContent,
        GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
      } = payload

      groupOptionList = groupOptionList.filter(option => option.checked)
      const subCoursesSelected = subCourses.map(({ generalId }) => ({
        courseGeneralId: generalId, classSelected: "", classSelectedId: "", goodsSelectedList: []
      }))

      mainCourse.classes = sortClassesByClassId(mainCourse.classes);

      return {
        ...state,
        formId,
        subCoursesSelected,
        settings: {
          enabled, formAttributeSelected, payMethodSelected, groupOptionList,
          title, subtitle, description, isOldMemberDiscount,
          mainCourse, subCourses, goods,
          enableTaxNumber, enableInfoFrom, infoOptions, enableGradeSelect, gradeOptions, enableVegan, enableBirthdayAndIdentityCardNumber,
          enableOthers, othersOptions, policyUrl,
          emailPaymentTitle, emailPaymentContent, emailSimplePaymentContent, emailNotPaidTitle, emailNotPaidContent, emailSimpleNotPaidContent, emailCBANotPaidContent, emailWaitingListTitle, emailWaitingListContent, emailSimpleWaitingListContent,
          GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
        }
      }
    }

    case cons.SET_REDUCER: {
      let newState = { ...state, ...payload }

      const classSelected = newState.placeSelected == state.placeSelected ? newState.classSelected : ""   // 若placeSelected變化，則清空classSelected
      const classSelectedId = newState.placeSelected == state.placeSelected ? newState.classSelectedId : ""   // 若placeSelected變化，則清空classSelected
      const oldStudentRelated = updateOldStudentRelated(newState)

      newState = { ...newState, classSelected, classSelectedId, ...oldStudentRelated }

      const priceData = calculateRegistrationPrice(newState, newState.settings)

      return {
        ...newState,
        priceData,
      }
    }

    case cons.SET_PAY_LINK: {
      return {
        ...state,
        payLink: payload.payLink
      }

    }

    case cons.GO_PREV_STEP: {
      window.scrollTo(0, 0)
      if (state.step === 4) {
        if (state.settings.formAttributeSelected !== "group" && state.settings.subCourses && state.settings.subCourses.length) {
          return { ...state, step: (state.step - 1) }
        }
        else {
          return { ...state, step: (state.step - 2) }
        }
      }
      else {
        return { ...state, step: (state.step - 1) }
      }
    }

    case cons.GO_NEXT_STEP: {
      window.scrollTo(0, 0)
      if (state.step === 2) {
        if (state.settings.formAttributeSelected !== "group" && state.settings.subCourses && state.settings.subCourses.length) {
          return { ...state, step: (state.step + 1) }
        }
        else {
          return { ...state, step: (state.step + 2) }
        }
      }
      else {
        return { ...state, step: (state.step + 1) }
      }
    }

    case cons.WAIT_FOR_REGISTRATION: {
      return {
        ...state,
        isWaitForRegistration: true,
        step: (state.step + 1)
      }
    }

    case cons.CHECK_USER_COUNT: {
      return {
        ...state,
        isCheckUserCount: true
      }
    }

    case cons.CANCEL_CHECK_USER_COUNT: {
      return {
        ...state,
        isCheckUserCount: false
      }
    }

    default:
      return state;
  }
}

export function initRegistration() {
  return {
    type: cons.INIT
  }
}

export function setAllGoodsFromDB() {
  return (dispatch) => {
    codingbarApi.getRegistrationService().getAllGoods().then(data => {
      dispatch({
        type: cons.SET_ALL_GOODS_FROM_DB,
        payload: { allGoodsFromDB: data }
      })
    })
  }
}
export function setAllInfosFromDB() {
  return (dispatch) => {
    codingbarApi.getRegistrationService().getAllInformationSources().then(data => {
      const allInfosFromDB = data.map(item => {
        const { informationId, ...others } = item
        return { id: informationId, ...others }
      })
      dispatch({
        type: cons.SET_ALL_INFOS_FROM_DB,
        payload: { allInfosFromDB: allInfosFromDB }
      })
    })
  }
}

export function getRegistrationSetting(formId, callback) {
  return (dispatch) => {
    codingbarApi.getRegistrationService().getRegistrationForm(formId).then(data => {
      console.log("THEN RESULT", data)
      if (!data) {
        dispatch({
          type: cons.GET_REGISTRATION_SETTING,
          payload: { enabled: false }
        })
      }
      else {
        codingbarApi.getRegistrationService().getAllClasses().then(allClassesFromDB => {
          const mainCourse = updateCourseObj(data.mainCourse, allClassesFromDB)
          const subCourses = data.subCourses.map(item => updateCourseObj(item, allClassesFromDB))
          const payload = { ...data, mainCourse, subCourses }
          console.log("THEN payload", payload)
          dispatch({
            type: cons.GET_REGISTRATION_SETTING,
            payload
          })
          if (callback) callback(data)
          dispatch({ type: cons.GO_NEXT_STEP })
        })
      }
    })
  }
}

function updateCourseObj(courseObj, allClassesFromDB) {
  const courseFromDB = allClassesFromDB.find(course => course.formGeneralId === courseObj.generalId)
  console.log("old", courseObj.classes)
  courseObj.courseTitle = courseFromDB.classes[0].className
  courseObj.classes = courseObj.classes.map(({ classId }) => {
    return courseFromDB.classes.find(classItem => classItem.classId === classId)
  })
  console.log("new", courseObj.classes)

  courseObj.classes = courseObj.classes.filter(classItem => classItem && classItem.status === "招生")
  console.log("new2", courseObj.classes)
  return courseObj

}

export function setReducer(data) {
  return {
    type: cons.SET_REDUCER,
    payload: data
  }
}

export function saveAndGoPay() {
  return (dispatch, getState) => {
    const payId = getUUID()
    const { registrationNew } = getState()
    const {
      formId, settings,
      placeSelected, classSelected, classSelectedId, goodsSelectedList,
      subCoursesSelected,
      useCoupon, couponLegal, couponCode, couponCodeDiscount, couponRemark, couponTarget,
      useTaxNumber, taxTitle, taxNumber,
      contactName, contactEmail, contactPhone, contactConty, contactAddress,
      studentName, studentEmail, studentPhone, studentSchool, studentGrade, studentBirthday, studentIDNumber,
      needVegan, needOthers, remark, infoFromSelected,
      priceData,
      isWaitForRegistration,
    } = registrationNew

    const studentInfoList = [{
      name: studentName,
      identityCardNumber: "",
      birthday: studentBirthday ? studentBirthday.format('YYYY/MM/DD') : "",
      identityCardNumber: studentIDNumber,
      email: studentEmail.toLowerCase(),
      phone: studentPhone,
      school: studentSchool,
      grade: studentGrade,
    }]

    const data = {
      version: "2.1",
      formId: formId,
      generalFormId: settings.mainCourse.generalId,
      payId,
      numOfStudent: 1,    // for old version merging
      isWaitForRegistration,

      placeSelected, classSelected, classSelectedId, goodsSelectedList,
      subCoursesSelected,

      taxTitle: useTaxNumber ? taxTitle : "",
      taxNumber: useTaxNumber ? taxNumber : "",

      contactName, contactEmail, parentEmail: contactEmail, contactPhone, contactConty, contactAddress,
      studentInfoList,
      needVegan, needOthers, remark, infoFromSelected,

      priceData,
      payAmount: priceData.finalPrice,
      discountTypeList: [],

    }

    if (settings.formAttributeSelected === "group") {
      const { isFirstGroupRegistration, groupCountSelected, groupCode } = registrationNew
      data.groupCode = groupCode
      data.isFirstGroupRegistration = isFirstGroupRegistration
      data.groupCount = parseInt(groupCountSelected)
      data.discountTypeList.push(cons.DISCOUNT.GROUP)
    }
    if (registrationNew.useOldMember && registrationNew.oldMemberData.length > 0) {
      const { useOldMember, oldMemberPhone, useOldStudent, oldStudentEmail } = registrationNew
      data.useOldMember = useOldMember
      data.useOldStudent = useOldStudent
      data.oldMemberPhone = oldMemberPhone
      if (useOldStudent) data.oldStudentEmail = oldStudentEmail
    }
    if (useCoupon && couponLegal) {
      data.useCoupon = true
      data.couponCodeEntered = couponCode
      data.couponCodeDiscount = couponCodeDiscount
      data.couponRemark = couponRemark
      data.couponTarget = couponTarget
      data.discountTypeList.push(cons.DISCOUNT.COUPON)
    }

    const payLink = `${API_URL.PAY}/payment/registration/order/${payId}`;

    console.log("FINAL DATA", data)

    if (isWaitForRegistration) {
      codingbarApi.getRegistrationService().createSignUpUser(data)
      .then(() => {
        return sendEmailWaitingList(data, registrationNew.settings)
      })
      .then(() => {
        return sendEmailToStuffs(data, registrationNew.settings)
      })
      .then(() => {
        dispatch(goNextStep())
      })
      .catch(err => {
        console.log("codingbarApi.getRegistrationService().createSignUpUser ERROR!!!", err)
      })
    } 
    else {
      codingbarApi.getRegistrationService().createSignUpUser(data).then((res) => {
        dispatch(setPayLink(payLink))
        return sendEmailNotPaid(data, registrationNew.settings, res, payLink)
      }).then((res) => {
        console.log("sendEmailNotPaid", res)
        return sendEmailToStuffs(data, registrationNew.settings, res, payLink)
      }).then((res) => {
        console.log("sendEmailToStuffs", res)
        if (data.payAmount < 1) {
          alert("出現錯誤，請洽客服人員！")
        }
        else {
          dispatch(goNextStep())    // from step 6 to step 7
        }
      }).catch(err => {
        console.log("codingbarApi.getRegistrationService().createSignUpUser ERROR!!!", err)
      })
    }
  }
}

function setPayLink(payLink) {
  return {
    type: cons.SET_PAY_LINK,
    payload: { payLink }
  }
}

function sendEmailNotPaid(registrationData, registrationSettings, apiResponse, payLink) {
  const { studentInfoList, payAmount, contactName, contactEmail, groupCode, classSelectedId, subCoursesSelected } = registrationData
  const { title, emailNotPaidTitle, emailNotPaidContent, mainCourse, subCourses } = registrationSettings
  const { } = { apiResponse }

  const allRegisteredCourses = [];

  allRegisteredCourses.push(mainCourse.classes.find(item => item.classId === classSelectedId));

  if (subCoursesSelected.length > 0) {
    subCoursesSelected.forEach((subCourseSelected, idx)=> {
      const foundSubCourse = subCourses[idx].classes.find(item => item.classId === subCourseSelected.classSelectedId);
      if (foundSubCourse) {
        allRegisteredCourses.push(foundSubCourse);
      }
    })
  }

  const studentListStr = `
    <div>學生名字： ${studentInfoList[0].name}</div>
    <div>學生帳號： ${studentInfoList[0].email}</div>
    <div>聯絡人姓名： ${contactName}</div>
    <div>聯絡人Email： ${contactEmail}</div>`

  const coursesList = () => {
    const courseLi = (
      allRegisteredCourses.map(course=> {
        const { className, place, placeLabel, courseStartAndEndDate, classTime, classRoomName } = course;
        return `<li>${className}<br> ${place}${placeLabel} ${courseStartAndEndDate !== null ? courseStartAndEndDate : ''} ${classTime !== null ? classTime : ''} ${classRoomName !== null ? classRoomName : ''}</li>`
        // 範例: 'Python程式創世神 新竹E班 7/5~7/9 09:00-17:00 竹北教室'
      }).join('')
    )
    return (
      `<div>課程資訊：<ul>${courseLi}</ul></div>`
    )
  }

  var emailTitle = emailNotPaidTitle
  emailTitle = emailTitle.replace(/{{title}}/g, title)

  var emailContent = emailNotPaidContent
  emailContent = emailContent.replace(/{{title}}/g, title)
  emailContent = emailContent.replace(/{{payAmount}}/g, payAmount)
  emailContent = emailContent.replace(/{{payLink}}/g, payLink)
  emailContent = emailContent.replace(/{{studentList}}/g, studentListStr)
  emailContent = emailContent.replace(/{{groupCode}}/g, groupCode)
  emailContent = emailContent.replace(/{{coursesList}}/g, coursesList())

  const receiverList = [...new Set([contactEmail, studentInfoList[0].email])]

  console.log(receiverList, emailTitle, emailContent)
  return sendEmail(receiverList, emailTitle, emailContent)
}

function sendEmailWaitingList(registrationData, registrationSettings) {
  const { studentInfoList, payAmount, contactName, contactEmail, groupCode, classSelectedId, subCoursesSelected } = registrationData
  const { title, emailWaitingListTitle, emailWaitingListContent, mainCourse } = registrationSettings

  const allRegisteredCourses = [];

  allRegisteredCourses.push(mainCourse.classes.find(item => item.classId === classSelectedId));

  if (subCoursesSelected.length > 0) {
    subCoursesSelected.forEach((subCourseSelected, idx)=> {
      const foundSubCourse = subCourses[idx].classes.find(item => item.classId === subCourseSelected.classSelectedId);
      if (foundSubCourse) {
        allRegisteredCourses.push(foundSubCourse);
      }
    })
  }

  const studentListStr = `
    <div>學生名字： ${studentInfoList[0].name}</div>
    <div>學生帳號： ${studentInfoList[0].email}</div>
    <div>聯絡人姓名： ${contactName}</div>
    <div>聯絡人Email： ${contactEmail}</div>`
  
  const coursesList = () => {
    const courseLi = (
      allRegisteredCourses.map(course=> {
        const { className, place, placeLabel, courseStartAndEndDate, classTime, classRoomName } = course;
        return `<li>${className}<br> ${place}${placeLabel} ${courseStartAndEndDate !== null ? courseStartAndEndDate : ''} ${classTime !== null ? classTime : ''} ${classRoomName !== null ? classRoomName : ''}</li>`
        // 範例: 'Python程式創世神 新竹E班 7/5~7/9 09:00-17:00 竹北教室'
      }).join('')
    )
    return (
      `<div>課程資訊：<ul>${courseLi}</ul></div>`
    )
  }

  var emailTitle = emailWaitingListTitle
  emailTitle = emailTitle.replace(/{{title}}/g, title)

  var emailContent = emailWaitingListContent
  emailContent = emailContent.replace(/{{title}}/g, title)
  emailContent = emailContent.replace(/{{studentList}}/g, studentListStr)
  emailContent = emailContent.replace(/{{groupCode}}/g, groupCode)
  emailContent = emailContent.replace(/{{coursesList}}/g, coursesList())

  const receiverList = [...new Set([contactEmail, studentInfoList[0].email])]

  console.log(receiverList, emailTitle, emailContent)
  return sendEmail(receiverList, emailTitle, emailContent)
}

function sendEmailToStuffs(registrationData, registrationSettings, apiResponse) {
  const { placeSelected, classSelected, classSelectedId, remark, studentInfoList, payAmount, contactName, contactEmail, contactPhone, isWaitForRegistration } = registrationData
  const { title } = registrationSettings

  const emailTitle = `${title} 報名確認函`

  const emailContent =
    `<p>課程: ${title}</p>\n
  <p>報名地點及班別: ${placeSelected} ${classSelected} ${classSelectedId} ${isWaitForRegistration ? '(候補)' : ''}</p>
  <div>學生名字： ${studentInfoList[0].name}</div>
  <div>學生帳號： ${studentInfoList[0].email}</div>
  <p>價錢: ${payAmount}</p>\n
  <p>聯絡人姓名: ${contactName}<br />\n
  聯絡人Email: ${contactEmail}<br />\n
  聯絡人電話: ${contactPhone}<br />\n
  備註: ${remark}</p>`

  console.log(STUFF_MAIL_LIST, emailTitle, emailContent)
  return sendEmail(STUFF_MAIL_LIST, emailTitle, emailContent)
}

function sortClassesByClassId(classes) {
  const sortBySpecificLetter = (a, b) => {
    const aChar3 = a.classId[3];
    const bChar3 = b.classId[3];
    const aChar6 = a.classId[6];
    const bChar6 = b.classId[6];

    // Sort fourth letter: C > R > H > everything else
    const char3Order = (char3) => {
      switch(char3) {
        case 'C':
          return 1;
        case 'R':
          return 2;
        case 'H':
          return 3;
        default:
          return 4;
      }
    }

    // Sort seventh letter: A-Z > 0-9
    const char6Order = () => {
      // +num: returns numeric value of string or NaN (ex. '1' -> 1; 'A' -> NaN)
      // !isNaN(+char): checks if is string of number
      if (!isNaN(+aChar6) && !isNaN(+bChar6)) return +aChar6 - +bChar6
      else if (!(isNaN(+aChar6) && isNaN(+bChar6)) && aChar6>bChar6) return -1
      else if (!(isNaN(+aChar6) && isNaN(+bChar6)) && aChar6<bChar6) return 1
      else if (aChar6 > bChar6) return 1
      else if (aChar6 < bChar6) return -1
      else return 0
    }

    // Sort fourth letter, then seventh letter:
    const comparisonChar3 = char3Order(aChar3) - char3Order(bChar3);
    const comparisonChar6 = char6Order();

    return comparisonChar3 || comparisonChar6;
  }

  return classes.sort(sortBySpecificLetter);
}


export function goPrevStep() {
  return {
    type: cons.GO_PREV_STEP
  }
}
export function goNextStep() {
  return {
    type: cons.GO_NEXT_STEP
  }
}

export function goToWaitingStep() {
  return {
    type: cons.WAIT_FOR_REGISTRATION
  }
}

export function checkUserCount() {
  return {
    type: cons.CHECK_USER_COUNT
  }
}

export function cancelCheckUserCount() {
  return {
    type: cons.CANCEL_CHECK_USER_COUNT
  }
}


function updateOldStudentRelated(state) {
  const updateObj = {}
  let { useOldMember, oldMemberData, useOldStudent } = state
  if (!useOldMember || !oldMemberData.length) {
    updateObj.oldMemberPhone = ""
    updateObj.oldMemberData = []
    if (useOldStudent) {
      updateObj.useOldStudent = false
      updateObj.oldStudentEmail = ""
      updateObj.studentName = ""
      updateObj.studentEmail = ""
      updateObj.studentPhone = ""
      updateObj.studentSchool = ""
      updateObj.studentGrade = ""
      updateObj.studentBirthday = null
      updateObj.studentIDNumber = ""
    }
  }
  return updateObj
}
