import { codingbarApi } from 'codingbar-api';
import { 
	STAFF_PERMISSIONS,
} from './collections';

export const createStaffPermissions = (permissionsData) => {
	if (!permissionsData) return Promise.reject('createStaffPermissions# error! Incorrect data!');

	return codingbarApi.getCoreService().createData(STAFF_PERMISSIONS, permissionsData);
}

export const removeStaffPermissions = (uid) => {
	if (!uid) return Promise.reject('removeStaffPermissions# error! Incorrect Uid!');

	const filter = {
		uid,
	}

	return codingbarApi.getCoreService().removeData(STAFF_PERMISSIONS, filter);
}

export const updateStaffPermissions = (uid, permissionsData) => {
	const filter = { 
		uid,
	}

	return codingbarApi.getCoreService().updateData(STAFF_PERMISSIONS, filter, permissionsData);
}

export const fetchStaffPermission = (uid) => {
	if (!uid) return Promise.reject('fetchStaffPermissions# error! Incorrect Uid!');

	const filter = {
		uid,
	}

	return codingbarApi.getCoreService().queryOne(STAFF_PERMISSIONS, filter)
	.then(res => {
		return res.result;
	})
	.catch(err => console.error(err));
}

export const fetchAllStaffPermission = () => {
	const filter = {
		
	}
	
	return codingbarApi.getCoreService().queryData(STAFF_PERMISSIONS, filter, {}).then(res => {
		return res.status === 'success' ? res.result : [];
	})
	.catch(err => console.error(err));
}