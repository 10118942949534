import React from 'react';

import './DisconnectNet.scss';
import { ReactComponent as MonsterDisconnectNetwork} from 'design/images/monster/monster-disconnectNetwork.svg'
import { translate } from 'react-i18next';

@translate('translations')
export default class DisconnectNet extends React.Component {
	static propTypes = {

	};

	constructor(props) {
		super(props);

	}

	render() {
		const { t } = this.props;

		return (
			<div className="DisconnetNetFrame">
				<MonsterDisconnectNetwork className="DisconnetNetFrame-img"/>
				<div className="DisconnetNetFrame-Content">
					<div className="DisconnetNetFrame-title">
						{t('disconnetPage.title')}
					</div>
					<div className="DisconnetNetFrame-text">
						{t('disconnetPage.text')}
					</div>
				</div>
			</div>
		)
	}
}
