import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './MultiPillButton.scss';

const MultiPillButton = memo(function MultiPillButton({ className, actions }) {
	if (actions.length === 0) {
		return null;
	}
	return (
		<div className={cx('MultiPillButton', className)}>
			{actions.map((action) => {
				return (
					<div
						key={action.label}
						className={cx('MultiPillButton-action', { disabled: action.disabled })}
						aria-hidden="true"
						onClick={action.onClick}
					>
						{action.label}
					</div>
				);
			})}
		</div>
	);
});

MultiPillButton.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.shape({
		disabled: PropTypes.bool,
		label: PropTypes.string,
		onClick: PropTypes.func,
	})),
	className: PropTypes.string,
};

export default MultiPillButton;
