import React from 'react';
import { codingbarApi } from 'codingbar-api';
import axios from 'axios';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import cx from 'classnames';

import { addToast } from 'redux/reducers/app';

import { fetchFeedbackInternalReceivers } from 'shared/helper/permission';

import { ActionModal, TextArea, Select } from 'components';
import ProgressDialog from 'components/ProgressDialog';

import './FeedbackManage.scss';
import { feedbackManageTitleOptions } from './FeedbackManageConstants';

@translate('translations')
@connect((state) => {
	const app = state.app;
	return {
		user: app.get('user'),
	};
})
export default class FeedbackManage extends React.PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);

		this.state = {
			feedbackTitle: {
				value: 'default',
				label: this.props.t('feedback.issue.default'),
			},
			feedbackDescription: '',
			includeScreenShot: true,
			uploadProgress: 0,
			fileUrl: undefined,
			isSubmittable: false,

			showProgress: false,
		};
	}

	componentDidMount() {
		// change canvas to blob to save image as binary
		this.props.screenshotCanvas.toBlob((blob) => {
			const data = new FormData();
			const today = new Date().getTime();
			const filename = `feedback_${today}.png`;
			data.append('file', blob, filename);
			axios.post(codingbarApi.getCourseService().getMaterialUploadUrl(), data, {
				onUploadProgress: (ProgressEvent) => {
					this.setState({
						uploadProgress: (ProgressEvent.loaded / ProgressEvent.total) * 100,
					});
				},
			})
			.then((res) => {
				console.log('zzz', res);
				if (res.status === 200 && res.data.status === 'ok') {
					console.log(res.data.url);
					this.setState({
						fileUrl: res.data.url,
					});
				}
			})
		});
	}

	componentDidUpdate() {
		/* 將截圖顯示在畫面（移除state  alreadyAppendCanvas: false) */
		// if (
		//   !this.state.alreadyAppendCanvas &&
		//   document.getElementById("feedback-screenshot")
		// ) {
		//   this.setState({ alreadyAppendCanvas: true });
		//   document
		//     .getElementById("feedback-screenshot")
		//     .appendChild(this.props.screenshotCanvas);
		// }
	}

	onFeedbackTitleChange = (selected) => {
		this.setState({
			feedbackTitle: selected,
		});
	};

	onFeedbackDescriptionChange = (e) => {
		this.setState({
			feedbackDescription: e.target.value,
		});
	};

	onIncludeScreenshotChange = (e) => {
		this.setState({
			includeScreenShot: e.target.checked,
		});
	};

	handleScreenshotUpload = async () => {
		const { user, t } = this.props;
		const { feedbackTitle, feedbackDescription, fileUrl } = this.state;

		if (!this.state.feedbackTitle.value || this.state.feedbackTitle.value === 'default') {
			return;
		}
		// const data = new FormData();
		// const today = new Date().getTime();
		// const filename = `feedback_${today}.png`;
		// data.append('file', this.state.screenshot, filename);
		// axios
		// 	.post(codingbarApi.getCourseService().getMaterialUploadUrl(), data, {
		// 		onUploadProgress: (ProgressEvent) => {
		// 			this.setState({
		// 				uploadProgress: (ProgressEvent.loaded / ProgressEvent.total) * 100,
		// 			});
		// 		},
		// 	})
		// 	.then((res) => {
		// 		console.log('zzz', res);
		// 		if (res.status === 200 && res.data.status === 'ok') {
		// 			console.log(res.data.url);
		// 			this.setState({
		// 				fileUrl: res.data.url,
		// 			});
		// 		}
		// 	})
		const uid = user.get('uid');
		// Description增加空白內容，才可以順利傳送
		const feedbackDescriptionToSend = feedbackDescription || '&nbsp;';
		const receivers = await fetchFeedbackInternalReceivers();

		codingbarApi
			.getFeedbackService()
			.createFeedback(uid, feedbackTitle.label, feedbackDescriptionToSend, fileUrl, receivers)
			.then(() => {
				this.props.dispatch(addToast({ toastType: 'success', toastText: t('feedback.msg') }));

				this.setState({
					showProgress: false,
				}, this.props.closeModal)
			});
	};

	// handleWithoutScreenshot = () => {
	// 	const { t } = this.props;
	// 	if (!this.state.feedbackTitle.value || this.state.feedbackTitle.value === 'default') {
	// 		return;
	// 	}
	// 	console.log('preparing to post feedback without screenshot');
	// 	const uid = this.props.user.get('uid');
	// 	const { feedbackTitle, feedbackDescription } = this.state;
	// 	// Description增加空白內容，才可以順利傳送
	// 	const feedbackDescriptionToSend = feedbackDescription || '&nbsp;';
	// 	codingbarApi
	// 		.getFeedbackService()
	// 		.createFeedback(uid, feedbackTitle.label, feedbackDescriptionToSend, 'No screenshot')
	// 		.then(() => {
	// 			alert(t('feedback.msg'));
	// 			this.props.closeModal();
	// 		});
	// };

	onSubmitFeedback = (e) => {
		e.stopPropagation();

		this.setState({
			showProgress: true,
		})

		this.handleScreenshotUpload();
	// 	if (this.state.includeScreenShot) {
	// 		this.handleScreenshotUpload();
	// 	} else {
	// 		this.handleWithoutScreenshot();
	// 	}
	};

	handleClick = (e) => {
		e.stopPropagation();
	};

	render() {
		const { isShow, closeModal, t, rootClassName } = this.props;
		const { feedbackTitle, feedbackDescription } = this.state;
		const cn = cx('feedback-manage-main', rootClassName);

		return (
			<ActionModal
				className={cn}
				modalBodyClassname="feedback-manage-main-body"
				isOpen={isShow}
				onClose={closeModal}
				isShowClose={false}
				modalTitle={t('feedback.title')}
				onCancelButtonClick={closeModal}
				onConfirm={(e) => this.onSubmitFeedback(e)}
				isConfirmButtonDisabled={feedbackTitle.value === 'default' || !feedbackTitle.value || !feedbackDescription || !feedbackDescription.trim()}
				confirmButtonText={t('feedback.submit')}
			>
				<div className="feedback-manage-content-body">
					<div className="feedback-manage-content-title">{t('feedback.subject')}</div>

					<Select
						name="feedback-manage-issue"
						className="bulleted-list-item"
						onChange={this.onFeedbackTitleChange}
						value={this.state.feedbackTitle}
						options={feedbackManageTitleOptions}
					/>

					<div className="feedback-manage-content-title">{t('feedback.description')}</div>
					<TextArea
						className="add-comment-input"
						value={this.state.feedbackDescription}
						placeholder={t('feedback.descriptionPlaceholder')}
						onChange={this.onFeedbackDescriptionChange}
					/>
					{/* <div className="feedback-manage-content-title">
						{t("feedback.screenshot")}
					</div>
					<div
						id="feedback-screenshot"
						className="feedback-manage-screenshot"
					></div> */}
									{/* <div className="feedback-manage-checkBox-container">
						<Checkbox
						checked={this.state.includeScreenShot}
						onChange={this.onIncludeScreenshotChange}
						/>
						<p className="include-screenShot-label">
						{t('feedback.withScreenshot')}
						</p>
					</div> */}
					<p className="feedback-manage-additional">
						{t('feedback.additional.agree1')}
						<a href="https://codingbar.ai/privacy/index.html" target="_blank">
							{t('feedback.additional.privatePolicy')}
						</a>
						{t('feedback.additional.agree2')}
					</p>
				</div>
				<ProgressDialog
					show={this.state.showProgress}
					// message={t('common.loading')}
					type={'systemLoading'}
				/>
			</ActionModal>
		);
	}
}
