import { COURSES_COLLECTION, TRANSLATION_INFO_COLLECTION } from './collections';

export const getTranslationInfoListOfTeacher = (teacherUid, projection = {}) => {
	const filter = {
		uid: teacherUid,
		translationStatus: 'ongoing',
	}
	
	const sort = {
		timestamp: 1,
	}
	
	return codingbarApi.getCoreService().queryData(TRANSLATION_INFO_COLLECTION, filter, sort, projection, '');
}

export const getTranslationInfoByStatus = (translationStatus, skip, limit, sort = {}, searchString = '', projection = {}) => {
	const filter = {
		translationStatus, // ongoing || ended
	}
	
	const searchKeys = ['courseId', 'courseTitle', 'displayName', 'uid'];
	
	return codingbarApi.getCoreService().queryPagination(TRANSLATION_INFO_COLLECTION, filter, sort, skip, limit, searchKeys, searchString, projection);
}

export const getTranslationCourseById = (courseId) => {
	const filter = { 
		courseId,
		courseType: 'translation', 
	};
	
	const projection = {
		id: 1,
		courseId: 1,
		title: 1,
		content: 1,
		tags: 1,
		courseType: 1,
		lessons: 1,
		tests: 1,
	}
	
	return codingbarApi.getCoreService().queryOneWithoutUnitMerge(COURSES_COLLECTION, filter, projection)
	.then(res => {
		return res.result;
	})
	.catch(err => console.error(err))
}

export const createTranslationInfo = (translationObj) => {
	
	return codingbarApi.getCoreService().createData(TRANSLATION_INFO_COLLECTION, translationObj);
}

export const updateTranslationInfo = (id, infoObj) => {
	const filter = { id };
	
	return codingbarApi.getCoreService().patchData(TRANSLATION_INFO_COLLECTION, filter, infoObj);
}

export const removeTranslationInfo = (id) => {
	const filter = { id };
	
	return codingbarApi.getCoreService().removeData(TRANSLATION_INFO_COLLECTION, filter);
}

export const removeTranslationInfoByTranslationCourseId = (translationCourseId) => {
	if (!translationCourseId) return Promise.reject('removeTranslationInfoByCourseId: incorrect translation course id');
	
	const filter = { translationCourseId }
	
	return codingbarApi.getCoreService().removeData(TRANSLATION_INFO_COLLECTION, filter);
}

export const checkIsShowTranslateRoute = (userUid) => {
	const projection = {
		unit: 1,
		uid: 1,
	}
	
	return getTranslationInfoListOfTeacher(userUid, projection)
	.then(res => {
		return res.result.length > 0;
	})
	.catch(err => Promise.reject(err))
}