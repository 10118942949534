import i18n from 'lang/i18n';

export const GET_EXCERCISE = 'excercise/GET_EXCERCISE';
export const GET_EXCERCISE_SUC = 'excercise/GET_EXCERCISE_SUC';
export const GET_EXCERCISE_ERR = 'excercise/GET_EXCERCISE_ERR';

export const SAGA_GET_EXCERCISE = 'excercise/SAGA_GET_EXCERCISE';

export const EXERCISE_TYPE = {
	FREECODE: { value: 'freecode', label: 'FreeCode' },
	SLOT_FILLING: { value: 'slot-filling', label: i18n.t('component.exerciseManage.tab.fillin') },
	CLOZE: { value: 'cloze', label: i18n.t('component.exerciseManage.tab.cloze') },
	DRAGDROP: { value: 'dragdrop', label: i18n.t('component.exerciseManage.tab.dnd') },
	CHOICE: { value: 'choice', label: i18n.t('component.exerciseManage.tab.choice') },
	REARRANGEMENT: { value: 'rearrangement', label: i18n.t('component.exerciseManage.tab.rearrangement') },
	VIDEO: { value: 'video', label: 'Video' },
	STEP_BY_STEP: { value: 'step-by-step', label: i18n.t('component.exerciseManage.tab.step') },
};

export const exerciseTypes = Object.values(EXERCISE_TYPE);
const allExerciseTypeOptions = exerciseTypes.map((e) => e.value);
const basicExerciseTypeOptions = ['freecode', 'slot-filling'];
const paidExerciseTypeOptions = ['freecode', 'slot-filling', 'choice', 'rearrangement', 'video'];
export const multiExerciseTypeOptionMap = {
	all: allExerciseTypeOptions,
	basic: basicExerciseTypeOptions,
	paid: paidExerciseTypeOptions,
};

export const EXERCISE_TYPE_SCHOOL = {
	FREECODE: { value: 'freecode', label: 'FreeCode' },
	SLOT_FILLING: { value: 'slot-filling', label: i18n.t('component.exerciseManage.tab.fillin') },
	// VIDEO: { value: 'video', label: 'Video' },
};

export const EXERCISE_TYPE_DROPDOWN = Object.values(EXERCISE_TYPE);

export const EXERCISE_EDITOR_LANGUAGES = {
	PYTHON: { value: 'python', label: 'Python' },
	C: { value: 'c', label: 'C' },
	CPP: { value: 'cpp', label: 'C++' },
	JAVA: { value: 'java', label: 'Java' },
	LUA: { value: 'lua', label: 'Lua' },
};
export const EDITOR_FONT_SIZE_OPTIONS = [
	{ value: '8', label: '8 pt' },
	{ value: '10', label: '10 pt' },
	{ value: '12', label: '12 pt' },
	{ value: '14', label: '14 pt' },
	{ value: '16', label: '16 pt' },
	{ value: '18', label: '18 pt' },
	{ value: '20', label: '20 pt' },
	{ value: '22', label: '22 pt' },
	{ value: '24', label: '24 pt' },
	{ value: '26', label: '26 pt' },
];

export const EXERCISE_CODETYPE_ICON_MAP = {
	python: 'icon-codetype-python',
	pygame: 'icon-codetype-pygame',
	pyweb: 'icon-codetype-pyweb',
	microbit: 'icon-codetype-microbit',
	blocklymicrobit: 'icon-codetype-blocklymicrobit',
	meowbit: 'icon-codetype-meowbit',
	c: 'icon-codetype-c',
	cpp: 'icon-codetype-cpp',
	video: 'icon-video',
	java: 'icon-codetype-java',
	lua: 'icon-codetype-lua',
};

export const getCodetypeIcon = (codetype) => {
	return EXERCISE_CODETYPE_ICON_MAP[codetype];
};
// 測資類型
export const EXERCISE_VD_TYPE = {
	NON_VD: ['cloze', 'dragdrop', 'choice', 'rearrangement', 'video'],
	VD: ['freecode', 'slot-filling'],
};

export const DIVERSE_EXERCISE_TYPE = [
	EXERCISE_TYPE.CLOZE.value,
	EXERCISE_TYPE.DRAGDROP.value,
	EXERCISE_TYPE.CHOICE.value,
	EXERCISE_TYPE.REARRANGEMENT.value,
];
