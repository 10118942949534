export const REGISTRATION_SETTING_INIT = 'REGMAN_REGISTRATION_SETTING_INIT'
export const EDIT_EXISTED_REGISTRATION = 'REGMAN_EDIT_EXISTED_REGISTRATION'
export const SET_LOADING = 'REGMAN_SET_LOADING'
export const SAVED_DONE = 'REGMAN_SAVED_DONE'

export const SAVE_STEP_ONE = 'REGMAN_SAVE_STEP_ONE'
export const SAVE_STEP_TWO = 'REGMAN_SAVE_STEP_TWO'
export const SAVE_STEP_THREE = 'REGMAN_SAVE_STEP_THREE'
export const SAVE_STEP_FOUR = 'REGMAN_SAVE_STEP_FOUR'
export const SAVE_STEP_FIVE = 'REGMAN_SAVE_STEP_FIVE'
export const SAVE_STEP_SIX = 'REGMAN_SAVE_STEP_SIX'
export const CHECK_IF_FORM_CAN_ENABLED = 'REGMAN_CHECK_IF_FORM_CAN_ENABLED'

export const GO_PREV_STEP = 'REGMAN_GO_PREV_STEP'
export const GO_NEXT_STEP = 'REGMAN_GO_NEXT_STEP'
export const GO_STEP = 'REGMAN_GO_STEP'
// export const SAVE_STEP_FOUR_STUDENT = 'SAVE_STEP_FOUR_STUDENT'
// export const SAVE_STEP_FOUR = 'SAVE_STEP_FOUR'
