import React from 'react';
import IconLabel from '../common/IconLabel.js';

import iconIncomplete from 'design/images/icon-label-incomplete.svg'

export default function (props) {
  return (
    <IconLabel icon={iconIncomplete} label='尚未註冊確認' bgColor='#F65248' />
  );
}
