import { Map } from 'immutable';
import i18n from 'lang/i18n';
import { GRADE_LIST } from 'shared/util/registrationUtils';

export function isAuthenticated(user, roles) {
	user = Map.isMap(user) ? user.toJS() : user;
	const res = user && roles ? roles.indexOf(user.role) !== -1 : false;
	return res;
}

export function isEmailVerified(user) {
	user = Map.isMap(user) ? user.toJS() : user;
	const res = user.isEmailVerified;
	return res;
}

export function isBasicInfoFilled(user) {
	user = Map.isMap(user) ? user.toJS() : user;
	const res = user.displayName;
	return res;
}

export const getStudentGrade = (gradeValue) => {
	const gradeObj = GRADE_LIST.find((el) => el.id === parseInt(gradeValue) || el.name === gradeValue);

	if (gradeObj) return gradeObj.name;
	else return undefined;
};

export const getStudentEducationStage = (value) => {
	const elem = [
		i18n.t('studentInfo.grade.first'),
		i18n.t('studentInfo.grade.second'),
		i18n.t('studentInfo.grade.third'),
		i18n.t('studentInfo.grade.fourth'),
		i18n.t('studentInfo.grade.fifth'),
		i18n.t('studentInfo.grade.sixth'),
	];
	const junior = [
		i18n.t('studentInfo.grade.seventh'),
		i18n.t('studentInfo.grade.eighth'),
		i18n.t('studentInfo.grade.ninth'),
	];
	const senior = [
		i18n.t('studentInfo.grade.tenth'),
		i18n.t('studentInfo.grade.eleventh'),
		i18n.t('studentInfo.grade.twelfth'),
	];
	const uni = [i18n.t('studentInfo.grade.uni')];

	if (elem.includes(value)) return i18n.t('studentInfo.grade.elementary');
	else if (junior.includes(value)) return i18n.t('studentInfo.grade.junior');
	else if (senior.includes(value)) return i18n.t('studentInfo.grade.senior');
	else if (uni.includes(value)) return i18n.t('studentInfo.grade.uni');
	else return i18n.t('role.other');
};

export const getUserOrigin = async (userUnit) => {
	const origin = await codingbarApi
		.getUserService()
		.getCodingbarDomainAccessUnitList()
		.then((list) => {
			if (userUnit === 'airabbi') return 'toC';
			else if (list.some((item) => item.unit === userUnit.toLowerCase())) return 'toB';
			else return 'toS';
		})
		.catch((err) => Promise.reject(err));

	return origin;
};

//學生從 公開模擬考/CBA體驗課程 入口進入的話，添加追蹤 utm 資訊
export const onHandleUtmData = (user) => {
	var utmData = JSON.parse(localStorage.getItem('utmData'));

	const updateUser = { ...user, updated: Date.now(), utmData: utmData };
	codingbarApi
		.getUserService()
		.updateUser(updateUser)
		.then(() => {
			console.log('updateUser#', updateUser);
		})
		.catch((err) => console.log('submitEditUserInfo', err));
};

// unit id corresponding label
const unitMap = {
	新竹市國立竹科實驗高中附設國中: '新竹市國立竹科實驗高中',
	soltest: 'soltest最強學校',
	aiMate: 'AImate',
};

export const getUnitLabel = (unit) => {	
	return unitMap[unit] || unit;
};

export const getDisplayUnitsString = (unitsString) => {
	if (!Object.keys(unitMap).some((key) => unitsString.includes(key))) {
		return unitsString;
	}
	return Object.entries(unitMap).reduce((acc, [key, value]) => {
		return acc.replace(new RegExp(key, 'g'), value);
	}, unitsString);
};
