import React from 'react';
import MonacoEditor from 'react-monaco-editor';
import './PlaygroundExerciseEditor.scss';
import { codingbarMonacoTheme } from 'shared/util/EditorUtils'; // 不需要先移除 codingbarDarkMonacoTheme
import { EXERCISE_EDITOR_LANGUAGES } from 'redux/constants/excercise';

class ExerciseEditor extends React.PureComponent {
	componentDidMount = () => {
		this.setLock(false);
	};

	componentWillReceiveProps = (nextProps) => {
		const { status, fontSize } = nextProps;
		if (status) {
			if (this.props.status !== status) {
				console.log('isSubmitting# nextProps# setLock# status:' + this.props.status + ', new statust:' + status);
				console.log('ExerciseEditor# nextProps# unlock');
				this.setLock(false);
			}
		}
		if (fontSize !== this.props.fontSize) {
			this.state.monacoeditor.updateOptions({ fontSize });
		}
	};

	setLock = (lock) => {
		localStorage.setItem('isSubmitting', lock ? Date.now().toString() : '0');
	};

	getEditor = () => {
		const { language, fontSize } = this.props;
		// const nowUrl = window.location.href;
		// const isTest = ['course', 'test', 'exercise'].every(item => nowUrl.includes(item));

		const options = {
			selectOnLineNumbers: true,
			lineNumbersMinChars: 0,
			roundedSelection: false,
			readOnly: false,
			cursorStyle: 'line',
			automaticLayout: true,
			// theme: (isTest) ? "codingbarDarkMonacoTheme" : "codingbarMonacoTheme",
			theme: 'codingbarMonacoTheme',
			fontSize: fontSize || '16',
			// lineNumbers: 'off',
			minimap: {
				enabled: false,
			},
		};

		const requireConfig = {
			url: 'https://cdnjs.cloudflare.com/ajax/libs/require.js/2.3.1/require.min.js',
			paths: {
				vs: '/vs',
			},
		};

		return (
			<MonacoEditor
				key="1"
				height={this.props.playgroundframeheight - 48 - 200 - 32}
				width={this.props.playgroundframewidth - 32 - 18}
				language={language}
				requireConfig={requireConfig}
				options={options}
				value={this.props.code}
				onChange={this.onCodeChange}
				editorWillMount={this.editorWillMount}
				editorDidMount={this.editorDidMount}
				// editorDidMount={this.editorDidMount4Python}
			/>
		);
	};

	onCodeChange = (newValue, e) => {
		this.props.onCodeChange(newValue, e);
	};

	editorDidMount = (editor) => {
		this.props.setStopDraggingEvent(() => {
			editor.layout();
		});

		this.setState({
			monacoeditor: editor,
		});
	};

	editorWillMount = (monacoInstance) => {
		this.monaco = monacoInstance;
		// this.monaco.editor.defineTheme("codingbarDarkMonacoTheme",codingbarDarkMonacoTheme)
		this.monaco.editor.defineTheme('codingbarMonacoTheme', codingbarMonacoTheme);
		const { language } = this.props;
		if (language === EXERCISE_EDITOR_LANGUAGES.C.value || language === EXERCISE_EDITOR_LANGUAGES.CPP.value) {
			monacoInstance.languages.register({
				id: 'C',
				extensions: ['.c'],
				aliases: ['C', 'c'],
			});
		} else if (language === EXERCISE_EDITOR_LANGUAGES.JAVA.value) {
			monacoInstance.languages.register({
				id: 'java',
				extensions: ['.java'],
				aliases: ['JAVA', 'Java'],
			});
		} else if (language === EXERCISE_EDITOR_LANGUAGES.LUA.value) {
			monacoInstance.languages.register({
				id: 'lua',
				extensions: ['.lua'],
				aliases: ['LUA', 'Lua'],
			});
		} else {
			//python default
			monacoInstance.languages.register({
				id: 'python',
				extensions: ['.py'],
				aliases: ['PYTHON', 'Python'],
			});
		}
	};

	render() {
		return <div className="playground-editor">{this.getEditor()}</div>;
	}
}

export default ExerciseEditor;
