import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { translate} from 'react-i18next';
@translate('translations')
export default class CountDownDay extends React.PureComponent {
	static propTypes = {
		timeUnit: PropTypes.oneOf(['minutes']),
	};
	
	constructor(props) {
		super(props);

		this.state = {
			initialValue: this.props.initialValue ? this.props.initialValue : 0,
			currentValue: this.props.initialValue ? this.props.initialValue : 0,
			time: {},
		}

		// used as starting point
		this.timer = 0;

		// bind function to use component state
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
	}

	componentDidMount() {
		// set initial display

		let timeLeftVar = this.secondsToTime(this.state.initialValue);
		this.setState({ time: timeLeftVar });

		// start timer
		this.startTimer()
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	componentWillReceiveProps(props, nextProps) {
		// clear and resetComment timer when switching between dropdown
		if (this.timer && props.isTimerPaused) {
			clearInterval(this.timer);
		}
		if (this.timer) {
			clearInterval(this.timer);
			this.setState({
				time: {
					"dd": '00',
					"hh": '00',
					"mm": '00',
					"ss": '00',
				}
			})
		}

		if (props.initialValue && props.initialValue != 0) {
			let seconds = props.initialValue ? props.initialValue : 0
			this.setState({
				initialValue: seconds,
				currentValue: seconds,
			}, () => {
				this.countDown()
				this.timer = setInterval(this.countDown, 1000);
			})
		}
	}

	startTimer() {
		if (this.timer == 0 && this.state.initialValue > 0) {
			this.timer = setInterval(this.countDown, 1000);
		}
	}

	countDown() {
		// 勿動順序
		// 1. Check if timer is at zero. 
		if (this.state.currentValue == 0 || this.state.currentValue < 0) {
			clearInterval(this.timer);
			if (this.timer) {
				if (this.props.actionWhenTimerEnds) {
					this.props.actionWhenTimerEnds();
					return;
				}
			}
		}
		// 2. Remove one second, set state so a re-render happens.
		const seconds = this.state.currentValue - 1;
		this.setState({
			time: this.secondsToTime(seconds),
			currentValue: parseInt((this.props.endTime - Date.now()) / 1000),
		});
	}

	formatTwoDigit(num) {
		if (num.toString().length == 1){
			return '0' + num.toString()
		} else {
			return num.toString()
		}
	}

	secondsToTime(secs){
		let days = Math.floor(secs / (24 * 60 * 60));
		let divisor_for_hours = secs % (24 * 60 * 60);

		let hours = Math.floor(divisor_for_hours / (60 * 60));

		let divisor_for_minutes = divisor_for_hours % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		let obj = {
			"dd": days == 0 || days < 0 ? '00' : hours == 24 ? this.formatTwoDigit(days + 1) : this.formatTwoDigit(days),
			"hh": hours == 0 || hours < 0 ? '00' : minutes == 60 ? this.formatTwoDigit(hours + 1) : this.formatTwoDigit(hours),
			"mm": minutes == 0 || minutes == 60 || minutes < 0 ? '00' : seconds == 60 ? this.formatTwoDigit(minutes + 1) : this.formatTwoDigit(minutes),
			"ss": seconds == 0 || seconds == 60 || seconds < 0 ? '00' : this.formatTwoDigit(seconds),
		};

		return obj;
	}

	renderText = () => {
		const { timeUnit, isAllText, t } = this.props;
		if (timeUnit === 'minutes') {
			if (isAllText) {
				return `${this.state.time.mm} ${t('common.minutes')} ${this.state.time.ss} ${t('common.seconds')}`
			}
			return `${this.state.time.mm}:${this.state.time.ss}`;

		}
		if (isAllText) {
			return `${this.state.time.dd} ${t('staff.day')} ${this.state.time.hh} ${t('common.hours')} ${this.state.time.mm} ${t('common.minutes')} ${this.state.time.ss} ${t('common.seconds')}`
		}
		return `${this.state.time.dd}:${this.state.time.hh}:${this.state.time.mm}:${this.state.time.ss}`;
	}

	render() {
		const { customClass, isAllText, t } = this.props;

		return (
			<div className={cx("timer-count-down", customClass )}>
				<div className="change-time-zone">
					{this.renderText()}
				</div>
			</div>
		)
	}
}
