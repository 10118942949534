import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initAction } from 'redux/reducers/app';
import { ActionModal } from 'components';
import DisconnectNet from 'components/DisconnectNet/DisconnectNet';

import ErrorHandler from '../ErrorHandler';
import AnnouncementModal from './AnnouncementModal/AnnouncementModal';
import '../App.scss';

@connect(
	(state) => {
		const app = state.app;
		return {
			user: app.get('user'),
			announcements: app.get('announcements'),
		};
	},
	{
		initAction,
	}
)
class BodyContent extends React.Component {
	constructor(props) {
		super(props);
	}

	renderVersionBox() {
		const confirmMessage = (
			<div>
				<div>您使用版本({this.props.desktopVersion})太舊，目前最新的版本是 v1.6</div>
				<div>請下載最新的 PC 版 CodingBar，得到最好的支援!</div>
				下載網址:{' '}
				<a href={'https://app.codingbar.ai/desktop/select/1.6/mac/pm'} target={'_blank'}>
					https://app.codingbar.ai/desktop/select/1.6/mac/pm
				</a>
			</div>
		);
		return (
			<ActionModal
				type='confirmation'
				isOpen={this.props.showVersionUpgrade}
				onConfirm={this.closeVersionCheckBox}
				isShowClose={false}
				isHideCancelBtn
				confirmButtonText={'繼續'}
				modalTitle={'版本更新'}
				modalBody={confirmMessage}
			/>
		);
	}

	closeVersionCheckBox = () => {
		this.props.closeVersionCheckBox();
	};

	renderErrorHandler() {
		if (!this.props.showError || !this.props.bug) {
			return;
		}

		return (
			<div>
				<ErrorHandler bug={this.props.bug} close={this.closeErrorHandler.bind(this)} />
			</div>
		);
	}

	closeErrorHandler() {
		this.props.closeErrorHandler();
	}

	renderAnnouncementModal() {
		const { announcements } = this.props;

		const location = this.props.location;
		if (location && location.pathname.includes('AImate')) return; //AImate 不用被發送公告

		if (announcements === undefined || announcements.size === 0) return;

		// Show Announcement Order: agreement -> announcement -> alert
		const orderNum = (type) => {
			switch (type) {
				case 'agreement':
					return 3;
				case 'announcement':
					return 2;
				case 'alert':
					return 1;
			}
		};

		const sortedAncms = announcements.sort((a, b) => orderNum(a.get('type')) - orderNum(b.get('type')));

		const modal = sortedAncms.map((ancm) => {
			return <AnnouncementModal key={ancm.get('id')} userRole={this.props.userRole} announcement={ancm} />;
		});

		return modal;
	}

	render() {
		return (
			<div>
				{this.renderVersionBox()}
				{this.renderErrorHandler()}
				{this.props.loggedIn && this.renderAnnouncementModal()}

				{!this.props.onlineStatus && <DisconnectNet />}
			</div>
		);
	}
}

export default withRouter(BodyContent);
