import * as cons from '../constants/exerciseSubmitResult';

export function getInitialState() {
	return {
		submitType: 'exec',
		codeStatus: undefined,
		isShowExerciseSubmitResult: false,
		currentStep: undefined,
	}
}

export default function reducer(state = getInitialState(), action = {}) {
	const { type, payload } = action;
	
	switch (type) {
		case cons.SET_INIT_STATE: {
			return getInitialState()
		}
		
		case cons.SET_EXERCISE_SUBMIT_TYPE: {
			return {
				...state,
				submitType: payload,
			}
		}
		
		case cons.SET_EXERCISE_SUBMIT_RESULT: {
			return {
				...state,
				codeStatus: payload,
			}
		}
		
		case cons.SET_TOGGLE_SHOW_SUBMIT_RESULT: {
			return {
				...state,
				isShowExerciseSubmitResult: payload !== undefined ? payload : !state.isShowExerciseSubmitResult,
			}
		}
		
		case cons.SET_CURRENT_STEP: {
			return {
				...state,
				currentStep: payload,
			}
		}
		
		default:
			return state;
	}
};

export function setInitState() {
	return {
		type: cons.SET_INIT_STATE,
	}
}

export function setExerciseSubmitType(submitType) {
	return {
		type: cons.SET_EXERCISE_SUBMIT_TYPE,
		payload: submitType,
	}
}

export function setExerciseSubmitResult(codeStatus) {
	return {
		type: cons.SET_EXERCISE_SUBMIT_RESULT,
		payload: codeStatus,
	}
}

export function setToggleShowSubmitResult(isShow) {
	return {
		type: cons.SET_TOGGLE_SHOW_SUBMIT_RESULT,
		payload: isShow !== undefined ? isShow : undefined,
	}
}

export function setCurrentStep(stepObj) {
	return {
		type: cons.SET_CURRENT_STEP,
		payload: stepObj,
	}
}