import * as cons from '../constants/registrationManageNew';
import { codingbarApi } from 'codingbar-api';
import isUndefined from 'lodash-es/isUndefined';
import { API_URL } from '../../settings';
import { generateRandomCode } from '../../shared/util/utils';
import { checkIfFormCanEnabled, GRADE_LIST, PAY_METHOD_INSTALLMENT_OPTION, PAY_METHOD_ALLOW_INSTALLMENT } from '../../shared/util/registrationUtils';
import { FORM_ATTRIBUTE_CBA_GROUP, FORM_ATTRIBUTE_CBA, CBA_OPTION_LIST } from "redux/constants/registrationManageNew"

import { ParentSignPay } from '../constants/registrationEmailTemplate';

export function getInitialState() {
	return {
		allClassesFromDB: [],
		allGoodsFromDB: [],
		allInfosFromDB: [],
		allGradesFromDB: GRADE_LIST,

		isEdit: false,  // new form or edit old form
		isLoading: false,    // fetching data, posting data
		savedStatus: "",    // post data status
		step: 1,

		enabled: false,

		// step 1
		formId: generateRandomCode(),
		formAttributeSelected: 'single',
		payMethodSelected: "all",
		groupOptionList: [],
		payInstallmentList: PAY_METHOD_INSTALLMENT_OPTION.map(option => { return { ...option, checked: false } }),
		title: "",
		subtitle: "",
		description: "",
		isOldMemberDiscount: true,
		manualPrice: '',

		// step 2
		mainCourse: { generalId: "", classes: [] },
		subCourses: [
			// { generalId, courseClassName, classes }
		],

		// step 3
		goods: {
			// "NPAG": {"goodId1": true, "goodId2": false, ... },
		},
		enableInfoFrom: true,
		infoOptions: {},
		enableGradeSelect: true,
		gradeOptions: {},
		enableTaxNumber: false,
		enableVegan: false,
		enableBirthdayAndIdentityCardNumber: true,
		enableOthers: false,
		othersOptions: ["自行駕車，需要停車證"],
		enableCouponCode: true,

		// step 4
		emailPaymentTitle: ParentSignPay.subject.x2,
		emailPaymentContent: ParentSignPay.templates.x2,
		emailSimplePaymentContent: ParentSignPay.templates.x2_simple,
		emailNotPaidTitle: ParentSignPay.subject.x1,
		emailNotPaidContent: ParentSignPay.templates.x1,
		emailNoFeeContent: ParentSignPay.templates.x1_noFee,
		emailSimpleNotPaidContent: ParentSignPay.templates.x1_simple,
		emailCBANotPaidContent: ParentSignPay.templates.x1_cba,
		emailCBANoFeeContent: ParentSignPay.templates.x1_cba_noFee,
		emailWaitingListTitle: ParentSignPay.subject.x6,
		emailWaitingListContent: ParentSignPay.templates.x6,
		emailSimpleWaitingListContent: ParentSignPay.templates.x6_simple,
		policyUrl: "https://codingbar.ai/service_term/index.html",

		// step 5
		GlobalGACodeList: ["AW-784787163"],
		GACodeList: ["AW-784787163/Y-BeCP-f3bIBENvNm_YC"],
		GlobalFACodeList: ["1950066075061967"],
		FACodeList: ["1950066075061967"],
	};
}

export default function reducer(state = getInitialState(), action = {}) {
	const { type, payload } = action
	switch (type) {
		case cons.REGISTRATION_SETTING_INIT: {
			return {
				...getInitialState(),
				allClassesFromDB: state.allClassesFromDB,
				allGoodsFromDB: state.allGoodsFromDB,
				allInfosFromDB: state.allInfosFromDB,
				allGradesFromDB: state.allGradesFromDB,
			}
		}

		case cons.SET_ALL_CLASSES_FROM_DB: {
			return {
				...state,
				allClassesFromDB: payload.allClassesFromDB
			}
		}

		case cons.SET_ALL_GOODS_FROM_DB: {
			return {
				...state,
				allGoodsFromDB: payload.allGoodsFromDB
			}
		}

		case cons.SET_ALL_INFOS_FROM_DB: {
			return {
				...state,
				allInfosFromDB: payload.allInfosFromDB
			}
		}

		case cons.EDIT_EXISTED_REGISTRATION: {
			let {
				enabled, formId,
				formAttributeSelected, payMethodSelected, groupOptionList, installmentPeriods, title, subtitle, description, isOldMemberDiscount, manualPrice,
				mainCourse, subCourses, goods,
				enableInfoFrom, infoOptions = state.infoOptions, enableGradeSelect, gradeOptions = state.gradeOptions,
				enableTaxNumber, enableVegan, enableBirthdayAndIdentityCardNumber,
				enableOthers, othersOptions,
				enableCouponCode,
				emailPaymentTitle, emailPaymentContent, emailSimplePaymentContent, emailNotPaidTitle, emailNotPaidContent, emailSimpleNotPaidContent, emailNoFeeContent, emailCBANotPaidContent, emailCBANoFeeContent, emailWaitingListTitle, emailWaitingListContent, emailSimpleWaitingListContent, policyUrl,
				GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
			} = payload
			return {
				...state,
				isEdit: true,
				enabled, formId,
				formAttributeSelected, payMethodSelected, groupOptionList: groupOptionList.filter(e => e.checked),
				payInstallmentList: PAY_METHOD_INSTALLMENT_OPTION.map(option => { return { ...option, checked: (installmentPeriods || []).includes(option.value) } }),
				title, subtitle, description, isOldMemberDiscount, 
				...(isUndefined(manualPrice) ? null : { manualPrice }),
				mainCourse, subCourses, goods,
				enableInfoFrom, infoOptions, enableGradeSelect, gradeOptions,
				enableTaxNumber, enableVegan, enableBirthdayAndIdentityCardNumber,
				enableOthers, othersOptions,
				enableCouponCode,
				emailPaymentTitle, emailPaymentContent, emailSimplePaymentContent, emailNotPaidTitle, emailNotPaidContent, emailSimpleNotPaidContent,
				...(emailNoFeeContent ? { emailNoFeeContent } : null),
				...(emailCBANotPaidContent ? { emailCBANotPaidContent } : null),
				...(emailCBANoFeeContent ? { emailCBANoFeeContent } : null),
				emailWaitingListTitle, emailWaitingListContent, emailSimpleWaitingListContent, policyUrl,
				GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
			}
		}

		case cons.SET_REDUCER: {
			const newState = { ...state, ...payload }
			const enabled = newState.enabled && Boolean(checkIfFormCanEnabled(newState))
			const goods = updateGoods(newState.goods, newState.mainCourse, newState.subCourses)

			// 當更改報名表屬性為CBA, 在「其他設定」 放入 default 值 && 課程設定只有一門課
			if (payload.formAttributeSelected !== state.formAttributeSelected && CBA_OPTION_LIST.includes(payload.formAttributeSelected)) {
				const cbaGoods = Object.keys(goods).reduce((acc,cur)=> ({...acc, [cur]: {}}),{})
				const classes = state.mainCourse.classes.filter((classItem, index) => index === 0);
				const cbaOtherSettingsState = {
					// placeClass
					mainCourse: { ...state.mainCourse, classes },
					
					// other settings
					goods: cbaGoods,
					enableInfoFrom: false,
					infoOptions: {},
					enableGradeSelect: false,
					gradeOptions: {},
					enableTaxNumber: true,
					enableVegan: false,
					enableBirthdayAndIdentityCardNumber: false,
					enableOthers: false,
					othersOptions: [
						"自行駕車，需要停車證"
					],
					enableCouponCode: true,
				};
				return {
					...newState,
					...cbaOtherSettingsState,
					enabled,
				};

			}
			return {
				...newState,
				enabled,
				goods,
			};
		}

		case cons.GO_PREV_STEP: {
			return {
				...state,
				step: state.step - 1
			}
		}

		case cons.GO_STEP: {
			return {
				...state,
				step: payload.step
			}
		}

		case cons.GO_NEXT_STEP: {
			return { ...state, step: (state.step + 1) }
		}

		case cons.SET_LOADING: {
			let { isLoading } = payload
			return { ...state, isLoading }
		}

		case cons.SAVED_DONE: {
			let { savedStatus } = payload
			return { ...state, savedStatus, isLoading: false }
		}

		default:
			return state;
	}
}

export function initRegistrationManage() {
	return { type: cons.REGISTRATION_SETTING_INIT }
}
export function setAllClassesFromDB() {
	return (dispatch, getState) => {
		codingbarApi.getRegistrationService().getAllClasses().then(data => {
			dispatch({
				type: cons.SET_ALL_CLASSES_FROM_DB,
				payload: { allClassesFromDB: data }
			})
		})
	}
}
export function setAllGoodsFromDB() {
	return (dispatch, getState) => {
		codingbarApi.getRegistrationService().getAllGoods().then(data => {
			dispatch({
				type: cons.SET_ALL_GOODS_FROM_DB,
				payload: { allGoodsFromDB: data }
			})
		})
	}
}
export function setAllInfosFromDB() {
	return (dispatch, getState) => {
		codingbarApi.getRegistrationService().getAllInformationSources().then(data => {
			const allInfosFromDB = data.map(item => {
				const { informationId, ...others } = item
				return { id: informationId, ...others }
			})
			dispatch({
				type: cons.SET_ALL_INFOS_FROM_DB,
				payload: { allInfosFromDB: allInfosFromDB }
			})
		})
	}
}

export function editExistedRegistration(data) {
	return {
		type: cons.EDIT_EXISTED_REGISTRATION,
		payload: data
	}
}

export function setReducer(data) {
	return {
		type: cons.SET_REDUCER,
		payload: data
	}
}

export function goNextStep() {
	return {
		type: cons.GO_NEXT_STEP
	}
}
export function goSpecificStep(step) {
	return {
		type: cons.GO_STEP,
		payload: { step }
	}
}


function updateGoods(oldGoods, mainCourse, subCourses) {
	let courseIdList = subCourses.map(subCourseItem => subCourseItem.generalId)
	courseIdList.push(mainCourse.generalId)

	const newGoods = {}
	courseIdList.forEach((courseId) => {
		if (courseId) newGoods[courseId] = oldGoods[courseId] || {}
	})
	return newGoods
}

export function saveToDatabase() {
	return (dispatch, getState) => {
		const { registrationManageNew } = getState();
		const {
			isEdit,
			enabled, formId,
			formAttributeSelected, payMethodSelected, groupOptionList, payInstallmentList, title, subtitle, description, isOldMemberDiscount, manualPrice,
			mainCourse, subCourses, goods,
			enableInfoFrom, infoOptions, enableGradeSelect, gradeOptions,
			enableTaxNumber, enableVegan, enableBirthdayAndIdentityCardNumber,
			enableOthers, othersOptions,
			enableCouponCode,
			emailPaymentTitle, emailPaymentContent, emailSimplePaymentContent, emailNotPaidTitle, emailNotPaidContent, emailSimpleNotPaidContent, emailNoFeeContent, emailCBANotPaidContent, emailCBANoFeeContent, emailWaitingListTitle, emailWaitingListContent, emailSimpleWaitingListContent, policyUrl,
			GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
		} = registrationManageNew
		
		if (!enabled) {
			if (!confirm("此表單目前為不開放狀態，請確認此為正確設定？")) return
		}

		const href = `${API_URL.HOST}/registration/new/course/${formId}`;

		const groupOptionListToSave = groupOptionList.filter((o) => o.checked);

		if (!Array.isArray(GlobalGACodeList) || GlobalGACodeList.length === 0 ||
			!Array.isArray(GACodeList) || GACodeList.length === 0 ||
			!Array.isArray(GlobalFACodeList) || GlobalFACodeList.length === 0 ||
			!Array.isArray(FACodeList) || FACodeList.length === 0
		) {
			alert("GA/FB code 沒有內容, 請回去更新!");
			return;
		}

		let payItemLabel = "實體"; //db key value, do not i18n

		if (["j", "k", "g", "s", "m"].includes(formId[3].toLowerCase())) {
			payItemLabel = "線上";
		}

		// save data 簡易報名表的信件模板不會顯示在設定回覆，但還是存進報名表設定裡
		const data = {
			version: "2.1",
			versionDate: "20210119",
			enabled, formId, href,
			formAttributeSelected, payMethodSelected,
			groupOptionList: groupOptionListToSave,
			isInstallment: PAY_METHOD_ALLOW_INSTALLMENT.includes(payMethodSelected) ? payInstallmentList.some(el => el.checked === true) : false,
			installmentPeriods: PAY_METHOD_ALLOW_INSTALLMENT.includes(payMethodSelected) ? payInstallmentList.reduce((acc, el) => el.checked === true ? [...acc, el.value] : acc, []) : [],
			title, subtitle, description, isOldMemberDiscount,
			manualPrice,
			mainCourse, subCourses, goods,
			enableInfoFrom, infoOptions, enableGradeSelect, gradeOptions,
			enableTaxNumber, enableVegan, enableBirthdayAndIdentityCardNumber,
			enableOthers, othersOptions,
			enableCouponCode,
			emailPaymentTitle,
			emailPaymentContent,
			emailSimplePaymentContent,
			emailNotPaidTitle,
			emailNotPaidContent,
			emailSimpleNotPaidContent,
			emailNoFeeContent,
			emailCBANotPaidContent,
			emailCBANoFeeContent,
			emailWaitingListTitle,
			emailWaitingListContent,
			emailSimpleWaitingListContent,
			policyUrl,
			payItemLabel,
			GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
		}
		
		console.log("registrationManage data", data, "isEdit", isEdit)

		dispatch({
			type: cons.SET_LOADING,
			payload: { isLoading: true }
		})

		if (!isEdit) {
			codingbarApi.getRegistrationService().createRegistrationForm(data).then(result => {
				dispatch({
					type: cons.SAVED_DONE,
					payload: { savedStatus: result.status }
				})
			})
		}
		else {
			codingbarApi.getRegistrationService().updateRegistrationForm(data).then(result => {
				console.log("result", result)
				dispatch({
					type: cons.SAVED_DONE,
					payload: { savedStatus: result.status }
				})
			})
		}
	};
}

