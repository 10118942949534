import { takeEvery, select, call, put, spawn } from 'redux-saga/effects';
import { codingbarApi } from 'codingbar-api';

import * as cons from '../constants/course';

export function* watchGetCourse() {
  yield takeEvery(cons.SAGA_GET_COURSE, function* ({ payload = {} }) {
    try {

      const courseReducer = yield select(state => state.course);
      const nowCourse = courseReducer.toJSON().course || {}


      if (nowCourse.courseId !== payload.courseId) {
        yield put({ type: cons.GET_COURSE_CLEAR });
      }

      const courseService = codingbarApi.getCourseService();
      yield put({ type: cons.GET_COURSE });
      const courseId = payload.courseId;
      const isGlobalTest = payload.isGlobalTest
      const course = yield call([courseService, 'getCourse'], courseId, isGlobalTest);

      yield put({ type: cons.GET_COURSE_SUC, course });
    } catch (error) {
      yield put({ type: cons.GET_COURSE_ERR, error });
      throw error;
    }
  });
}

export default [
  watchGetCourse,
];
