import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import { ReactComponent as FallbackIcon } from '../../../design/images/icons/icon-course.svg';
import { createStyles, withStyles } from '@material-ui/core';

const styles = createStyles({
    span: {

    },
    svg: {
        verticalAlign: 'middle'
    }
})

class Icon extends PureComponent {

    static propsTypes = {
        name: PropTypes.string.isRequired,      // icon name, name={course} => 'icon-course.svg'
        size: PropTypes.number.isRequired,      // icon size, number, default 32px

        className: PropTypes.string,            // className of icon's span wrapper
        imgClassName: PropTypes.string,         // className of icon's image

        color: PropTypes.string,                // icon's color, e.g. "red", "#123456"
        fill: PropTypes.string,                // icon's fill color, e.g. "red", "#123456"
        stroke: PropTypes.string,                // icon's stroke color, e.g. "red", "#123456"
        width: PropTypes.number,                // icon width, will override props.size if props.width is specified
        height: PropTypes.number,                // icon height, will override props.size if props.height is specified
    };

    static defaultProps = {
        size: 32,
        color: undefined,
        fill: undefined,
        stroke: undefined
    }

    getIconComponent = (iconName) => {
        try {
            return require(`../../../design/images/icons/icon-${iconName}.svg`).ReactComponent
        }
        catch (e) {
            return FallbackIcon
        }
    }

    render() {
        const { name, size, width, height, color, fill, stroke, classes } = this.props
        const spanClassName = cx(classes.span, this.props.className)
        const imgClassName = cx(classes.svg, this.props.imgClassName)
        const SvgIcon = this.getIconComponent(name)

        let iconProps = {
            className: imgClassName,
            width: width || size,
            height: height || size
        }
        if (color && color !== "default") iconProps.color = color
        if (stroke && stroke !== "default") iconProps.stroke = stroke
        if (fill && fill !== "default") iconProps.fill = fill

        return (
            <span className={spanClassName}>
                <SvgIcon {...iconProps} />
            </span>
        )
    }
}

export default withStyles(styles)(Icon)
