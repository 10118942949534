import { FORM_ATTRIBUTE_CBA, FORM_ATTRIBUTE_CBA_GROUP, FORM_ATTRIBUTE_SINGLE, FORM_ATTRIBUTE_SINGLE_PAY_GROUP } from 'redux/constants/registrationManageNew';
import { isEmpty } from 'lodash-es';

export function checkIfFormCanEnabled(formData) {
	const {
		formId,
		title,
		// subtitle,
		description,
		priceMethodSelected,
		manualPrice,
		mainCourse,
		subCourses,
		emailPaymentTitle,
		emailPaymentContent,
		emailNotPaidTitle,
		emailNotPaidContent,
		formAttributeSelected,
		groupOptionList,
	} = formData;

	if (!title || !formId || !description) return false; // 檢查報名表標題及報名表ID非空
	if (priceMethodSelected === 'manualPrice' && manualPrice === '') return false;
	if (!mainCourse.generalId) return false; // 檢查主要課程有選取
	if (!mainCourse.classes || !mainCourse.classes.length) return false;
	for (const subCourse of subCourses) if (!subCourse.classes || !subCourse.classes.length) return false;
	if (!emailPaymentTitle || !emailPaymentContent || !emailNotPaidTitle || !emailNotPaidContent) return false; // 檢查回覆信內容都非空
	if (formAttributeSelected === 'group') { // 團報未設定人數或小於兩人
		if (groupOptionList.length < 1) return false;
	}
	// cba團報 & 一人付款團報，必須設定特殊價格
	if (formAttributeSelected === FORM_ATTRIBUTE_CBA_GROUP || formAttributeSelected === FORM_ATTRIBUTE_SINGLE_PAY_GROUP) {
		if (groupOptionList.length < 1 || !groupOptionList.every((o) => o.checked ? o.manualPrice : true)) return false;
	}
	return true;
}

export const FORM_ATTRIBUTE_OPTION_LIST = [
	{ value: FORM_ATTRIBUTE_SINGLE, label: '單人' },
	{ value: FORM_ATTRIBUTE_CBA, label: 'CBA', disabled: true },
	{ value: FORM_ATTRIBUTE_CBA_GROUP, label: 'CBA團報', disabled: true },
	{ value: FORM_ATTRIBUTE_SINGLE_PAY_GROUP, label: '團報', disabled: true },
	{ value: 'group', label: '舊團報', disabled: true },
	{ value: 'normal', label: '一般', disabled: true },
	{ value: 'simple', label: '單人簡易版', disabled: true },
	{ value: 'seminar', label: '活動講座', disabled: true },
	{ value: 'trial', label: '試聽', disabled: true },
	{ value: 'manual', label: '手動', disabled: true },
];

export const PAY_METHOD_OPTION_LIST = [
	{ value: 'all', label: '全開放' },
	{ value: 'atm', label: '僅ATM' },
	{ value: 'creditAtm', label: '僅信用卡與ATM' },
	{ value: 'creditMerchant', label: '僅信用卡與超商代碼' },
];
export const PAY_METHOD_ALLOW_INSTALLMENT = ['all', 'creditAtm', 'creditMerchant'];
export const PAY_METHOD_INSTALLMENT_OPTION = [
	{ value: 3, label: '三期' },
	{ value: 6, label: '六期' },
];

export const getClassCurrentPrice = function getClassCurrentPrice(classItem) {
	const { singlePriceSettings = [], finalPrice, originalPrice } = classItem;
	const dateNow = Date.now();

	let currentPrice;
	try {
		currentPrice = (singlePriceSettings || [])
			.filter((priceData = {}) => { // 客服有可能先填時間期限但不填金額, 這種的不用 filter 出來, 一定要確保有金額才撈
				return ((!isEmpty(priceData) && !isNaN(priceData.payAmount) && priceData.payAmount !== null) && priceData.endTimeStamp > dateNow);
			})
			.sort((priceA = {}, priceB = {}) => {
				return priceA.payAmount - priceB.payAmount;
			})[0].payAmount;
	} catch (error) {
		currentPrice = finalPrice;
		if (isNaN(currentPrice) || currentPrice == null) currentPrice = originalPrice;
	}

	
	return currentPrice;
};

export const checkCouponCodeValid = function checkCouponCodeValid(courseId, classId, couponCodeEntered) {
	return new Promise((resolve) => {
		if (!couponCodeEntered) return resolve({ isValid: false, message: '請輸入折扣碼' });

		codingbarApi
			.getRegistrationService()
			.checkCoupon('couponCode', classId, couponCodeEntered, courseId)
			.then((result) => {
				if (result.status === 'success') {
					// console.log("result1", result)
					resolve({
						isValid: true,
						couponCodeDiscount: result.coupon.disCountPrice,
						couponRemark: result.coupon.remark,
						couponTarget: result.coupon.target,
					});
				} else {
					let message;
					if (result.err.errorCode === 'couponNotExistError') message = '折扣碼錯誤。';
					else if (result.err.errorCode === 'couponReachMaxCountError') message = '折扣碼使用次數已達上限！';
					else message = result.err.message;
					resolve({
						isValid: false,
						message,
					});
				}
			});
	});
};

export const checkGroupCodeValid = function checkGroupCodeValid(groupCode) {
	return new Promise((resolve) => {
		// codingbarApi.getRegistrationService().checkCoupon("groupDiscountCode", formId, groupCode).then(result => {
		codingbarApi
			.getRegistrationService()
			.checkCoupon('groupDiscountCode', '', groupCode)
			.then((result) => {
				if (result.status === 'success') {
					const { groupCount = 0, usedCount = 0 } = result.coupon;

					if (groupCount - usedCount <= 0) {
						resolve({
							isValid: false,
							message: '驗證碼使用人數已達上限！',
						});
					} else {
						resolve({
							isValid: true,
							groupCount,
						});
					}
				} else {
					resolve({
						isValid: false,
						message: '驗證碼無效。',
					});
				}
			});
	});
};

export const GRADE_LIST = [
	{ id: 0, name: '三年級' },
	{ id: 1, name: '四年級' },
	{ id: 2, name: '五年級' },
	{ id: 3, name: '六年級' },
	{ id: 4, name: '七年級' },
	{ id: 5, name: '八年級' },
	{ id: 6, name: '九年級' },
	{ id: 7, name: '高一' },
	{ id: 8, name: '高二' },
	{ id: 9, name: '高三' },
	{ id: 10, name: '大學' },
	{ id: 11, name: '社會人士' },
	{ id: 12, name: '其他 (請填寫於備註欄)' },
];

function getClassPriceTheta(coursePrice) {
	if (coursePrice >= 7000) return 400;
	else if (coursePrice >= 4500) return 300;
	else if (coursePrice >= 2000) return 200;
	else return 0;
}

function getCoursePriceData(courseItem, courseSetting, allGoods) {
	// console.log("courseItem", courseItem)
	// console.log("courseSetting", courseSetting)

	const classSelectedId = courseItem.classSelectedId;
	const coursePrice = getClassCurrentPrice(
		courseSetting.classes.find((classItem) => classItem.classId === classSelectedId)
	);
	const courseTheta = getClassPriceTheta(coursePrice);

	const courseGoodsSelectedList = courseItem.goodsSelectedList.map((goodsSelectId) =>
		allGoods.find((goodItem) => goodItem.goodId === goodsSelectId)
	);
	let totalPrice = coursePrice;
	for (const goodItem of courseGoodsSelectedList) totalPrice += parseInt(goodItem.payAmount);

	return { coursePrice, courseTheta, courseGoodsSelectedList, totalPrice };
}

function getDiscountData(userData, settingData, mainCourseCalculatedData, subCourseCalculatedDataList) {
	let couponDiscount = 0;
	let groupDiscount = 0;
	let oldMemberDiscount = 0;
	let subCourseDiscount = 0;

	if (settingData.formAttributeSelected === 'group') {
		groupDiscount = (userData.groupCountSelected - 1) * mainCourseCalculatedData.courseTheta;
	} else if (userData.subCoursesSelected.filter((subCourse) => subCourse && subCourse.classSelectedId).length > 0) {
		const n = userData.subCoursesSelected.filter((subCourse) => subCourse && subCourse.classSelectedId).length;
		subCourseDiscount += n * mainCourseCalculatedData.courseTheta;
		for (const subCourseCalculatedData of subCourseCalculatedDataList)
			subCourseDiscount += n * subCourseCalculatedData.courseTheta;
	} else if (userData.useOldMember && userData.useOldStudent && userData.oldStudentEmail) {
		oldMemberDiscount = 2 * mainCourseCalculatedData.courseTheta;
	}

	if (userData.useCoupon && userData.couponLegal && userData.couponCodeDiscount)
		couponDiscount = userData.couponCodeDiscount;

	return { couponDiscount, groupDiscount, oldMemberDiscount, subCourseDiscount };
}

export const calculateRegistrationPrice = function calculateRegistrationPrice(userData, settingData) {
	if (!userData.classSelectedId) return { finalPrice: 0 }; // 若根本還沒填完主課程，則根本還不用開始計算

	// console.groupCollapsed("calculateRegistrationPrice")
	// console.log("calculateRegistrationPrice", "userData", userData, "settingData", settingData)

	const mainCourseItem = { classSelectedId: userData.classSelectedId, goodsSelectedList: userData.goodsSelectedList }; // 將主課程整理成一個data structure
	const mainCourseCalculatedData = getCoursePriceData(mainCourseItem, settingData.mainCourse, userData.allGoodsFromDB); // 獲取主課程的課程價錢、theta、加購項目、總價格
	// console.log("mainCourseCalculatedData", mainCourseCalculatedData)

	const subCourseCalculatedDataList = userData.subCoursesSelected
		.filter((subCourseSelectItem) => {
			return subCourseSelectItem.classSelectedId != ''; // 若這個副課程沒有選取class，則不用計算價錢
		})
		.map((subCourseSelectItem) => {
			const subCourseSetting = settingData.subCourses.find(
				(subCourseItem) => subCourseItem.generalId === subCourseSelectItem.courseGeneralId
			); // 獲取副課程的settings
			const subCourseCalculatedData = getCoursePriceData(
				subCourseSelectItem,
				subCourseSetting,
				userData.allGoodsFromDB
			); // 獲取該副課程的課程價錢、theta、加購項目、總價格
			// console.log("subCourseCalculatedData", subCourseCalculatedData)
			return subCourseCalculatedData;
		});

	const discountData = getDiscountData(userData, settingData, mainCourseCalculatedData, subCourseCalculatedDataList); // 獲取折扣資料
	const { couponDiscount, groupDiscount, oldMemberDiscount, subCourseDiscount } = discountData;

	let finalPrice = 0; // 最終總價  = 主課程價錢 + 所有副課程價錢 - 所有折扣
	finalPrice += mainCourseCalculatedData.totalPrice;
	for (const subCourseCalculatedData of subCourseCalculatedDataList) finalPrice += subCourseCalculatedData.totalPrice;
	finalPrice -= couponDiscount + groupDiscount + oldMemberDiscount + subCourseDiscount;

	// console.log("groupDiscount", groupDiscount, "oldMemberDiscount", oldMemberDiscount, "subCourseDiscount", subCourseDiscount, "finalPrice", finalPrice)
	// console.groupEnd()

	return {
		mainCourseCalculatedData,
		subCourseCalculatedDataList,
		couponDiscount,
		groupDiscount,
		oldMemberDiscount,
		subCourseDiscount,
		finalPrice,
	};
};
