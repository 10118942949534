import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import MenuItem from './MenuItem';

import './IconMenu.css';

class IconMenu extends React.PureComponent {
	static propTypes = {
		className: PropTypes.string,
		menuButtonClassName: PropTypes.string,
		menuClassName: PropTypes.string,
		iconName: PropTypes.string,
		menuPosition: PropTypes.string,
		onMenuStateChange: PropTypes.func,
		CustomButtonComponent: PropTypes.elementType,
	};

	static defaultProps = {
		className: '',
		menuButtonClassName: '',
		menuClassName: '',
		iconName: 'hamburger-menu',
		menuPosition: 'center',
	}

	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	openMenu = () => {
		this.setState({
			isOpen: true,
		});
		this.handleOnMenuStateChange(true);
	}

	closeMenu = () => {
		this.setState({
			isOpen: false,
		});
		this.handleOnMenuStateChange(false);
	}

	toggleMenu = (e) => {
		e.stopPropagation();
		this.state.isOpen ? this.closeMenu() : this.openMenu();
	}

	handleClickOutside = () => {
		this.closeMenu();
	};

	handleOnMenuStateChange = (isOpen) => {
		const onMenuStateChange = this.props.onMenuStateChange;
		if (onMenuStateChange) {
			onMenuStateChange(isOpen);
		}
	}

	listItems() {
		return React.Children.map(this.props.children, (child, index) => {
			if (!child) {
				return null;
			}

			// if uses <Fragment /> to wrap child
			if (typeof child.props.children !== 'string') {
				return React.Children.map(child.props.children, (propChild, propIdx) => {
					if (!propChild) return null
					return React.cloneElement(propChild, {
						closeMenu: this.closeMenu,
					});
				})
			}

			return React.cloneElement(child, {
				closeMenu: this.closeMenu,
			});
		});
	}

	render() {
		const { className, menuButtonClassName, menuClassName, iconName, menuPosition, CustomButtonComponent } = this.props;
		const { isOpen } = this.state;
		const menuCSS = classnames('icon-menu-list', `align-${menuPosition}`, { 'active': isOpen });

		return (
			<div className={`icon-menu-main ${className}`}>
				{CustomButtonComponent ? (
					<CustomButtonComponent onClick={this.toggleMenu} />
				) : (<button
					className={`icon-menu-main-btn icon icon-${iconName} ${menuButtonClassName}`}
					onClick={this.toggleMenu}
				/>)}
				<ul className={`${menuClassName} ${menuCSS}`}>
					{this.listItems()}
				</ul>
			</div>
		);
	}
}

const wrappedComponent = onClickOutside(IconMenu);

wrappedComponent.Item = MenuItem;

wrappedComponent.Divider = () => {
	return <div className="icon-menu-divider" />;
};

export default wrappedComponent;
