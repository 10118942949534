import * as cons from '../constants/parentPage';

export function getInitialState() {
  return {
    isParentLoggedIn: false,
    students: [],
    studentCoursesList: [],
    studentCourseReports: [],
    studentReportIndex: 0,
    LogginAccount: '',
  };
}

export default function reducer(state = getInitialState(), action = {}) {
  const { type, payload } = action;

  switch (type) {
    case cons.INIT:
      return getInitialState()

    case cons.LOGIN_SUCCESS: {
      return {
        ...state,
        isParentLoggedIn: true,
      }
    }
    case cons.SET_STUDENTS_OF_PARENT: {
      return {
        ...state,
        students: payload,
      }
    }
    case cons.SET_COURSES_OF_STUDENT: {
      return {
        ...state,
        studentCoursesList: payload,
      }
    }
    case cons.SET_INDEX_OF_REPORT: {
      return {
        ...state,
        studentReportIndex: payload,
      }
    }
    case cons.SET_COURSE_REPORT_OF_STUDENT: {
      return {
        ...state,
        studentCourseReports: [...state.studentCourseReports, payload],
      }   
    }
    case cons.SET_COURSE_OBJ_OF_STUDENT: {
      return {
        ...state,
        studentCoursesList: [...state.studentCoursesList, payload],
      }
    }
    case cons.SET_UPDATED_COURSE_OBJ_OF_STUDENT: {
      return {
        ...state,
        studentCoursesList: state.studentCoursesList.map(studentCourseObj => (studentCourseObj.studentId === action.payload.studentId) ? action.payload : studentCourseObj),
      }
    }

    case cons.SET_PARENT_LOGIN_ACCOUNT: {
        return {
          ...state,
          LogginAccount: payload,
        }
    }

    default:
      return state;
  }
}


export function setInitialState() {
  return {
    type: cons.INIT
  }
}

export function setParentLoginSuccess() {
  return {
    type: cons.LOGIN_SUCCESS
  }
}

export function setStudentsOfParent(students) {
  return {
    type: cons.SET_STUDENTS_OF_PARENT,
    payload: students,
  }
}

export function setCoursesOfStudents(students) {
  return {
    type: cons.SET_COURSES_OF_STUDENT,
    payload: students,
  }
}

export function setIndexOfStudentReport(studentIdx) {
  return {
    type: cons.SET_INDEX_OF_REPORT,
    payload: studentIdx,
  }
}

export function setStudentCourseReport(report) {
  return {
    type: cons.SET_COURSE_REPORT_OF_STUDENT,
    payload: report,
  }
}

export function setParentLoginAccount(password) {
  return {
    type: cons.SET_PARENT_LOGIN_ACCOUNT,
    payload: password,
  }
}
export function setStudentCourseObj(studentCourseObj) {
  return {
    type: cons.SET_COURSE_OBJ_OF_STUDENT,
    payload: studentCourseObj,
  }
}

export function setUpdatedStudentCourseObj(updatedStudentCourseObj) {
  return {
    type: cons.SET_UPDATED_COURSE_OBJ_OF_STUDENT,
    payload: updatedStudentCourseObj,
  }
}
