import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import app from './app';
import course from './course';
import excercise from './excercise';
import search from './search';
import notification from './notification';
import registration from './registration';
import registrationNew from './registrationNew';
import registrationManage from './registrationManage';
import registrationManageNew from './registrationManageNew';
import exerciseManage from './exerciseManage';
import exerciseSidebar from './exerciseSidebar'
import exerciseSubmitResult from './exerciseSubmitResult'
import parentPage from './parentPage'

export default () => {
  const appReducer = combineReducers({
    app,
    course,
    excercise,
    form: formReducer,
    search,
    notification,
    registration,
    registrationNew,
    registrationManage,
    registrationManageNew,
    exerciseManage,
    exerciseSidebar,
    exerciseSubmitResult,
    parentPage,
  });

  return (state, action) => {
    return appReducer(state, action);
  };
};
