import i18n from 'lang/i18n';
export const feedbackManageTitleOptions = [
	{
		value: 'unable to submit code',
		label: i18n.t('feedback.issue.unable.to.submit.code'),
	},
	{
		value: 'loading, no response',
		label: i18n.t('feedback.issue.loading.no.response'),
	},
	{
		value: 'unable to open courses units topics',
		label: i18n.t('feedback.issue.unable.to.open.courses.units.topics'),
	},
	{ value: 'others', label: i18n.t('feedback.issue.others') },
];
