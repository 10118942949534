import React, { Component } from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './ResizeAngle.scss';

export default class ResizeAngle extends Component {
	static propTypes = {
		// array, bool, func, number, object, string, node (anything that can be rendered)
		onResize: PropTypes.func,
		
		// style props
		dark: PropTypes.bool,
		light: PropTypes.bool,
	}
	
	static defaultProps = {
		dark: true,
	}

	render() {
		const { className, dark, light } = this.props;
		
		return (
			<Draggable
				onDrag={this.props.onResize}
				onStop={this.props.onStop}
			>
				<div className={cx("ResizeAngle", className, { dark }, { light })}>
					<div className="resize-angle-icon">
						<div className="icon-bar"></div>
						<div className="icon-bar"></div>
					</div>
				</div>
			</Draggable>
		)
	}
}