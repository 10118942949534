import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Ads tracking tool
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import i18n from './lang/i18n';
import route from './route';
import createStore from './redux/storeCreator';

import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../design/postcss/general.scss';

import moment from 'moment';
moment.locale('zh-TW');

const store = createStore({});
const routes = route();
const appRoot = document.getElementById('app');

// Create data layer for GTM
window.dataLayer = window.dataLayer || [];

// Tracking Ads config setting
const tagManagerArgs = { gtmId: 'GTM-N4BLDFV' };
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-129789023-1');


window.addEventListener("unhandledrejection", function (event) {
  console.warn("WARNINGGGGG: Unhandled promise rejection. Reason: " + event.reason, event);
  console.warn(event);
});

ReactDOM.render(

  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <span className="notranslate"> {routes} </span>
    </I18nextProvider>
  </Provider>,
  appRoot,
);
