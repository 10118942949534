import React from 'react';
import './ExerciseResult.scss';
import {
  Button,
  FormControl,
} from "react-bootstrap";
import { EXERCISE_EDITOR_LANGUAGES} from "redux/constants/excercise";
import { codingbarApi } from "codingbar-api";
import Zoom from '@material-ui/core/Zoom';
import { translate } from 'react-i18next';
import { Typography } from '@material-ui/core';

@translate('translations')
class ExerciseResult extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      input:'',
      isInputRequired:false,
    }
  }
  // state = {
  //   input: '',
  // }
  componentDidUpdate(prevProps){
    const { studentStatus } = this.props
    if(!studentStatus)return;
    if (studentStatus && prevProps.studentStatus.buildCount !== studentStatus.buildCount) {
      const isInputRequired = this.checkIsInputRequired();
      console.log('SSS-componentDidUpdate',isInputRequired)
      this.setState({
				isInputRequired,
			})
    }


  }
  checkIsInputRequired = () => {
		const { studentStatus, language } = this.props;
		
		const lines = studentStatus.code.split("\n");
		let isInputRequired = false;
		
		// if (!['python', 'c', 'cpp', 'java', 'pygame', 'pyweb', 'microbit', 'blocklymicrobit', 'meowbit'].includes(exercise.codeType)) {
		// 	isInputRequired = true;
		// } else {
      console.log('SSS-language',language)
			if (language===EXERCISE_EDITOR_LANGUAGES.C.value) {
        
				lines.forEach((line) => {
					if (line.replace(/ /g, '').indexOf('scanf(') !== -1) {
            isInputRequired = true;
						return;
					}
				})
			} else if (language===EXERCISE_EDITOR_LANGUAGES.CPP.value) {
        console.log('SSS-language',language)
				lines.forEach((line) => {
					if (
						(line.replace(/ /g, '').indexOf('cin>>')) !== -1 || 
						(line.replace(/ /g, '').indexOf('scanf(') !== -1) || 
						(line.replace(/ /g, '').indexOf('getline(') !== -1) || 
						(line.replace(/ /g, '').indexOf('cin.get(') !== -1) || 
						(line.replace(/ /g, '').indexOf('cin.getline(') !== -1)
					) {
            isInputRequired = true;
						return;
					}
				})
			} else if (language===EXERCISE_EDITOR_LANGUAGES.JAVA.value) {
				lines.forEach((line) => {
					if ((line.replace(/ /g, '').indexOf('System.in')) !== -1 ) {
            isInputRequired = true;
						return;
					}
				})
			} else if (language===EXERCISE_EDITOR_LANGUAGES.LUA.value) {
				lines.forEach((line) => {
					if ((line.replace(/ /g, '').indexOf('io.read')) !== -1 ) {
            isInputRequired = true;
						return;
					}
				})
			} else{ //python default 
        lines.forEach((line) => {
					if (
						(line.replace(/ /g, '').indexOf('input(') !== -1) || 
						(line.replace(/ /g, '').indexOf('stdin.readline(') !== -1) || 
						(line.replace(/ /g, '').indexOf('stdin.readlines(') !== -1)
					) {
            isInputRequired = true;
						return;
					}
				})
      }
		// }
    return isInputRequired;
	}

  onInputChange = (e) => {
    const text = e.target.value;
    if (!this.state.shiftEnterPressed) {
      this.setState({
        input: text,
      })
    }
    else {
      this.setState({
        shiftEnterPressed: false
      });
    }
  };

  renderInput = () => {

    // const exerciseInfo = codingbarApi.getCourseService().getCurrentExercise();
    // console.log("exerciseInfo#renderInput#", exerciseInfo)
    // if (!exerciseInfo || !exerciseInfo.code || exerciseInfo.code.trim() === '') {
    //   return;
    // }
    // const lines = exerciseInfo.code.split("\n");
    
    // if(this.state.isInputRequired)return;
    // lines.forEach((line) => {
    //   if (line.trim().indexOf('input(') !== -1) {
    //     showInput = true;
    //     return
    //   }
    // });

    // if (!showInput) {
    //   return;
    // }

    return (
      <div>
        {/* <Typography className="title" variant="caption" display="block">請輸入</Typography> */}
        <FormControl
          type="text"
          value={this.state.input || ""}
          componentClass='textarea'
          rows="3"
          onKeyDown={this.handleKeyDown}
          style={{ resize: 'vertical' }}
          onChange={this.onInputChange}
          className="textarea-monospace input-field"
        />
      </div>);
  }


  handleKeyDown = (target) => {
    const text = this.state.input;

    if (target.keyCode === 13) {
      if (target.shiftKey) {
        // add enter in textarea whilte onInputChanged
      }
      else {
        codingbarApi.getCourseService().sendInput(this.props.studentStatus.student.uid, this.state.input);
        this.setState({
          input: '',
          shiftEnterPressed: true
        });
      }
    }
  }

  render() {
    const { t, studentStatus } = this.props;
    const { isInputRequired } = this.state;
    
    if(!studentStatus)return;
    const state = studentStatus.status;

    return (
      <div className="ExerciseResult">
        {state === 'running' && isInputRequired && this.renderInput()}
        {/* <Typography className="title" variant="caption" display="block">{t('exercise.playground.outputResult')}</Typography> */}
        <FormControl
          type="text"
          rows="6"
          value={studentStatus.output || ""}
          readOnly
          componentClass="textarea"
          className={`textarea-monospace output-field ${state === "running" && isInputRequired ? "onbotton":''}`}
        />
      </div>
    );
  }
}

export default ExerciseResult;
