export const INIT = 'REGNEW_INIT'
export const SET_ALL_GOODS_FROM_DB = 'REGNEW_SET_ALL_GOODS_FROM_DB'
export const SET_ALL_INFOS_FROM_DB = 'REGNEW_SET_ALL_INFOS_FROM_DB'
export const GET_REGISTRATION_SETTING = 'REGNEW_GET_REGISTRATION_SETTING'

export const SET_REDUCER = 'REGNEW_SET_REDUCER'

export const SET_PAY_LINK = 'REGNEW_SET_PAY_LINK'

export const GO_PREV_STEP = 'REGNEW_GO_PREV_STEP'
export const GO_NEXT_STEP = 'REGNEW_GO_NEXT_STEP'

export const CHECK_USER_COUNT = 'REGNEW_CHECK_USER_COUNT'
export const CANCEL_CHECK_USER_COUNT = 'REGNEW_CANCEL_CHECK_USER_COUNT'
export const WAIT_FOR_REGISTRATION = 'REGNEW_WAIT_FOR_REGISTRATION'

export const DISCOUNT = {
  NONE: 'none',
  MULTI: 'multi',
  OLD: 'old',
  COUPON: 'coupon',
  GROUP: 'group',
}
