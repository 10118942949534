import React from 'react';
import { connect } from 'react-redux';
import * as cons from 'redux/constants/app';

import { Button, Modal } from 'components';
// import Modal from 'react-responsive-modal';

import Icon from '../Icon';

import './MultiLoginAlert.scss';

const mapStateToProps = (state) => {
	const { app } = state;

	return {
		multiLoginAlertShow: app.get('multiLoginAlertShow'),
	};
};
const mapDispatchToProps = (dispatch) => {
	const testest = () => {
		return dispatch({ type: cons.MULTI_LOGIN_ALERT });
	};

	return {
		testest,
	};
};
import { translate } from 'react-i18next';
@translate('translations')
class MultiLoginAlert extends React.Component {
	constructor(props) {
		super(props);
		window.testest = props.testest;
	}

	signOutApi = () => {
		codingbarApi.getAuthService().signOut();
	};
	render() {
		const { multiLoginAlertShow, t } = this.props;
		console.log('multiLoginAlertShow', multiLoginAlertShow);

		return (
			<Modal
				isOpen={multiLoginAlertShow}
				isShowClose={false}
				closeOnOverlayClick={false}
				shouldCloseOnEsc={false}
			>
				<div className="multi-login-alert">
					<h4 className="multi-login-alert-header">
						<Icon name="MdReportProblem" color="red" />
						<span>&nbsp;{t('multipleLogin')}</span>
					</h4>

					<br />
					<p>{t('multipleLogin.hint1')}</p>
					<br />
					<p>{t('multipleLogin.hint2')}</p>
					<br />

					<div className="text-center">
						<Button primary className="w-50" onClick={this.signOutApi}>
							{t('common.known')}
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiLoginAlert);
