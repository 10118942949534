import PropTypes from "prop-types"
import React from 'react';
import { translate } from 'react-i18next';
// import { connect } from 'react-redux';
import { ActionModal } from 'components';
import './StudentTestAlert.scss';

@translate('translations')
class StudentTestAlert extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	closeAlert = () => {
		const { testAlertData, t } = this.props;

		this.props.closeTestModal()
		if (testAlertData.modalButtonWord === t('common.leave')) {
			window.location.href = this.props.testRoute //因為要 reload 資料, 不要用 history
		}

	}

	goToTest = () => {
		this.props.closeTestModal()
		window.location.href = this.props.testRoute //因為要 reload 資料, 不要用 history
	}
	render() {
		const { showTestModal, testAlertData } = this.props;
		
		return (
			<ActionModal
				type="confirmation"
				className="studenttestalert-view-modal"
				isOpen={showTestModal}
				onClose={this.closeAlert}
				onConfirm={this.goToTest}
				shouldCloseOnOverlayClick={false}
				modalTitle={testAlertData ? testAlertData.modalTitle : ''}
				confirmButtonText={testAlertData ? testAlertData.modalButtonWord : ''}
			>
				<div className="studenttestalert-content-msg">
					<p>{testAlertData ? testAlertData.modalContent : ''}</p>
				</div>
			</ActionModal>
		);
	}
}

StudentTestAlert.propTypes = {
	closeTestModal: PropTypes.func,
	showTestModal: PropTypes.bool,
	t: PropTypes.func,
	testAlertData: PropTypes.shape({
		modalButtonWord: PropTypes.string,
		modalContent: PropTypes.string,
		modalTitle: PropTypes.string,
	}),
	testRoute: PropTypes.string,
};

export default StudentTestAlert;
