export const codingbarMonacoTheme = {
	base: 'vs-dark',
	inherit: true,
	rules: [
		{ background: '1F2A3F' },
		{ token: 'number.python', foreground: '#b5f1a8' },
		{ token: 'keyword.python', foreground: '#8bcbdd' },
		{ token: 'comment.python', foreground: '#ba7ad0' },
		{ token: 'string.python', foreground: '#eba88f' },
		{ token: 'string.escape.python', foreground: '#eba88f' }

	],
	colors: {
		'editor.background': '#1F2A3F',
		"editor.lineHighlightBackground": "#2F3B52",
	}
};

export const codingbarDarkMonacoTheme = {
	base: 'vs-dark',
	inherit: true,
	rules: [
		{ background: '#1B1D26' },
		{ token: 'number.python', foreground: '#b5f1a8' },
		{ token: 'keyword.python', foreground: '#8bcbdd' },
		{ token: 'comment.python', foreground: '#ba7ad0' },
		{ token: 'string.python', foreground: '#eba88f' },
		{ token: 'string.escape.python', foreground: '#eba88f' }

	],
	colors: {
		'editor.background': '#1B1D26',
		"editor.lineHighlightBackground": "#343945",
	}
};

export const AImateDarkTheme = {
	base: 'vs-dark',
	inherit: true,
	rules: [
		{ background: '#151515' },
		{ token: 'number.python', foreground: '#b5f1a8' },
		{ token: 'keyword.python', foreground: '#8bcbdd' },
		{ token: 'comment.python', foreground: '#ba7ad0' },
		{ token: 'string.python', foreground: '#eba88f' },
		{ token: 'string.escape.python', foreground: '#eba88f' },

	],
	colors: {
		'editor.background': '#151515',
		'editor.lineHighlightBackground': '#252525',
	},
};

export const defineThemeOnEditorWillMount = function defineThemeOnEditorWillMount(monacoInstance)  {
	monacoInstance.editor.defineTheme('codingbarMonacoTheme', codingbarMonacoTheme);
};

export const isLock = () => {
	const lockTime = localStorage.getItem('isSubmitting');
	console.log('lockTime', lockTime);
	console.log('lockTime# now', Date.now());
	return (Date.now() - parseInt(lockTime)) < 1000; //原 3 秒太長了, 很多執行時間不超過 1 秒, UserStory 是按完執行後接著按提交
}
export const setLock = (lock) => {
	localStorage.setItem('isSubmitting', lock ? Date.now().toString() : '0');
};
// 基本上沒有用到這個function submitCode
export function submitCode(courseId, lessonId, exerciseId, status, uid, code, vds, kernel = undefined, submitType = "exec", version = undefined, stepId='', language='') {

	console.log("isSubmitting:" + isLock())

	if (!code || code.trim().length === 0) {
		alert("程式碼不可為空!");
		return;
	}

	if (!courseId || !lessonId || !exerciseId || !status) {
		alert("資料不正確!");
		return;
	}

	if (isLock() || status === 'running') {
		alert("正在執行!!");
		return;
	}

	console.log("isSubmitting# submit# setLock: true, status:" + status);
	console.log("ExerciseEditor# submit# lock");
	setLock(true);

	localStorage.setItem("submitType", submitType);

	codingbarApi.getCourseService().submitCode(courseId,
		lessonId,
		exerciseId,
		uid,
		code,
		vds,
		kernel,
		submitType,
		version,
		stepId,
		language,
	);
}

export function stopCode(courseId, lessonId, exerciseId, uid) {
	codingbarApi.getCourseService().stopTask(courseId, lessonId, exerciseId, uid);
}
