import React from 'react';
import { Creatable } from 'react-select';
import Indicator from 'design/images/icons/icon-index_down.svg';
import './CreatableSelect.scss';

export default function(props) {
	const { className, ...otherProps } = props;
	const DropdownIndicator = () => {
		return <img src={Indicator} />;
	};
	return (
		<Creatable
			className={`coding-bar-creatable-select ${className}`}
			classNamePrefix="coding-bar-creatable-select"
			isClearable={false}
			backspaceRemovesValue={false}
			components={{ DropdownIndicator }}
			{...otherProps}
		/>
	);
}
