export function checkIfFormCanEnabled(formData) {
  const { isGroupRegistration } = formData // to check
  if (!checkFormValidation(formData)) return false
  else {
    if (isGroupRegistration) return checkPriceValidationGroup(formData)
    else return checkPriceValidation(formData)
  }
}
export function checkFormValidation(formData) {
  const {
    formId, title,
    placeClassOptions, emailPaymentTitle, emailPaymentContent, emailNotPaidTitle, emailNotPaidContent
  } = formData

  if (!title || !formId) return false    // 檢查報名表標題及報名表ID非空
  if (!placeClassOptions || !placeClassOptions.length) return false   // 檢查開課地區>0
  for (const placeOption of placeClassOptions) {  // 檢查每個開課地區的班別數>0
    if (!placeOption.classOptions || !placeOption.classOptions.length) return false
  }
  if (
    !emailPaymentTitle || !emailPaymentContent || !emailNotPaidTitle || !emailNotPaidContent
  ) return false   // 檢查回覆信內容都非空
  return true
}
export function checkPriceValidationGroup(formData) {
  const { priceGroupDiscount } = formData
  return priceGroupDiscount && priceGroupDiscount.length    // 檢查團報價格非空
}
export function checkPriceValidation(formData) {
  const { priceOrigin } = formData
  return priceOrigin && priceOrigin.length    // 檢查基本價格非空
}
