import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './DatePickerCalendar.scss';

export default class DatePickerCalendar extends React.Component {
	static propTypes = {
		// value: PropTypes.string,
		// onChange: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.state = {
			startDate: this.props.value, // || moment(),
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			startDate: nextProps.value,
		};
	}

	handleChange = (date) => {
		const { onChange } = this.props;
		this.setState({
			startDate: date,
		});

		if (typeof onChange === 'function') {
			onChange(date);
		}
	};

	render() {
		const { startDate } = this.state;

		return <DatePicker className="coding-bar-date-picker-calendar" selected={startDate} onChange={this.handleChange} />;
	}
}
