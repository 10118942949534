import React from 'react';

import './TextArea.scss';

export default function (props) {
  const { className = '', ...otherProps } = props;
  const handleClick = (e) => {
    e.stopPropagation()
  }

  return (
    <textarea rows={10} className={`coding-bar-textarea-text ${className}`}onClick={e => handleClick(e)} {...otherProps} />
  );
}
