import { STUDENT_STATUS_COLLECTION } from './collections';

export const getStudentLessonExerciseStatus = (courseId, lessonId) => {
	const user = codingbarApi.getAuthService().getUser();
	if (!courseId || !courseId) return Promise.reject(`courseId or lessonId can't be empty`);

	const filter = {
		unit: user.unit,
		courseId,
		lessonId,
		uid: user.uid,
	};

	const projection = {};

	return codingbarApi.getCoreService().queryData(STUDENT_STATUS_COLLECTION, filter, {}, {}, '');
};

export const resetStudentStatus = (courseId, lessonId, exerciseId, isStep, uid) => {
	if (!courseId || !lessonId || !exerciseId || !uid) return Promise.reject(`invalid parameters`);

	const filter = {
		courseId,
		lessonId,
		exerciseId,
		uid,
	};

	const now = Date.now();

	const data = {
		code: '',
		result: '',
		output: '',
		codeStatus: 'unknown',
		status: 'idle',
		buildCount: 0,
		tipViewCount: 0,
		answerViewCount: 0,
		readTips: [],

		finishedTime: 0,
		firstSubmitCode: '',

		duration: 0,
		latestBuildTime: 0,
		kernel: {},
		observed: false,

		updated: now,
		practiceUpdated: now,
	};

	if (isStep) {
		data.stepResults = {};
	}

	return codingbarApi.getCoreService().updateData(STUDENT_STATUS_COLLECTION, filter, data);
};

export const getStudentStatusByUidList = (courseId, lessonId, exerciseId, uids, sort = {}, projection = {}, distinctKey = '') => {
	if (!courseId || !lessonId || !exerciseId || uids.length < 1) return Promise.reject(`invalid parameters`);

	const filter = {
		courseId,
		lessonId,
		exerciseId,
		uid: { $in: uids },
	};

	return codingbarApi.getCoreService().queryData(STUDENT_STATUS_COLLECTION, filter, sort, projection, distinctKey);
};

export const getStudentStatusByTestIds = (courseId, testIds, withUnit = false) => {
	if (!courseId || !testIds) return Promise.reject(`invalid parameters`);
	const user = codingbarApi.getAuthService().getUser();

	const filter = {
		courseId,
		lessonId: { '$in' : testIds},
		...(withUnit ? { unit: user.unit } : null),
	};

	return codingbarApi.getCoreService().queryData(STUDENT_STATUS_COLLECTION, filter, {}, {}, '').then((res) => {
		return res.status === 'success' ? res.result : undefined;
	});
};

export const setStudentStatusKillReason = (courseId, lessonId, exerciseId, uid, killReason = 'overTimeLimit') => {
	if (!courseId || !lessonId || !exerciseId || !uid) return Promise.reject(`invalid parameters`);
	const filter = {
		courseId,
		lessonId,
		exerciseId,
		uid,
	};

	const data = {
		killReason,
	};

	return codingbarApi.getCoreService().updateData(STUDENT_STATUS_COLLECTION, filter, data);
};

// chatGPT related
export const updateChatGPTTokensBySessionId = async (sessionId, newTokens) => {
	try {
		if (!sessionId) throw new Error('invalid parameters, sessionId');
		const filter = {
			sessionId,
		};
		const projection = {
			chatGPTTokens: 1,
		};
		const response = await codingbarApi.getCoreService().queryOne(STUDENT_STATUS_COLLECTION, filter, projection);
		if (response.status !== 'success') throw new Error('something is wrong');
		const { chatGPTTokens } = response.result;
		const preGPTTokens = chatGPTTokens || 0;
		const updatedTokens = preGPTTokens + newTokens;

		const data = {
			chatGPTTokens: updatedTokens,
		};

		return codingbarApi.getCoreService().updateData(STUDENT_STATUS_COLLECTION, filter, data);
	} catch (error) {
		return Promise.reject(error.message);
	}
};
