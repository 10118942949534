import React from 'react';
import IconLabel from '../common/IconLabel.js';

import iconDone from 'design/images/icon-label-done.svg'

export default function (props) {
  return (
    <IconLabel icon={iconDone} label='作業已完成' bgColor='#5CD391' />
  );
}
