import { takeLatest, call, put } from 'redux-saga/effects';
import { delay } from "redux-saga";
// import { codingbarApi } from 'codingbar-api';

import * as cons from '../constants/search';

const fakeSearchedResponse = (searchTerm) => {
  return new Promise(resolve => {
    setTimeout(() => resolve({
      searchTerm,
      list: [
        {id: 123, name: 'Joe'},
        {id: 124, name: 'John'},
        {id: 125, name: 'Jason'}
      ]
    }), 2000)
  })
}

export function* watchSearch() {
  yield takeLatest(cons.SAGA_GET_SEARCHED_RESULT, function*({ payload = {} }) {
    yield delay(500);
    try {
      // const courseService = codingbarApi.getCourseService();
      yield put({ type: cons.GET_SEARCHED_RESULT, payload });
      const result = yield call(fakeSearchedResponse, payload);

      yield put({ type: cons.GET_SEARCHED_RESULT_SUC, result });
    } catch (error) {
      yield put({ type: cons.GET_SEARCHED_RESULT_ERR, error });
      throw error;
    }
  });
}

export default [watchSearch];
