import React, { Fragment } from 'react';
import { codingbarApi } from 'codingbar-api';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, ButtonBase } from '@material-ui/core';

import { EXERCISE_EDITOR_LANGUAGES, EDITOR_FONT_SIZE_OPTIONS } from 'redux/constants/excercise';
import { setCurrentSubmitting, addToast } from 'redux/reducers/app';

import { setStudentStatusKillReason } from 'shared/api/StudentStatus';
import { stopCode, isLock, setLock } from 'shared/util/EditorUtils';

import { Select } from 'components';
import Icon from 'components/core/Icon';
import Button from 'components/core/Button';
import CountDownDay from 'components/CountDownDay/CountDownDay';

import noSessionImg from 'design/images/TeacherPage/teacher-course-no-course.svg';
import { ReactComponent as RunIcon } from 'design/images/icons/icon-run-yellow.svg';
import { ReactComponent as StopRunIcon } from 'design/images/icons/icon-union.svg';
import { ReactComponent as SettingWhiteIcon } from 'design/images/icons/icon-setting-white.svg';

import ExerciseEditor from './ExerciseEditor';
import ExerciseResult from './ExerciseResult';
// import ExerciseFooter from "./ExerciseFooter";
// import PlaygroundSettings from './PlaygroundSettings';

import './Playground.scss';
@translate('translations')
@connect((state) => {
	const app = state.app.toJS();
	return {
		currentSubmitting: app.currentSubmitting,
	};
})
class Playground extends React.PureComponent {
	constructor(props) {
		super(props);
		this.settingWrapperRef = React.createRef();
		this.settingIconRef = React.createRef();
		this.state = {
			courseId: '00000',
			lessonId: '00000',
			exerciseId: '00000',
			isFullScreen: false,
			isDataReady: false,
			// isSetting: false,
			isShowSetting: false,
			studentStatus: null,

			language: localStorage.getItem('playgroundLanguage')
				? EXERCISE_EDITOR_LANGUAGES[localStorage.getItem('playgroundLanguage').toUpperCase()]
				: { value: 'python', label: 'Python' },
					fontSize: localStorage.getItem('playgroundFontSize')
				? {
						value: `${localStorage.getItem('playgroundFontSize')}`,
						label: `${localStorage.getItem('playgroundFontSize')} pt`,
				}
				: { value: '16', label: '16 pt' },
					
			execEndTime: Date.now() + 120000 // set exec limit CountDown
		};
	}
	// playgroundLanguage
	componentDidMount = () => {
		this.prepare();
	};
	componentDidUpdate = (prevProps, preState) => {
		if (
			preState.studentStatus &&
			(preState.studentStatus.status !== this.state.studentStatus.status ||
				preState.studentStatus.buildCount !== this.state.studentStatus.buildCount)
		) {
			this.props.dispatch(setCurrentSubmitting({ status: this.state.studentStatus.status }));
		}
	};

	prepare = () => {
		const { courseId, lessonId, exerciseId } = this.state;
		console.log('StudentExercise#prepare#');
		const user = codingbarApi.getAuthService().getUser();
		const studentInfo = {
			uid: user.uid,
			email: user.email,
			displayName: user.displayName,
			photoUrl: user.photoUrl,
			seatNo: user.moreInfo && user.moreInfo.seatNo ? user.moreInfo.seatNo : '',
		};

		codingbarApi
			.getCourseService()
			.stopCurrentExercise()
			.then(() => {
				console.log('StudentExercise#prepare#');
				return codingbarApi.getCourseService().startExercise(courseId, lessonId, exerciseId, studentInfo);
			})
			.then((ss) => {
				console.log('StudentExercise#prepare#startExercise# done!');
				this.setState({
					isDataReady: true,
					studentStatus: ss,
					code: ss.code,
				});

				this.observeStudentStatus(ss);
			})
			.catch((err) => console.log('StudentExercise# startExercise# error', err));
	};

	observeStudentStatus = (studentStatus) => {
		const { courseId, lessonId, exerciseId } = this.state;
		const host = this;

		console.log('observeStudentStatus#", "##listenStudentStatus()');
		codingbarApi.getCourseService().listenStudentStatus(courseId, lessonId, exerciseId, studentStatus.student.uid, {
			onChanged(updatedStudentStatus) {
				console.log('observeStudentStatus# updatedStudentStatus:', updatedStudentStatus);
				if (host.state.exerciseId !== updatedStudentStatus.exerciseId) {
					console.error(
						'observeStudentStatus# listenStudentStatus#onChanged# studentStatus, listener leak!',
						host.state.exerciseId + ' v.s ' + updatedStudentStatus.exerciseId
					);
					return;
				}
				host.setState({
					studentStatus: updatedStudentStatus,
				});
			},
		});
	};

	onCodeChange = (newValue) => {
		const { courseId, lessonId, exerciseId, studentStatus } = this.state;
		codingbarApi.getCourseService().saveCode(courseId, lessonId, exerciseId, studentStatus.student.uid, newValue);
		this.setState({
			code: newValue,
		});
	};
	handleMouseDownEvent = (event) => {
		if (!this.state.isShowSetting) return;
		if (
			this.settingWrapperRef.current &&
			!this.settingWrapperRef.current.contains(event.target) &&
			!this.settingIconRef.current.contains(event.target)
		) {
			this.setState({ isShowSetting: false });
		}
	};
	onSubmitCode = () => {
		const { courseId, lessonId, exerciseId, studentStatus, code, language } = this.state;
		const { t, dispatch } = this.props;

		// submitCode(courseId, lessonId, exerciseId, studentStatus.status, studentStatus.student.uid, code, "", undefined, "exec", undefined ,undefined,language.value)
		if (!code || code.trim().length === 0) {
			dispatch(
				addToast({
					toastType: 'alert',
					toastText: t('component.exerciseManage.alert.noCode'),
				})
			);
			return;
		}

		if (!courseId || !lessonId || !exerciseId || !studentStatus.status) {
			dispatch(
				addToast({
					toastType: 'alert',
					toastText: t('component.exerciseManage.alert.incorrectData'),
				})
			);
			return;
		}

		if (isLock()) {
			dispatch(
				addToast({
					toastType: 'alert',
					toastText: t('component.exerciseManage.alert.execute.frequently'),
				})
			);
			return;
		}
		if (studentStatus.status === 'running') {
			dispatch(
				addToast({
					toastType: 'alert',
					toastText: t('component.exerciseManage.alert.executing'),
				})
			);
			return;
		}

		if (
			this.props.currentSubmitting &&
			this.props.currentSubmitting.status === 'running' &&
			!localStorage.getItem('exercise_type')
		) {
			dispatch(
				addToast({
					toastType: 'alert',
					toastText: t('component.exerciseManage.alert.other.code.executing'),
				})
			);
			return;
		}
		const submitType = 'exec';

		console.log('isSubmitting# submit# setLock: true, status:' + studentStatus.status);
		console.log('ExerciseEditor# submit# lock');
		setLock(true);
		this.props.dispatch(setCurrentSubmitting({ status: 'running' }));
		localStorage.setItem('submitType', submitType);

		codingbarApi
			.getCourseService()
			.submitCode(
				courseId,
				lessonId,
				exerciseId,
				studentStatus.student.uid,
				code,
				'',
				undefined,
				submitType,
				undefined,
				'',
				language.value
			);
		this.setState({
			isShowSetting: false,
			execEndTime: Date.now() + 120000,
		});
	};

	onStopCode = () => {
		const { courseId, lessonId, exerciseId, studentStatus } = this.state;
		stopCode(courseId, lessonId, exerciseId, studentStatus.student.uid);
	};

	onToggleSetting = () => {
		this.setState({ isShowSetting: !this.state.isShowSetting });
	};

	onLanguagesChange = (option) => {
		localStorage.setItem('playgroundLanguage', option.value);
		this.setState({ language: option });
	};
	onFontSizeChange = (option) => {
		localStorage.setItem('playgroundFontSize', option.value);
		this.setState({ fontSize: option });
	};
	// fontSizeList = () => {
	//   const fontSizeList = [];
	//   let i = 0;
	//   for (i = 0; i < 10; i++) {
	//     fontSizeList.push(8 + i * 2);
	//   }

	//   return (
	//     <DropdownButton
	//       title={this.state.fontSize}
	//     >
	//       {fontSizeList.map((val, index) => {
	//         return <MenuItem id={index} eventKey={index} onSelect={this.onFontSizeChanged.bind(this, val)}>{val}</MenuItem>;
	//       })}
	//     </DropdownButton>
	//   );
	// }

	toggleFullScreen = () => {
		this.props.toggleFullScreen();
	};
	renderHeader = () => {
		const { hidePlayground, dispatch, t } = this.props;
		const { studentStatus, isShowSetting, language, execEndTime } = this.state;

		const isRunning = studentStatus ? studentStatus.status === 'running' : '';
		const selectLanguagesOptions = Object.values(EXERCISE_EDITOR_LANGUAGES);

		return (
			<Grid className="playground-Header" container spacing={6}>
				<div className="playground-icon">
					<Button className="playground-run-btn" id="playground-run-btn" onClick={!isRunning ? this.onSubmitCode : this.onStopCode}>
						{!isRunning ? <RunIcon className="playground-run-icon" /> : <StopRunIcon className="playground-run-icon" />}
					</Button>
				</div>
				<Grid item xs='auto' className="playground-codetype">
					{!isRunning ? `CodeType: ${language.label}` : <div className="running" id="running">running...</div>}
				</Grid>
				<Grid item xs={true} className="playground-countDown">
					{isRunning &&
						<CountDownDay
							customClass={'playground-countDown-countDownDay'}
							initialValue={execEndTime < Date.now() ? 0 : (execEndTime - Date.now()) / 1000}
							endTime={execEndTime}
							actionWhenTimerEnds={() => {
								dispatch(addToast({
									toastType: 'alert',
									toastText: t('exercise.killReason.overTimeLimit'),
									msBeforeClose: 3000,
								}));
								setStudentStatusKillReason('00000', '00000', '00000', studentStatus.student.uid, 'overTimeLimit');
								this.onStopCode();
							}}
						/>}
				</Grid>
				{/* <Grid item xs="auto">
					<ButtonBase onClick={() => this.toggleSettings(true)}>
						<Icon name="setting" color="white" />
					</ButtonBase>
					</Grid>
					<Grid item xs="auto">
					<ButtonBase onClick={this.toggleFullScreen}>
						<Icon name="fullscreen" color="white" />
					</ButtonBase>
				</Grid> */}
				<Grid
					item
					xs="auto"
					className={`playground-setting-btn ${isRunning ? 'disabled' : ''}`}
					ref={this.settingIconRef}>
					<SettingWhiteIcon className="setting-icon" onClick={this.onToggleSetting} />
				</Grid>
				<Grid item xs="auto" className="playground-close-btn">
					<ButtonBase onClick={hidePlayground}>
						<Icon name="close-light" />
					</ButtonBase>
				</Grid>
				{isShowSetting && (
					<div className="playground-setting-cont" ref={this.settingWrapperRef}>
						<Select
							options={selectLanguagesOptions}
							defaultValue={EXERCISE_EDITOR_LANGUAGES.PYTHON}
							className="monacoTheme playground-select"
							onChange={this.onLanguagesChange}
							value={this.state.language}
						/>
						<Select
							options={EDITOR_FONT_SIZE_OPTIONS}
							defaultValue={EDITOR_FONT_SIZE_OPTIONS[4]}
							className="monacoTheme playground-select"
							onChange={this.onFontSizeChange}
							value={this.state.fontSize}
						/>
					</div>
				)}

				{/* <Grid item xs={12}>
					<Typography variant="caption">{t('exercise.playground.enterCode')}</Typography>
				</Grid> */}
			</Grid>
		);
	};

	getContent = () => {
		const {
			isDataReady,
			// isSetting,
			studentStatus,
			fontSize,
			language,
			code,
		} = this.state;
		const { t } = this.props;

		if (!isDataReady)
			return (
				<Fragment>
					{this.renderHeader()}
					{/* this id is for cypress, don't delete */}
					<div id="playground-dataNotReady">
						<img className="no-course-img" alt="" src={noSessionImg} />
						<h5 className="no-course-text">{t('common.loading')}</h5>
					</div>
				</Fragment>
			);
		// else if (isSetting) return (
		//   <PlaygroundSettings
		//     onClose={() => this.toggleSettings(false)}
		//     fontSize={fontSize}
		//     onFontSizeChanged={this.onFontSizeChanged}
		//   />
		// )
		else
			return (
				<Fragment>
					{this.renderHeader()}
					<ExerciseEditor
						status={this.state.studentStatus ? this.state.studentStatus.status : 'idle'}
						fontSize={fontSize.value}
						language={language.value}
						code={code}
						onCodeChange={this.onCodeChange}
						playgroundframewidth={this.props.playgroundframewidth}
						playgroundframeheight={this.props.playgroundframeheight}
						setStopDraggingEvent={this.props.setStopDraggingEvent}
					/>
					{studentStatus && <ExerciseResult studentStatus={studentStatus} language={language.value} />}
				</Fragment>
			);
	};

	render() {
		const { studentStatus } = this.state;
		return (
			<div className="Playground" id="playground" onMouseDown={this.handleMouseDownEvent}>
				{/* this <div id="studentStatus" / > is for cypress, don't delete */}
				{studentStatus && <div id="playground-studentStatus" data-status={studentStatus.status} data-kill-reason={studentStatus.killReason}></div>}
				{this.getContent()}
			</div>
		);
	}
}

export default Playground;
