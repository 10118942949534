import React from "react";
import cx from 'classnames'
import Draggable from 'react-draggable';
import "./ZoomTeacher.scss";
import { translate } from 'react-i18next';
import {Button} from "react-bootstrap";
import ZoomStudent from "./ZoomStudent";
import ZoomTeacher from "./ZoomTeacher";

@translate('translations')
class ZoomManager extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      videoConferenceInfos: props.videoConferenceInfos,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      videoConferenceInfos: nextProps.videoConferenceInfos,
    })
  }

  getUser() {
    try {
      return codingbarApi.getAuthService().getUser();
    } catch (err) {
      console.log(err)
    }
    return undefined;
  }

  setVisible(visible) {
    if (visible) {
      this.props.setVisible(true);
    } else {
      this.props.setVisible(false);
    }
  }

  render() {

    if (this.props.visible !== true) {
      return "";
    }

    const user = this.getUser();

    console.log("zoomManager#videoConferenceInfos", this.state.videoConferenceInfos);

    switch (user.role) {
      case "student":
        return <div>
          <ZoomStudent setVisible={this.setVisible.bind(this)}  videoConferenceInfos={this.state.videoConferenceInfos}/>
        </div>
        break;
      case "teacher":
        return <div>
          <ZoomTeacher/>
        </div>
        break;
    }

    return (
      <div>
      </div>
    )
  }
}

export default ZoomManager;
