import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import html2canvas from 'html2canvas';
// import { initNotification, initListenUnreadNotification } from 'redux/reducers/notification';
import onClickOutside from 'react-onclickoutside';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { Hidden, ButtonBase, Drawer } from '@material-ui/core';

import { isCSFC } from 'shared/helper/permission';

import FeedbackManage from 'containers/FeedbackManage/FeedbackManage';

import Icon from 'components/core/Icon';
// import NotificationListModal from 'components/NotificationListModal/NotificationListModal';
import AnnouncementListModal from 'components/AnnouncementListModal/AnnouncementListModal';

import NoteDialog from 'containers/NewStudentPage/StudentNote/NoteDialog';
import HeaderIconList from './HeaderIconList';
import './HeaderIconContent.css';

const mapStateToProps = (state) => {
	const app = state.app;
	// 通知功能目前無入口使用, 且應與公告功能分離, 並且重新定義顯示規範
	// const notification = state.notification;
	const user = state.app.get('user');
	const isDemo = user ? user.get('role') === 'demo' : false;
	return {
		// notifications: notification.get('unreadNotifications') ? notification.get('unreadNotifications').toJSON() : '',
		isDemo,
		announcements: app.get('announcements') ? app.get('announcements').toJSON() : [], // 只儲存誰會於登入時跳出公告視窗
		ancmList: app.get('ancmList') ? app.get('ancmList').toJSON() : [], // 儲存所有公告列表
	};
};

const mapDispatchToProps = {
	// initNotification,
	// initListenUnreadNotification,
};

class HeaderDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			// following state used for Feedback screenshot
			screenshotCanvas: null,
			// notifications: [],

			isAnnouncementListModalOpen: false,
			isNotesOpen: false,
			isFeedbackOpen: false,

			isMobileDropdownOpen: false,
		};
	}
	// componentDidMount() {
	// 	this.props.initNotification();
	// 	this.props.initListenUnreadNotification();
	// }

	toggleAnnouncementListModal = () => {
		if (this.props.isDemo) return;

		this.setState((prevState) => {
			if (!prevState.isAnnouncementListModalOpen) {
				return {
					isAnnouncementListModalOpen: true,
					isNotesOpen: false,
					isFeedbackOpen: false,
				};
			} else
				return {
					isAnnouncementListModalOpen: false,
				};
		});
	};

	toggleNotes = () => {
		if (this.props.isDemo) return;

		this.setState((prevState) => {
			if (!prevState.isNotesOpen) {
				return {
					isAnnouncementListModalOpen: false,
					isNotesOpen: true,
					isFeedbackOpen: false,
				};
			} else
				return {
					isNotesOpen: false,
				};
		});
	};

	toggleNotes = () => {
		if (this.props.isDemo) return;

		this.setState((prevState) => {
			if (!prevState.isNotesOpen) {
				return {
					isAnnouncementListModalOpen: false,
					isNotesOpen: true,
					isFeedbackOpen: false,
				};
			} else
				return {
					isNotesOpen: false,
				};
		});
	};

	toggleFeedback = () => {
		if (this.props.isDemo) return;

		if (!this.state.isFeedbackOpen) {
			html2canvas(document.body, {
				ignoreElements: (node) => {
					return (
						typeof node.className === 'string' &&
						node.className.split(' ').indexOf('icon') !== -1 &&
						window.getComputedStyle(node).backgroundImage.indexOf('image/svg') !== -1
					);
				},
			}).then((canvas) => {
				this.setState({
					isAnnouncementListModalOpen: false,
					isNotesOpen: false,
					isFeedbackOpen: true,

					screenshotCanvas: canvas,
				});
			});
		} else {
			this.setState({ isFeedbackOpen: false });
		}
	};

	toggleMobileDropdown = () => {
		this.setState((prevState) => ({ isMobileDropdownOpen: !prevState.isMobileDropdownOpen }));
	};

	handleClickOutside = () => {
		this.setState({
			// isNotificationOpen: false,
			isNotesOpen: false,
			isFeedbackOpen: false,
		});
	};

	getHeaderIconList = (isMobile = false) => {
		const { ancmList } = this.props;
		return (
			<HeaderIconList
				ancmList={ancmList}
				onClickAnnouncement={this.toggleAnnouncementListModal}
				openNoteDialog={this.toggleNotes}
				onClickFeedback={this.toggleFeedback}
				onClickFreecode={this.props.togglePlayground}
				onClickZoom={this.props.toggleZoom}
				isMobile={isMobile}
				onClickItem={isMobile ? this.toggleMobileDropdown : null}
			/>
		);
	};

	render() {
		const { isMobileDropdownOpen } = this.state;
		const isCSFCExam = isCSFC();

		return (
			<Fragment>
				<Hidden smDown>{this.getHeaderIconList()}</Hidden>
				<Hidden mdUp>
					<ButtonBase onClick={this.toggleMobileDropdown}>
						<Icon name="menu" color="white" />
					</ButtonBase>

					<Drawer
						anchor="right"
						open={isMobileDropdownOpen}
						onClose={this.toggleMobileDropdown}
						className="HeaderIconDropdown"
						PaperProps={{ className: isCSFCExam ? 'header-paper-csfc' : 'header-paper' }}
						keepMounted>
						{this.getHeaderIconList(true)}
					</Drawer>
				</Hidden>

				{this.state.isAnnouncementListModalOpen && (
					<ul className="HeaderIconContent ignore-react-onclickoutside">
						<AnnouncementListModal
							isAnnouncementListModalOpen={this.state.isAnnouncementListModalOpen}
							toggleAnnouncementListModal={this.toggleAnnouncementListModal}
							ancmList={this.props.ancmList}
						/>
					</ul>
				)}

				{this.state.isNotesOpen && (
					<NoteDialog
						rootClassName="ignore-react-onclickoutside"
						isShow={this.state.isNotesOpen}
						closeModal={this.toggleNotes}
					/>
				)}

				{this.state.isFeedbackOpen && (
					<FeedbackManage
						rootClassName="ignore-react-onclickoutside"
						isShow={this.state.isFeedbackOpen}
						closeModal={this.toggleFeedback}
						screenshotCanvas={this.state.screenshotCanvas}
					/>
				)}
			</Fragment>
		);
	}
}

export default compose(
	translate('translations'),
	connect(mapStateToProps, mapDispatchToProps),
	onClickOutside
)(HeaderDetail);
