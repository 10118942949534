import React, { Fragment } from "react";
import cx from 'classnames'
import Draggable from 'react-draggable';
import "./PlaygroundFrame.scss";
import { translate } from 'react-i18next';
import Playground from "./Playground";

import ResizeAngle from 'components/ResizeBar/ResizeAngle';
@translate('translations')
class PlaygroundFrame extends React.PureComponent {

	constructor(props) {
		super(props);

		this.isFullScreen = false;

		this.state = {
			playgroundframewidth: 320,
			playgroundframeheight: 400,
			isHideForFullScreen: false,
			onStopDraggingEvent: null,
		};
	}

	toggleFullScreen = () => {
		this.isFullScreen = !this.isFullScreen;
		this.setState({
			isHideForFullScreen: true
		});

		const host = this;
		setTimeout(function (){
			host.setState({
				isHideForFullScreen: false
			})}, 50);
	}

	// hidePlayground = () => {
	//   if (this.props.onCloseModel) {
	//     this.props.onCloseModel()
	//   }
	//   this.props.hidePlayground()
	// }

	onAdjustPlayGroundItemSize = (e, data) => {
		const locationHref = window.location.href.split('/').reverse();
		const isInExercise = locationHref[1] === 'exercise' && !locationHref.includes('teacher');
		
		const dragPositionX = e.touches ? e.touches[0].clientX : e.clientX;
		const dragPositionY = e.touches ? e.touches[0].clientY : e.clientY;
		
		const playGroundWidth = this.playGroundFrame.getBoundingClientRect().right - dragPositionX;
		const playGroundHeight = dragPositionY - this.playGroundFrame.getBoundingClientRect().top;
		
		let limitMaxWidth = this.playGroundFrame.getBoundingClientRect().right
		let limitMaxHeight = document.documentElement.clientHeight - this.playGroundFrame.getBoundingClientRect().top 
		
		if (isInExercise) {
			limitMaxWidth = this.playGroundFrame.getBoundingClientRect().right - 96
			limitMaxHeight = document.documentElement.clientHeight - this.playGroundFrame.getBoundingClientRect().top - 62
		}
		
		let renderHeight;
		let renderWidth;

		if (limitMaxWidth > playGroundWidth && playGroundWidth > 320) { // min-width
			renderWidth = playGroundWidth;
		} else if (limitMaxWidth <= playGroundWidth) {
			renderWidth = limitMaxWidth;
		}

		if (limitMaxHeight > playGroundHeight && playGroundHeight > 400) { // min-height
			renderHeight = playGroundHeight;
		} else if (limitMaxHeight <= playGroundHeight) {
			renderHeight = limitMaxHeight;
		}

		this.playGroundFrame.style.width = `${renderWidth}px`;
		this.playGroundFrame.style.height = `${renderHeight}px`;
		this.setState({
			playgroundframewidth: renderWidth,
			playgroundframeheight: renderHeight,
		});
		if (this.state.onStopDraggingEvent) {
			this.state.onStopDraggingEvent();
		}
	}
	
	setStopDraggingEvent = (event) => {
		this.setState({ 
			onStopDraggingEvent: event 
		})
	}

	render() {
		const locationHref = window.location.href.split('/').reverse();
		const isInExercise = locationHref[1] === 'exercise' && !locationHref.includes('teacher');
		const displayerClassName = cx('PlaygroundFrame', {"in-exercise-displayer": isInExercise});

		return (
			<Draggable 
				bounds={(isInExercise) ? {top: 0, left: -(document.documentElement.clientWidth - this.state.playgroundframewidth - 160), right: 0, bottom: (document.documentElement.clientHeight - this.state.playgroundframeheight - 158)} : "body"} 
				cancel={"input,.monaco-editor,.form-control,.resize-cont, .playground-run-btn, .playground-close-btn"} 
				disabled={this.isFullScreen}
			>
				<div className={displayerClassName} ref={ref => { this.playGroundFrame = ref }}>
					{!this.state.isHideForFullScreen &&
						<Fragment>
							<Playground
								toggleFullScreen={this.toggleFullScreen}
								isFullScreen={this.isFullScreen}
								editorId={this.props.editorId}
								hidePlayground={this.props.hidePlayground}

								onResize={this.onAdjustSidebarItemWidth}
								playgroundframewidth={this.state.playgroundframewidth}
								playgroundframeheight={this.state.playgroundframeheight}
								
								setStopDraggingEvent={this.setStopDraggingEvent}
							/>
							<ResizeAngle
								className="resize-cont"
								onResize={this.onAdjustPlayGroundItemSize}
							/>
						</Fragment>
					}
				</div>
			</Draggable>
		)
	}
}

export default PlaygroundFrame;
