// users
export const USERS_COLLECTION = "Users";
export const SIGNUP_USER= "signup-user";

//Permissions
export const STAFF_PERMISSIONS = "staffPermissions";

//InternalMailReceivers
export const INTERNAL_MAIL_RECEIVER = "internalMailReceiver";

// courses
export const COURSES_COLLECTION = "courses";
export const COURSE_PACKAGE_COLLECTION = "CoursePackages";
export const CLONE_COURSE_RECORDS_COLLECTION = "cloneCourseRecords";

export const STUDENT_STATUS_COLLECTION = "studentStatus";

export const MATERIALS_COLLECTION = "materials";
export const EXERCISES_COLLECTION = "exercises";
export const TESTS_COLLECTION = "tests";
export const QUESTION_GROUPS_COLLECTION = "questionGroups";

export const COMMENTS_COLLECTION = "comments";
export const QUESTIONS_COLLECTION = "questions";

export const TEST_ATTEMPTS_COLLECTION = "mockTestAttempts";

export const TRANSLATION_INFO_COLLECTION = "translationInfo";

// registrations
export const REGIS_COLLECTION = "course_registration";
export const REGIS_FORM_COLLECTION = "RegistrationForm";
export const GROUP_DISCOUNT_CODE_RECORDS_COLLECTION = "GroupDiscountCodeRecords";

export const GOODS = "Goods";
export const NEW_COUPON_RECORDS= "NewCouponRecords";

// crm
export const CRM_LABEL_COLLECTION = "crmStudentStatusLabel";

// domain & environment
export const DOMAIN_ACCESS_LIST = "authorizedUnit";
export const TEST_ENV_ACCESS_LIST = "testEnvAuthorizedUnit";
export const UNIT_PERMISSIONS_COLLECTION = "unitPermissions";

// chatGPT
export const CHAT_GPT_AUTHORIZED_UNIT_COLLECTION = "chatGPTAuthorizedUnit";
export const GPT_MESSAGES_COLLECTION = 'GPTMessages';
export const GPT_SESSION_HISTORIES_COLLECTION = 'GPTSessionHistories';

// aiMate
export const AI_MATE_EMAIL_VERIFICATIONS_COLLECTION = "aiMateEmailVerifications";
export const AI_MATE_USER_TRIALS_COLLECTION = "AImateUserTrials";
export const AI_MATE_SUBSCRIPTION_PLANS_COLLECTION = "AImateSubscriptionPlans";
export const AI_MATE_USER_SUBSCRIPTIONS_COLLECTION = "AImateUserSubscriptions";
export const AI_MATE_CHAT_GPT_QUESTION_COUNT_COLLECTION = "AImateChatGPTQuestionCount";

// resource center
export const SHARED_RESOURCES_COLLECTION ="SharedResources";

//ParentPlat
export const CONTACT_BOOK = "contactBook";
export const HAPPYTABLE = "happyTable";
export const STUDENTWORKS = "studentWorks";

//家教 tutorStudent
export const TUTOR_SETTING_COLLECTION = 'tutorSetting';

// login data ?
export const HISTORY_COLLECTION = 'history';

//School trial SentInfo
export const SCHOOL_TRIAL_SENT_INFO_COLLECTION = 'schoolTrialSentInfo';

//Free trial SentInfo
export const FREETRIAL_SENT_INFO_COLLECTION = 'freeTrialSentInfo';

//Auto Join 自動開通課程(免費/付費/Clone/schoolTrial)
export const AUTO_JOIN_COURSES_COLLECTION = 'autoJoinCourses';
//
export const COURSE_VALIDITY_PERIOD_COLLECTION = 'courseValidityPeriod';

//School Certificate Authorization
export const SCHOOL_CERTIFICATION_COLLECTION = 'schoolCertification';

//CSFC User and registed Info
export const CSFC_USERS_COLLECTION = 'csfcUsers';
export const CSFC_TEST_EVENTS_COLLECTION = 'csfcTestEvents';