import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import './Modal.scss';

ReactModal.setAppElement('#app');

class Modal extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		isOpen: PropTypes.bool, //set to be show Modal or not
		onClose: PropTypes.func, // set this to onClick Overlay will default call this function to close
		isShowClose: PropTypes.bool, //set to render cancel crossIcon rightTop side
		shouldCloseOnOverlayClick: PropTypes.bool,
		shouldCloseOnEsc: PropTypes.bool,
	};

	static defaultProps = {
		className: '',
		overlayClassName: '',
		buttonClassName: '',
		isShowClose: true,
		shouldCloseOnOverlayClick: true,
		shouldCloseOnEsc: true,
	};

	closeModal = () => {
		this.modal.portal.close();
		if (this.props.onClose) this.props.onClose();
	};

	render() {
		const { className, overlayClassName, buttonClassName, children, isShowClose, onClose, ...otherProps } = this.props;
		const css = classnames('coding-bar-modal', className);
		const overlay = classnames('coding-bar-modal-overlay', overlayClassName);
		const button = classnames('icon icon-close-light modal-close-button', buttonClassName);

		return (
			<ReactModal
				className={css}
				overlayClassName={overlay}
				onRequestClose={onClose}
				{...otherProps}
				ref={(ref) => {
					this.modal = ref;
				}}
			>
				{isShowClose && <button className={button} onClick={this.closeModal}/>}
				{children}
			</ReactModal>
		);
	}
}

export default Modal;
