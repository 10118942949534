import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { removeToast } from 'redux/reducers/app';
import Toast from 'components/common/Toast/Toast';

const ToastQueue = ({ dispatch, toasts }) => {
	const [curToast, setCurToast] = useState(null);

	useEffect(() => {
		if (toasts.size < 1) return;
		setCurToast(toasts.get(0).toObject());
	}, [toasts]);

	const removeCurToast = () => {
		dispatch(removeToast());
	};

	if (toasts.size < 1 || !curToast) return null;

	return <Toast {...curToast} onClose={removeCurToast} />;
};

ToastQueue.propTypes = {
	toasts: ImmutablePropTypes.contains(
		{
			actionText: PropTypes.string,
			isShowAtBottom: PropTypes.bool,
			msBeforeClose: PropTypes.number,
			onClickAction: PropTypes.func,
			toastSubText: PropTypes.string,
			toastText: PropTypes.string,
			toastType: PropTypes.oneOf(['alert', 'success']),
			id: PropTypes.string,
		}
	),
	dispatch: PropTypes.func,
};

const mapStateToProps = (state) => {
	const toasts = state.app.get('toasts');

	return {
		toasts,
	};
};
export default connect(mapStateToProps)(ToastQueue);
