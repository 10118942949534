import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Checkbox.scss';

export default class Checkbox extends React.PureComponent {
	static propTypes = {
		className: PropTypes.string,
		style: PropTypes.object,
		name: PropTypes.string,
		id: PropTypes.string,
		type: PropTypes.string,
		defaultChecked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
		checked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
		disabled: PropTypes.bool,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		onClick: PropTypes.func,
		tabIndex: PropTypes.string,
		readOnly: PropTypes.bool,
		autoFocus: PropTypes.bool,
		value: PropTypes.any,
		iconType: PropTypes.string,
	};
	static defaultProps = {
		className: '',
		style: {},
		type: 'checkbox',
		defaultChecked: false,
		onFocus() {},
		onBlur() {},
		onChange() {},
	};

	constructor(props) {
		super(props);

		const { checked, defaultChecked } = props;
		const _checked = checked !== undefined ? checked : defaultChecked;

		this.state = {
			checked: _checked,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (Reflect.has(nextProps, 'checked')) {
			this.setState({
				checked: nextProps.checked,
			});
		}
	}

	focus() {
		this.input.focus();
	}

	blur() {
		this.input.blur();
	}

	handleChange = (e) => {
		const { props } = this;
		e.stopPropagation();
		if (props.disabled) {
			return;
		}
		if (!('checked' in props)) {
			this.setState({
				checked: e.target.checked,
			});
		}
		props.onChange({
			target: {
				...props,
				checked: e.target.checked,
			},
			stopPropagation() {
				e.stopPropagation();
			},
			preventDefault() {
				e.preventDefault();
			},
			nativeEvent: e.nativeEvent,
		});
	};

	saveInput = (node) => {
		this.input = node;
	};

	render() {
		const {
			prefixCls,
			className,
			style,
			name,
			id,
			type,
			disabled,
			viewOnly,
			readOnly,
			tabIndex,
			onClick,
			onFocus,
			onBlur,
			autoFocus,
			value,
			iconType = 'icon-checkbox-checked',
			...others
		} = this.props;

		const globalProps = Object.keys(others).reduce((prev, key) => {
			if (key.substr(0, 5) === 'aria-' || key.substr(0, 5) === 'data-' || key === 'role') {
				prev[key] = others[key];
			}
			return prev;
		}, {});

		const { checked } = this.state;
		const classString = classNames('coding-bar-checkbox', className, {
			'coding-bar-checkbox-checked': checked,
			'coding-bar-checkbox-disabled': disabled,
			'coding-bar-checkbox-view-only': viewOnly,
		});

		return (
			<span className={classString} style={style}>
				<input
					name={name}
					id={id}
					type={type}
					readOnly={readOnly}
					disabled={disabled}
					tabIndex={tabIndex}
					className="coding-bar-checkbox-input"
					checked={!!checked}
					onClick={onClick}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={this.handleChange}
					autoFocus={autoFocus}
					ref={this.saveInput}
					value={value}
					{...globalProps}
				/>
				<span className="coding-bar-checkbox-inner">
					<i className={iconType || 'icon-checkbox-checked'} />
				</span>
			</span>
		);
	}
}
