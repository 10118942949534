import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import './Toast.scss';
import { ReactComponent as AlertIcon } from 'design/images/icons/icon-error.svg';
import { ReactComponent as SuccessIcon } from 'design/images/icons/icon-done.svg';
import { ReactComponent as CloseIcon } from 'design/images/icons/icon-close-light.svg';

const Toast = ({
	msBeforeClose, // milliseconds (default: 5000)
	toastType, // alert || success (default: 'success')
	toastText,
	toastSubText, // sub text has max width 280px, ie. test title
	actionText,
	onClickAction,
	onClose,
	isShowAtBottom, // (default: false)
	id,
}) => {
	const [startCounter, setStartCounter] = useState(true);
	useEffect(() => {
		if (startCounter) {
			const timer = setTimeout(() => {
				onClose();
			}, msBeforeClose || 5000);

			return () => clearTimeout(timer);
		}
	}, [startCounter, id]);

	const getIconType = (type = 'success') => {
		if (type === 'success') {
			return <SuccessIcon className="toast-icon" />;
		}
		if (type === 'alert') {
			return <AlertIcon className="toast-icon" />;
		}
	};

	return (
		<div
			className={cx('Toast', `${toastType || 'success'}-type`, { 'toast-bottom': isShowAtBottom })}
			onMouseEnter={() => setStartCounter(false)}
			onMouseLeave={() => setStartCounter(true)}>
			<div className="toast-message-cont">
				{getIconType(toastType)}
				<span className="toast-text">{toastText}</span>
				{toastSubText && <span className="toast-sub-text">{toastSubText}</span>}
			</div>

			<div className="toast-action-cont">
				{actionText && (
					<span className="toast-action-text" onClick={onClickAction} aria-hidden="true">
						{actionText}
					</span>
				)}

				<CloseIcon className="toast-close-icon" onClick={onClose} />
			</div>
		</div>
	);
};

Toast.propTypes = {
	actionText: PropTypes.string,
	isShowAtBottom: PropTypes.bool,
	msBeforeClose: PropTypes.number,
	onClickAction: PropTypes.func,
	onClose: PropTypes.func,
	toastSubText: PropTypes.string,
	toastText: PropTypes.string,
	toastType: PropTypes.oneOf(['alert', 'success']),
	id: PropTypes.string,
};

export default Toast;
