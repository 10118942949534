
import * as cons from '../constants/registration';
import moment from 'moment';
import { getUUID, removeConfirmCloseListener, sendEmail } from '../../shared/util/utils';
import { API_URL, API_ENV, ENV, STUFF_MAIL_LIST } from '../../settings';

// const regex = /.+-.+-.+T.+/;
const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

export function getInitialState() {
  return {
    step: 0,
    formId: "",
    payId: "",
    payLink: "",
    isWaitForRegistration: false,
    isCheckUserCount: false,

    settings: {
      enabled: true,
      isGroupRegistration: false,
      title: "",
      subtitle: "",
      description: "",
      placeClassOptions: [
        // {
        //   place: "台北",
        //   classOptions: [
        //     {
        //       value: "台北A班",
        //       subtitle: "每週六下午 01:30 - 04:30",
        //       description: "台灣文創訓練中心，台北市松江路131號2樓之4 (捷運松江南京站7號出口步行約3-5分鐘)"
        //     }
        //   ]
        // },
      ],
      priceOrigin: 0,
      // enableGroupDiscount: false,
      priceGroupDiscount: [],
      enableOldStudentDiscount: false,
      priceOldStudentDiscount: 0,
      enableMultiStudentDiscount: false,
      priceMultiStudentDiscount: [],
      enableCouponCode: false,
      enableTaxNumber: false,

      enableInfoFrom: true,
      infoFromOptions: [
        // "臉書", "LINE"
      ],
      enableGradeSelect: true,
      gradeSelectOptions: ["五年級", "六年級", "七年級", "八年級", "九年級", "高一", "高二", "高三",],
      enableVegan: false,
      enableRentLaptop: false,
      enableBirthdayAndIdentityCardNumber: true,
      enableOthers: false,
      othersOptions: ["自行駕車，需要停車證"],
      policyUrl: "",

      emailPaymentTitle: "",
      emailPaymentContent: "",
      emailNotPaidTitle: "",
      emailNotPaidContent: "",

      GlobalGACodeList: [],
      GACodeList: [],
      GlobalFACodeList: [],
      FACodeList: [],
    },

    // step 1
    placeSelected: "",

    isFirstGroupRegistration: false, // 團報報名表才用的到，表示此user是團報第一人
    groupCountSelected: null,        // 團報報名表才用的到，表示此團報方案的人數
    groupCode: "",            // 團報報名表才用的到，團報識別碼
    maxNumOfStudent: null,    // 團報報名表才用的到，表示此團還剩下多少報名名額

    // step 2
    classSelected: "",
    numOfStudent: 1,

    // step 3
    isOldStudent: false,
    couponCodeEntered: "",
    couponCodeDiscount: null,
    taxNumber: "",
    payAmount: 0,
    discountType: cons.DISCOUNT.NONE,

    // step 4
    currentStudentIndex: 0,
    studentInfoList: [
      // { index, name, identityCardNumber, birthday, email, phone, address, school, grade, needVegan, needRentLaptop, needOthers: {} }
    ],

    // step 5
    infoFromSelected: {},
    contactName: "",
    parentEmail: "",
    contactPhone: "",
    remark: "",

  };
}

export default function reducer(state = getInitialState(), action = {}) {
  const { type, payload } = action
  switch (type) {
    case cons.INIT:
      return getInitialState()

    case cons.GET_REGISTRATION_SETTING: {
      let {
        formId,
        enabled, isGroupRegistration, title, subtitle, description, placeClassOptions, priceOrigin, priceGroupDiscount, enableOldStudentDiscount, priceOldStudentDiscount, enableMultiStudentDiscount, priceMultiStudentDiscount, enableCouponCode, priceCouponCodeDiscount, enableTaxNumber, enableInfoFrom, infoFromOptions, 
        enableGradeSelect=state.settings.enableGradeSelect,
        gradeSelectOptions=state.settings.gradeSelectOptions,
        enableVegan, enableRentLaptop, enableBirthdayAndIdentityCardNumber, enableOthers, othersOptions, policyUrl,
        emailPaymentTitle, emailPaymentContent, emailNotPaidTitle, emailNotPaidContent,
        GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
      } = payload
      return {
        ...state,
        formId,
        settings: {
          enabled, isGroupRegistration, title, subtitle, description, placeClassOptions, priceOrigin, priceGroupDiscount, enableOldStudentDiscount, priceOldStudentDiscount, enableMultiStudentDiscount, priceMultiStudentDiscount, enableCouponCode, priceCouponCodeDiscount, enableTaxNumber, enableInfoFrom, infoFromOptions, 
          enableGradeSelect, gradeSelectOptions, 
          enableVegan, enableRentLaptop, enableBirthdayAndIdentityCardNumber, enableOthers, othersOptions, policyUrl,
          emailPaymentTitle, emailPaymentContent, emailNotPaidTitle, emailNotPaidContent,
          GlobalGACodeList, GACodeList, GlobalFACodeList, FACodeList
        }
      }
    }
    case cons.GET_SAVED_FORM_DATA: {
      let {
        formId, payId,
        placeSelected, classSelected, numOfStudent, groupCode, isOldStudent, couponCodeEntered, taxNumber, payAmount,
        studentInfoList,
        infoFromSelected, contactName, parentEmail, contactPhone, remark,
      } = payload
      return {
        ...state,
        formId,
        payId,
        placeSelected,
        classSelected,
        numOfStudent,
        groupCode,
        isOldStudent,
        couponCodeEntered,
        taxNumber,
        payAmount,
        studentInfoList: createStudentInfoList(numOfStudent, studentInfoList),
        infoFromSelected, contactName, parentEmail, contactPhone, remark,
        step: 5
      }
    }

    case cons.SET_IS_OLD_STUDENT: {
      return {
        ...state,
        isOldStudent: payload.isOldStudent
      }
    }

    case cons.SAVE_STEP_ONE: {
      let classSelected = state.placeSelected == payload.placeSelected ? state.classSelected : ""
      return {
        ...state,
        placeSelected: payload.placeSelected,
        classSelected: classSelected,
      }
    }
    case cons.SAVE_STEP_ONE_GROUP: {
      if (payload.isFirstGroupRegistration) {
        let classSelected = state.placeSelected == payload.placeSelected ? state.classSelected : ""
        console.log("state.numOfStudent", state.numOfStudent, "payload.maxNumOfStudent", payload.maxNumOfStudent, "bigger?", state.numOfStudent > payload.maxNumOfStudent, "result", state.numOfStudent > payload.maxNumOfStudent ? payload.maxNumOfStudent : state.numOfStudent)
        return {
          ...state,
          isFirstGroupRegistration: payload.isFirstGroupRegistration,
          groupCountSelected: parseInt(payload.groupCountSelected),
          maxNumOfStudent: payload.groupCountSelected,
          groupCode: payload.groupCode,
          placeSelected: payload.placeSelected,
          classSelected,

          numOfStudent: state.numOfStudent > payload.groupCountSelected ? payload.groupCountSelected : state.numOfStudent // 若前後選到的團報方案不同，則最大人數可能會不同，此時要檢查上次的numOfStudent是否大於這次的max
        }
      }
      else {
        return {
          ...state,
          isFirstGroupRegistration: payload.isFirstGroupRegistration,
          groupCountSelected: payload.groupCountSelected,
          maxNumOfStudent: payload.maxNumOfStudent,
          groupCode: payload.groupCode,
          placeSelected: payload.placeSelected,
          classSelected: payload.classSelected,

          numOfStudent: state.numOfStudent > payload.maxNumOfStudent ? payload.maxNumOfStudent : state.numOfStudent // 若前後選到的團報方案不同，則最大人數可能會不同，此時要檢查上次的numOfStudent是否大於這次的max
        }
      }
    }

    case cons.SAVE_STEP_TWO: {
      let { classSelected, numOfStudent } = payload
      let studentInfoList = createStudentInfoList(numOfStudent, state.studentInfoList)
      return {
        ...state,
        classSelected,
        numOfStudent,
        studentInfoList
        // studentInfoList: Array(payload.numOfStudent).fill(createStudent())
      }
    }
    case cons.SAVE_STEP_THREE: {
      return {
        ...state,
        infoFromSelected: payload.infoFromSelected,
        contactName: payload.contactName,
        parentEmail: payload.parentEmail,
        contactPhone: payload.contactPhone,
        taxNumber: payload.useTaxNumber ? payload.taxNumber : "",
        couponCodeEntered: payload.couponCodeEntered,
        couponCodeDiscount: payload.couponCodeDiscount || null,
        remark: payload.remark
      }
    }
    case cons.SAVE_STEP_THREE_GROUP: {
      return {
        ...state,
        taxNumber: payload.useTaxNumber ? payload.taxNumber : "",
      }
    }

    case cons.SET_CHEAPEST_PAY_AMOUNT: {
      return {
        ...state,
        payAmount: payload.payAmount,
        discountType: payload.discountType
      }
    }

    case cons.SAVE_CURRENT_STUDENT_INFO: {
      let { studentInfoList, currentStudentIndex } = state
      studentInfoList[currentStudentIndex] = payload.currentStudentInfo
      return {
        ...state,
        studentInfoList
      }
    }
    case cons.GO_PREV_STUDENT: {
      return {
        ...state,
        currentStudentIndex: state.currentStudentIndex - 1
      }
    }
    case cons.GO_NEXT_STUDENT: {
      return {
        ...state,
        currentStudentIndex: state.currentStudentIndex + 1
      }
    }
    case cons.SET_PAY_LINK: {
      return {
        ...state,
        payLink: payload.payLink
      }

    }

    case cons.GO_PREV_STEP: {
      return {
        ...state,
        step: state.step - 1
      }
    }

    case cons.GO_NEXT_STEP: {
      return {
        ...state,
        step: (state.step + 1)
      }
    }

    case cons.WAIT_FOR_REGISTRATION: {
      return {
        ...state,
        isWaitForRegistration: true,
        step: (state.step + 1)
      }
    }

    case cons.CHECK_USER_COUNT: {
      return {
        ...state,
        isCheckUserCount: true
      }
    }

    case cons.CANCEL_CHECK_USER_COUNT: {
      return {
        ...state,
        isCheckUserCount: false
      }
    }

    default:
      return state;
  }
}

function createStudentInfo(studentInfo = {}, idx) {
  // 用此function確保student的各個欄位不會是undefined
  const {
    index = idx,
    name = "",
    identityCardNumber = "",
    birthday = new moment(''),
    email = "",
    phone = "",
    address = "",
    school = "",
    grade = "",
    needVegan = false,
    needRentLaptop = false,
    needOthers = {}
  } = studentInfo
  console.log("createStudentInfo",
    name, identityCardNumber, birthday, email, phone, address, school, grade, needVegan, needRentLaptop, needOthers
  )
  return {
    index, name, identityCardNumber, birthday, email, phone, address, school, grade, needVegan, needRentLaptop, needOthers
  }
}
function createStudentInfoList(numOfStudent, studentInfoList = []) {
  const newList = studentInfoList.map((item, idx) => createStudentInfo(item, idx))
  if (numOfStudent < studentInfoList.length) {
    for (let i = numOfStudent; i < studentInfoList.length; i++) {
      newList.pop()
    }
  }
  else {
    for (let i = newList.length; i < numOfStudent; i++) {
      newList.push(createStudentInfo({}, i))
    }
  }
  return newList
}

function filterActivePrice(priceList, singlePrice = false) {
  if (!priceList || !priceList.length) {
    if (singlePrice) return null
    else return []
  }
  else {
    const filteredList = priceList.filter(price => {
      const mToday = moment(new Date())

      // workAround: 2020-05-03T16:00:00.000Z format will result in isSameOrXXX error. it should fix when update forms' startTime and endTime.
      const mStartTime = price.startTime.match && price.startTime.match(regex) ?
        moment(price.startTime, 'YYYY/MM/DD') + 24 * 60 * 60 * 1000 : moment(price.startTime, 'YYYY/MM/DD');
      const mEndTime = price.endTime.match && price.endTime.match(regex) ?
        moment(price.endTime, 'YYYY/MM/DD') + 24 * 60 * 60 * 1000 : moment(price.endTime, 'YYYY/MM/DD');

      console.log('the price: ', price)
      console.log('mStartTime: ', mStartTime, ' mEndTime: ', mEndTime)
      console.log('startResult: ', mToday.isSameOrAfter(mStartTime, 'day'), 'endResult: ', mToday.isSameOrBefore(mEndTime, 'day'))

      return (
        mToday.isSameOrAfter(mStartTime, 'day')
        &&
        mToday.isSameOrBefore(mEndTime, 'day')
      )
    }).map(price => {
      if (price.numOfStudent) price.numOfStudent = parseInt(price.numOfStudent)
      if (price.payAmount) price.payAmount = parseInt(price.payAmount)
      if (price.maxCount) price.maxCount = parseInt(price.maxCount)
      return price
    })
    if (singlePrice) return filteredList[0]
    else return filteredList
  }
}
export function initRegistration() {
  return {
    type: cons.INIT
  }
}

export function getRegistrationSetting(formId, callback) {
  return (dispatch, getState) => {
    codingbarApi.getRegistrationService().getRegistrationForm(formId).then(data => {
      console.log("THEN RESULT", data)
      if (!data) {
        return dispatch({
          type: cons.GET_REGISTRATION_SETTING,
          payload: {
            enabled: false
          }
        })
      }


      const payload = {
        ...data,
        priceOrigin: filterActivePrice(data.priceOrigin, true) || { payAmount: Number.MAX_SAFE_INTEGER },
        priceGroupDiscount: filterActivePrice(data.priceGroupDiscount),
        priceOldStudentDiscount: filterActivePrice(data.priceOldStudentDiscount, true),
        priceMultiStudentDiscount: filterActivePrice(data.priceMultiStudentDiscount),
        priceCouponCodeDiscount: filterActivePrice(data.priceCouponCodeDiscount),
      }
      console.log(payload)

      dispatch({
        type: cons.GET_REGISTRATION_SETTING,
        payload
      })
      if (callback) callback(data)
      dispatch({ type: cons.GO_NEXT_STEP })
    })
  }
}

export function checkOldStudentDiscount() {
  // todo
  return (dispatch, getState) => {
    setTimeout(() => {
      dispatch({
        type: cons.SET_IS_OLD_STUDENT,
        payload: { isOldStudent: true }
      })
    }, 500)
  }
}

export function saveStepOne({ placeSelected }) {
  return {
    type: cons.SAVE_STEP_ONE,
    payload: { placeSelected }
  }
}
export function saveStepOneGroup(data) {
  return {
    type: cons.SAVE_STEP_ONE_GROUP,
    payload: data
  }
}
export function saveStepTwo({ classSelected, numOfStudent }) {
  return {
    type: cons.SAVE_STEP_TWO,
    payload: { classSelected, numOfStudent }
  }
}

export function saveStepThree({ infoFromSelected, contactName, parentEmail, contactPhone, useTaxNumber, taxNumber, couponCodeEntered, couponCodeDiscount, remark, }) {
  return {
    type: cons.SAVE_STEP_THREE,
    payload: { infoFromSelected, contactName, parentEmail, contactPhone, useTaxNumber, taxNumber, couponCodeEntered, couponCodeDiscount, remark }
  }
}
export function saveStepThreeGroup({ useTaxNumber, taxNumber }) {
  return {
    type: cons.SAVE_STEP_THREE_GROUP,
    payload: { useTaxNumber, taxNumber }
  }
}

export function calculateCheapestPrice() {
  return (dispatch, getState) => {
    const { registration } = getState()
    const numOfStudent = registration.numOfStudent
    const isGroupRegistration = registration.settings.isGroupRegistration
    const { payAmountPerStudent, discountType } = isGroupRegistration ? _calculatePriceGroup(registration) : _calculatePriceNormal(registration)

    dispatch({
      type: cons.SET_CHEAPEST_PAY_AMOUNT,
      payload: { payAmount: payAmountPerStudent * numOfStudent, discountType }
    })
  }
}
function _calculatePriceNormal(registration) {
  const { numOfStudent, couponCodeDiscount, settings } = registration
  const {
    priceOrigin,
    enableOldStudentDiscount, priceOldStudentDiscount,
    enableMultiStudentDiscount, priceMultiStudentDiscount,
    enableCouponCode,
  } = settings

  let payAmountPerStudent = priceOrigin.payAmount
  let discountType = cons.DISCOUNT.NONE
  console.log("priceOrigin payAmountPerStudent", payAmountPerStudent, discountType)

  if (enableMultiStudentDiscount) {
    priceMultiStudentDiscount.forEach(discount => {
      if (numOfStudent >= discount.numOfStudent) {
        if (discount.payAmount < payAmountPerStudent) {
          payAmountPerStudent = discount.payAmount
          discountType = cons.DISCOUNT.MULTI
        }
      }
    })
    console.log("enableMultiStudentDiscount payAmountPerStudent", payAmountPerStudent, discountType)
  }

  if (enableCouponCode && couponCodeDiscount) {
    if (couponCodeDiscount < payAmountPerStudent) {
      payAmountPerStudent = couponCodeDiscount
      discountType = cons.DISCOUNT.COUPON
    }
  }
  return { payAmountPerStudent, discountType }
}
function _calculatePriceGroup(registration) {
  const { settings, groupCountSelected } = registration
  const {
    priceGroupDiscount
  } = settings

  const selectedDiscount = priceGroupDiscount.find(discount => {
    return discount.numOfStudent === groupCountSelected
  }) || priceGroupDiscount[0]
  return {
    payAmountPerStudent: selectedDiscount.payAmount,
    discountType: cons.DISCOUNT.GROUP
  };
}

export function saveAndGoPay(isWaitForRegistration) {
  return (dispatch, getState) => {
    const payId = getUUID()
    const { registration } = getState()

    const studentInfoList = registration.studentInfoList.map(info => {
      const { birthday, ...others } = info
      return {
        ...others,
        birthday: birthday ? birthday.format('YYYY/MM/DD') : ""
      }
    })


    const data = {
      version: "20200715",
      isNewGradeFormat: true,
      formId: registration.formId,
      payId,
      placeSelected: registration.placeSelected,
      classSelected: registration.classSelected,
      numOfStudent: registration.numOfStudent,
      studentInfoList: studentInfoList,
      infoFromSelected: registration.infoFromSelected,
      contactName: registration.contactName,
      parentEmail: registration.parentEmail,
      contactPhone: registration.contactPhone,

      taxNumber: registration.taxNumber,
      payAmount: parseInt(registration.payAmount),
      discountType: registration.discountType,

      remark: registration.remark,
      isWaitForRegistration: isWaitForRegistration
    }

    if (registration.settings.isGroupRegistration) {
      data.groupCode = registration.groupCode
      data.isFirstGroupRegistration = registration.isFirstGroupRegistration
      if (registration.isFirstGroupRegistration) data.groupCount = parseInt(registration.groupCountSelected)
    }
    else {
      data.isOldStudent = registration.isOldStudent
      data.couponCodeEntered = registration.couponCodeEntered
    }

    const payLink = `${API_URL.PAY}/payment/registration/order/${payId}`;

    codingbarApi.getRegistrationService().createSignUpUser(data).then((res) => {
      if (data.isWaitForRegistration) {
        throw { error: "waitForRegistration" }
      }
      dispatch(setPayLink(payLink))
      return sendEmailNotPaid(data, registration.settings, res, payLink)
    }).then((res) => {
      console.log("sendEmailNotPaid", res)
      return sendEmailToStuffs(data, registration.settings, res, payLink)
    }).then((res) => {
      console.log("sendEmailToStuffs", res)
      dispatch(goNextStep())    // from step 6 to step 7
      // removeConfirmCloseListener()
      // window.location.href = payLink
    }).catch(err => {
      if (err && err.error === 'waitForRegistration') {
        const formTitle = registration.settings ? registration.settings.title : '';
        const receiverList = data.studentInfoList.map(stu => stu.email);
        receiverList.push(data.parentEmail);
        sendEmail(STUFF_MAIL_LIST.concat(receiverList), `${formTitle} 候補通知信`, `<div> 目前此梯次已滿員，已幫您排候補中，候補成功專人將與您聯絡! </div>
        <div> 地點: ${registration.placeSelected} </div>
        <div> 班別: ${registration.classSelected} </div>
        <div>感謝您的參與與支持，如有任何問題或建議，</div>
        <div>歡迎您透過LINE與我們反應與交流，謝謝！</div>
        <div>LINE 帳號：@CodingBar（或直接點選網址：http://nav.cx/1pmQ6m8 ）</div>
        <div>也請務必將 service@codingbar.ai 設為常用聯絡人，避免漏掉後續重要通知！</div>
        <div>　</div>
        <div>如有任何問題或建議，請於上班時間來電 02-7717-9386 或透過 LINE 聯繫我們，此為系統自動發送信件請勿直接回覆，謝謝。</div>
        `).then(() => {
          dispatch(goToWaitingStep())
        }).catch(err => {
          dispatch(goToWaitingStep())
        });
      }
    })
  }
}

function setPayLink(payLink) {
  return {
    type: cons.SET_PAY_LINK,
    payload: { payLink }
  }
}

function sendEmailNotPaid(registrationData, registrationSettings, apiResponse, payLink) {
  const { studentInfoList, payAmount, parentEmail } = registrationData
  const { title, emailNotPaidTitle, emailNotPaidContent } = registrationSettings
  const { } = { apiResponse }

  var studentListStr = ""
  for (const studentInfo of studentInfoList) {
    studentListStr += `
    <div>學生名字： ${studentInfo.name}</div>
    <div>學生帳號： ${studentInfo.email}</div>`
  }

  var emailTitle = emailNotPaidTitle
  emailTitle = emailTitle.replace(/{{title}}/g, title)

  var emailContent = emailNotPaidContent
  emailContent = emailContent.replace(/{{title}}/g, title)
  emailContent = emailContent.replace(/{{payAmount}}/g, payAmount)
  emailContent = emailContent.replace(/{{payLink}}/g, payLink)
  emailContent = emailContent.replace(/{{studentList}}/g, studentListStr)

  const receiverList = studentInfoList.map(stu => stu.email)
  receiverList.push(parentEmail)

  console.log(receiverList, emailTitle, emailContent)
  return sendEmail(receiverList, emailTitle, emailContent)
}

function sendEmailToStuffs(registrationData, registrationSettings, apiResponse) {
  const { placeSelected, classSelected, numOfStudent, remark, studentInfoList, payAmount, contactName, parentEmail, contactPhone } = registrationData
  const { title, emailNotPaidTitle, emailNotPaidContent } = registrationSettings
  const { } = { apiResponse }

  var studentListStr = ""
  for (const studentInfo of studentInfoList) {
    studentListStr += `
    <div>學生名字： ${studentInfo.name}</div>
    <div>學生帳號： ${studentInfo.email}</div>`
  }

  var emailTitle = `${title} 報名確認函`

  var emailContent =
    `<p>課程: ${title}</p>\n
  <p>報名地點及班別: ${placeSelected} ${classSelected}</p>
  <p>報名人數: ${numOfStudent}</p>
  <p>${studentListStr}</p>\n
  <p>價錢: ${payAmount}</p>\n
  <p>聯絡人姓名: ${contactName}<br />\n
  聯絡人Email: ${parentEmail}<br />\n
  聯絡人電話: ${contactPhone}<br />\n
  備註: ${remark}</p>`

  console.log(STUFF_MAIL_LIST, emailTitle, emailContent)
  return sendEmail(STUFF_MAIL_LIST, emailTitle, emailContent)
}


export function saveCurrentStudentInfo(currentStudentInfo) {
  return {
    type: cons.SAVE_CURRENT_STUDENT_INFO,
    payload: {
      currentStudentInfo: createStudentInfo(currentStudentInfo)
    }
  }

}

export function goPrevStudent() {
  return { type: cons.GO_PREV_STUDENT }
}
export function goNextStudent() {
  return { type: cons.GO_NEXT_STUDENT }
}

export function goPrevStep() {
  return {
    type: cons.GO_PREV_STEP
  }
}
export function goNextStep() {
  return {
    type: cons.GO_NEXT_STEP
  }
}

export function goToWaitingStep() {
  return {
    type: cons.WAIT_FOR_REGISTRATION
  }
}

export function checkUserCount() {
  return {
    type: cons.CHECK_USER_COUNT
  }
}

export function cancelCheckUserCount() {
  return {
    type: cons.CANCEL_CHECK_USER_COUNT
  }
}


