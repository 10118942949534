export const INIT = 'EXMNG_INIT'

export const SET_FREE_CODE = 'EXMNG_SET_FREE_CODE'
export const SET_CHOICE = 'EXMNG_SET_CHOICE'
export const SET_REARRANGEMENT = 'EXMNG_SET_REARRANGEMENT'
export const SET_SLOT_FILLING = 'EXMNG_SET_SLOT_FILLING'
export const SET_CLOZE = 'EXMNG_SET_CLOZE'
export const SET_DRAGDROP = 'EXMNG_SET_DRAGDROP'
export const SET_EXERCISE = 'EXMNG_SET_EXERCISE'
export const SET_VIDEO = 'EXMNG_SET_VIDEO'
export const SET_STEP_BY_STEP = 'EXMNG_SET_STEP_BY_STEP'

export const SET_TOTAL_STEPS = 'EXMNG_SET_TOTAL_STEPS'
export const GO_PREV_STEP = 'EXMNG_GO_PREV_STEP'
export const GO_NEXT_STEP = 'EXMNG_GO_NEXT_STEP'
export const GO_STEP = 'EXMNG_GO_STEP'
export const SET_LOADING = 'EXMNG_SET_LOADING'
