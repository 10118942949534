import React, { Component } from 'react';
import cx from 'classnames';
import './HeaderIconList.scss';
import { connect } from 'react-redux';
import Button from '../../../components/core/Button';
import { Typography, Badge, withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose';
import { signOutAction, removeCSFCUser } from '../../../redux/reducers/app';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import * as settings from '../../../settings';
import { removeLocalItem } from '../../../shared/util/utils';
import { isCSFC, isAiMate, isBrandUI, isZoomAllowed } from 'shared/helper/permission';
import Icon from '../../../components/core/Icon';
import { translate } from 'react-i18next';

const StyledBadge = withStyles(() => ({
	badge: {
		padding: '2px',
		height: 'auto',
		fontSize: '10px',
	},
}))(Badge);

@translate('translations')
class HeaderIconList extends Component {
	static propTypes = {
		onClickAnnouncement: PropTypes.func.isRequired,
		openNoteDialog: PropTypes.func.isRequired,
		onClickFeedback: PropTypes.func.isRequired,
		onClickFreecode: PropTypes.func.isRequired,

		isMobile: PropTypes.bool,
		onClickItem: PropTypes.func,

		user: PropTypes.instanceOf(Immutable.Iterable),
	};

	static defaultProps = {
		isMobile: false,
		user: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			itemWidth: props.isMobile ? 12 : 'auto',
			zoomVisible: isZoomAllowed(),
			videoConferenceInfos: undefined,
		};
	}

	componentDidMount() {
		this.listenVideoConference();
		this.updateVideoConferenceStatus();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.updateVideoConferenceStatus();
	}

	updateVideoConferenceStatus() {
		if (!isZoomAllowed(this.props.user.toJS())) {
			return;
		}

		this.setState({
			videoConferenceIsActived: localStorage.getItem('videoConferenceIsActived') === 'true' ? true : false,
		});
	}

	listenVideoConference() {
		if (!isZoomAllowed()) {
			return;
		}

		if (this.props.user && this.props.user.get('role') !== 'student') {
			return;
		}

		const host = this;
		codingbarApi.getCourseService().listenVideoConference({
			onListChanged: (videoConferenceInfos) => {
				if (videoConferenceInfos && videoConferenceInfos.length > 0) {
					const infos = videoConferenceInfos.filter(
						(i) => i.isActived && i.studentIds.find((uid) => uid === host.props.user.get('uid'))
					);
					if (infos && infos.length > 0) {
						host.setState({
							zoomVisible: true,
							videoConferenceInfos: infos,
						});
						host.props.onClickZoom(infos, false);
					} else {
						host.setState({
							zoomVisible: false,
							videoConferenceInfos: undefined,
						});
						host.props.onClickZoom([], false);
					}
				} else {
					host.setState({
						zoomVisible: false,
						videoConferenceInfos: undefined,
					});
					host.props.onClickZoom([], false);
				}
			},
		});
	}

	renderAnnounceItem = () => {
		const { itemWidth } = this.state;
		const { ancmList, user, t } = this.props;
		const itemLabelClassName = cx('item-label', {
			'item-label-dark': ancmList.length <= 0,
		});

		const unReadAncmList = ancmList && ancmList.filter(ancm => !ancm.markAsReadUidList || !ancm.markAsReadUidList.includes(user.get('uid'))) || []
		const unReadAnnouncementsCount = unReadAncmList.length || 0
		const iconColor = unReadAnnouncementsCount > 0 ? '#FED602' : '#6F8190';

		return (
			<Grid item className="item" xs={itemWidth}>
				<Button variant="text" onClick={this.onClickAnnouncement}>
					<StyledBadge badgeContent={unReadAnnouncementsCount} color="error">
						<Icon name="notification" size={24} color={iconColor} />
					</StyledBadge>
					<Typography className={itemLabelClassName} color="inherit" variant="caption">
						{t('header.announcement')}
					</Typography>
				</Button>
			</Grid>
		);
	};

	onClickAnnouncement = () => {
		this.props.onClickItem && this.props.onClickItem();
		this.props.onClickAnnouncement();
	};

	openNoteDialog = () => {
		this.props.onClickItem && this.props.onClickItem();
		this.props.openNoteDialog();
	};

	onClickFeedback = () => {
		this.props.onClickItem && this.props.onClickItem();
		this.props.onClickFeedback();
	};

	onClickFreecode = () => {
		this.props.onClickItem && this.props.onClickItem();
		this.props.onClickFreecode();
	};

	onClickZoom = () => {
		// console.log("onClickZoom#", this.state.videoConferenceInfos)
		this.props.onClickItem && this.props.onClickItem();
		this.props.onClickZoom(this.state.videoConferenceInfos, true);
	};

	signOut = () => {
		// console.log("signOut")

		removeLocalItem('currentCourse');
		removeLocalItem('currentLesson');

		const { signOutAction, history } = this.props;
		if ((isAiMate('route') && isAiMate('workspace')) || isAiMate('aiMateOnly')) {
			history.push(`/AImate`);

		} else {
			history.push(`/login`);

		}
		signOutAction();
	};

	signOutCSFC = () => {
		// console.log("signOut")

		removeLocalItem('currentCourse');
		removeLocalItem('currentLesson');
		removeLocalItem('csfcUserUid');

		const { signOutAction, removeCSFCUser } = this.props;
		removeCSFCUser();
		this.props.history.push(`/csfcasia`);
		signOutAction();
	};

	render() {
		const { isMobile, user, t } = this.props;
		const usingBrandUI = isBrandUI();
		const isCSFCExam = isCSFC();
		const aiMate = isAiMate('route');

		const { itemWidth, zoomVisible, videoConferenceIsActived } = this.state;
		return (
			<Grid container spacing={isMobile ? 8 : 4} className="HeaderIconList" alignItems="center">
				{!usingBrandUI && !isCSFCExam && !aiMate && this.renderAnnounceItem()}

				{!usingBrandUI && !isCSFCExam && zoomVisible && (
					<Grid item className="item" xs={itemWidth}>
						<Button variant="text" onClick={this.onClickZoom.bind(this)}>
							<Icon name={videoConferenceIsActived ? 'zoomlive' : 'zoom'} size={24} />
							<Typography className="item-label" color="inherit" variant="caption">
								{t('header.zoom')}
							</Typography>
						</Button>
					</Grid>
				)}

				{!usingBrandUI && !isCSFCExam && !aiMate && (
					<Grid item className="item" xs={itemWidth}>
						<Button variant="text" onClick={this.onClickFeedback}>
							<Icon name="question-square" size={24} />
							<Typography className="item-label" color="inherit" variant="caption">
								{t('header.bugReport')}
							</Typography>
						</Button>
					</Grid>
				)}

				{!usingBrandUI && !isCSFCExam && !aiMate && (
					<Grid item className="item" xs={itemWidth}>
						<Button id="header-playground" variant="text" onClick={this.onClickFreecode}>
							<Icon name="freecode" size={24} />
							<Typography className="item-label" color="inherit" variant="caption">
								{t('header.freecode')}
							</Typography>
						</Button>
					</Grid>
				)}

				<Grid item className="item" xs={itemWidth}>
					<Button id="header-logout" variant="text" onClick={isCSFCExam ? this.signOutCSFC : this.signOut}>
						<Icon name="logout" size={24} />
						<Typography className="item-label" color="inherit" variant="caption">
							{t('header.logOut')}
						</Typography>
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.app.get('user'),
});

const mapDispatchToProps = {
	signOutAction,
	removeCSFCUser,
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(HeaderIconList);
