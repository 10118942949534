export const GET_VERSION = 'app/GET_VERSION';

export const INIT = 'app/INIT';
export const INIT_SUC = 'app/INIT_SUC';
export const INIT_ERR = 'app/INIT_ERR';

export const SIGNIN = 'auth/SIGNIN';
export const SIGNIN_SUC = 'auth/SIGNIN_SUC';
export const SIGNIN_ERR = 'auth/SIGNIN_ERR';
export const SIGNOUT = 'auth/SIGNOUT';
export const MULTI_LOGIN_ALERT = 'auth/MULTI_LOGIN_ALERT';

export const GETUSER = 'auth/GETUSER';

export const SAGA_SIGNIN = 'auth/SAGA_SIGNIN';
export const SAGA_SIGNOUT = 'auth/SAGA_SIGNOUT';
export const SAGA_GETUSER = 'auth/SAGA_GETUSER';

export const GET_ANNOUNCEMENT = 'app/GET_ANNOUNCEMENT';
export const GET_ANNOUNCEMENT_LIST = 'app/GET_ANNOUNCEMENT_LIST';

export const OPEN_PLAYGROUND_MODAL = 'app/OPEN_PLAYGROUND_MODAL';
export const CLOSE_PLAYGROUND_MODAL = 'app/CLOSE_PLAYGROUND_MODAL';

export const SET_BETA_USER = 'app/SET_BETA_USER';
export const REMOVE_BETA_USER = 'app/REMOVE_BETA_USER';
export const BETA_USER_LIST = 'app/BETA_USER_LIST';
export const SET_ONLINE = 'app/SET_ONLINE';
export const SET_OFFLINE = 'app/SET_OFFLINE';
export const SET_FEATURE_TRIAL_UNIT = 'app/SET_FEATURE_TRIAL_UNIT';
export const REMOVE_FEATURE_TRIAL_UNIT = 'app/REMOVE_FEATURE_TRIAL_UNIT';

// export const SET_SHOW_TOAST = 'app.SET_SHOW_TOAST';
// export const SET_CLOSE_TOAST = 'app.SET_CLOSE_TOAST';
export const ADD_TOAST = 'app.ADD_TOAST';
export const REMOVE_TOAST = 'app.REMOVE_TOAST';

export const SET_SCHOOL_DOMAIN_USER = 'app/SET_SCHOOL_DOMAIN_USER';
export const UNSET_SCHOOL_DOMAIN_USER = 'app/UNSET_SCHOOL_DOMAIN_USER';
export const SCHOOL_DOMAIN_LIST = 'app/SCHOOL_DOMAIN_LIST';

export const SET_CSFC_USER = 'app/SET_CSFC_USER';
export const REMOVE_CSFC_USER = 'app/REMOVE_CSFC_USER';

export const SET_UNIT_PERMISSIONS = 'app/SET_UNIT_PERMISSIONS';

export const SET_CURRENT_SUBMITTING = 'app.SET_CURRENT_SUBMITTING';
export const CLEAR_CURRENT_SUBMITTING = 'app.CLEAR_CURRENT_SUBMITTING';

export const SET_AI_MATE_SUBSCRIPTION_PLANS = 'app/SET_AI_MATE_SUBSCRIPTION_PLANS';
export const SET_AI_MATE_USER_PLAN = 'app/SET_AI_MATE_USER_PLAN';
